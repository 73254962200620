import React, { useState, useCallback, useEffect } from 'react';
import { Form } from '@rocketseat/unform';
import PropTypes from 'prop-types';
import { MdInfo } from 'react-icons/md';
import { toast } from 'react-toastify';
import Header from '../../components/HeaderForm';
import api from '../../services/api';
import history from '../../services/history';
import { Select } from '../../components/InputSelect';
import {
  opcoesDefaultParticipacaoEconomica,
  opcoesDefaultSituacaoMoradia,
  opcoesDefaultMeioTransporte,
  opcoesDefaultEscolaEnsinoMedio,
} from './utils';
import Input from '../../components/InputText';
import Button from '../../components/Button';
import {
  Container,
  Question,
  Content,
  ContainerRadio,
  PrivateSchool,
} from './styles';

export default function Informations({ location }) {
  const [idFichaAnalise] = useState(location.state.idFichaAnalise);
  const [idSituacao] = useState(location.state.idSituacao);

  const [opcoesParticipacaoEconomica] = useState(
    opcoesDefaultParticipacaoEconomica
  );
  const [opcoesSituacaoMoradia] = useState(opcoesDefaultSituacaoMoradia);
  const [opcoesMeioTransporte] = useState(opcoesDefaultMeioTransporte);
  const [opcoesEscolaEnsinoMedio] = useState(opcoesDefaultEscolaEnsinoMedio);

  const [escolaEnsinoMedio, setEscolaEnsinoMedio] = useState('');
  const [participacaoEconomica, setParticipacaoEconomica] = useState('');
  const [situacaoMoradia, setSituacaoMoradia] = useState('');
  const [meioTransporte, setMeioTransporte] = useState('');

  const [moradiaCedida, setMoradiaCedida] = useState(true);
  const [escolaParticular, setEscolaParticular] = useState(true);
  const [, setPorcentagemBolsa] = useState(true);
  const [infoCursoSuperior, setInfoCursoSuperior] = useState(true);

  const [errorParticipacao, setErrorParticipacao] = useState(false);
  const [errorMoradia, setErrorMoradia] = useState(false);
  const [errorMeioTransporte, setErrorMeioTransporte] = useState(false);
  const [errorEscola, setErrorEscola] = useState(false);

  const [zone, setZone] = useState(null);
  const [cursoSuperior, setCursoSuperior] = useState(null);
  const [possuiBolsas, setPossuiBolsas] = useState(null);

  const [opcoes, setOpcoes] = useState({});

  useEffect(() => {
    async function loadInformacoesFicha() {
      
        const { data } = await api.get(`fichaanalisesocial/${idFichaAnalise}`);

        const participacao = opcoesParticipacaoEconomica.find(
          (item) => item.value === data.fas_participacao
        );

        const moradia = opcoesSituacaoMoradia.find(
          (item) => item.value === data.fas_moradia
        );

        const transporte = opcoesMeioTransporte.find(
          (item) => item.value === data.fas_transporte
        );

        const escola = opcoesEscolaEnsinoMedio.find(
          (item) => item.value === data.fas_ensino_medio
        );

        if (data.fas_moradia === 2) {
          setMoradiaCedida(false);
        }

        if (data.fas_ensino_medio === 3 || data.fas_ensino_medio === 4) {
          setEscolaParticular(false);
        }
        if (data.fas_curso_superior === true) {
          setInfoCursoSuperior(false);
        }
        setOpcoes({
          participacao,
          moradia,
          transporte,
          escola,
          descricao_moradia_cedida: data.fas_moradia_cedida,
          porcentagem_bolsa: data.fas_ensino_medio_bolsa_per,
          descricao_curso_superior: data.fas_curso_curso,
          descricao_instituicao: data.fas_curso_superior_inst,
          zona: data.fas_moradia_zona,
          curso_superior: data.fas_curso_superior,
          ensino_medio: data.fas_ensino_medio_bolsa,
        });

        setEscolaEnsinoMedio(escola);
        setParticipacaoEconomica(participacao);
        setSituacaoMoradia(moradia);
        setMeioTransporte(transporte);

        setZone(data.zona);
        setCursoSuperior(data.curso_superior);
        setPossuiBolsas(data.ensino_medio);
      
    }
    loadInformacoesFicha();
  }, [
    idFichaAnalise,
    opcoesParticipacaoEconomica,
    opcoesSituacaoMoradia,
    opcoesMeioTransporte,
    opcoesEscolaEnsinoMedio,
  ]);

  const participacaoEconomicaSelected = useCallback((selectedOption) => {
    setParticipacaoEconomica(selectedOption);
  }, []);

  const situacaoMoradiaSelected = useCallback((selectedOption) => {
    if (selectedOption.value === 2) setMoradiaCedida(false);
    else setMoradiaCedida(true);

    setSituacaoMoradia(selectedOption);
  }, []);

  const meioTransporteSelected = useCallback((selectedOption) => {
    setMeioTransporte(selectedOption);
  }, []);

  const escolaEnsinoMedioSelected = useCallback((selectedOption) => {
    if (selectedOption.value === 3 || selectedOption.value === 4) {
      setEscolaParticular(false);
    } else {
      setEscolaParticular(true);
    }

    setEscolaEnsinoMedio(selectedOption);
  }, []);

  const ensinoParticularSelected = useCallback(() => {
    setPorcentagemBolsa(true);
  }, []);

  const cursoSuperiorSelected = useCallback((selectedOption) => {
    const value = Number(selectedOption.target.value);

    if (value === 1) setInfoCursoSuperior(false);
    else setInfoCursoSuperior(true);

    setCursoSuperior(value);
  }, []);

  const possuiBolsaEstudos = useCallback((selectedOption) => {
    const value = Number(selectedOption.target.value);

    setPossuiBolsas(value);
  }, []);

  const zonaSelecionada = useCallback((selectedOption) => {
    const { value } = selectedOption.target;

    setZone(value);
  }, []);

  async function handleAtualizarInformacoes(data) {
    if (participacaoEconomica === undefined) {
      setErrorParticipacao(true);
      return;
    }
    setErrorParticipacao(false);

    if (situacaoMoradia === undefined) {
      setErrorMoradia(true);
      return;
    }
    setErrorMoradia(false);

    if (meioTransporte === undefined) {
      setErrorMeioTransporte(true);
      return;
    }
    setErrorMeioTransporte(false);

    if (escolaEnsinoMedio === undefined) {
      setErrorEscola(true);
      return;
    }
    setErrorEscola(false);

    try {
      await api.put(`fichaanalisesocial/${idFichaAnalise}`, {
        fas_participacao: participacaoEconomica.value,
        fas_moradia: situacaoMoradia.value,
        fas_moradia_cedida: data.cedida,
        fas_moradia_zona: zone,
        fas_transporte: meioTransporte.value,
        fas_ensino_medio: escolaEnsinoMedio.value,
        fas_ensino_medio_bolsa: possuiBolsas,
        fas_ensino_medio_bolsa_per: data.porcentagem || 0,
        fas_curso_superior: cursoSuperior,
        fas_curso_curso: data.cursosuperior,
        fas_curso_superior_inst: data.instituicaosuperior,
      });

      await api.put(`checkficha/${idFichaAnalise}`, {
        outras_informacoes: true,
      });

      toast.success('Registros salvos com sucesso!');
      history.goBack();
    } catch (err) {
      toast.error(`Erro ao atualizar os dados!`, err.response.data.message);
    }
  }
  return (
    <Container>
      <Header title="Outras Informações" icon={MdInfo} />

      <Question>
        <Form
          onSubmit={handleAtualizarInformacoes}
          initialData={{
            cedida: opcoes.descricao_moradia_cedida,
            porcentagem: opcoes.porcentagem_bolsa,
            cursosuperior: opcoes.descricao_curso_superior,
            instituicaosuperior: opcoes.descricao_instituicao,
          }}
        >
          <Content>
            <h3>
              * Qual é a sua participação na vida econômica da sua família?
            </h3>

            <Select
              options={opcoesParticipacaoEconomica}
              onChange={participacaoEconomicaSelected}
              name="participacaoeconomica"
              value={participacaoEconomica || opcoes.participacao}
            />
            {errorParticipacao && <span>Selecione alguma opção!</span>}

            <h3>* Situação da Moradia - A casa em que sua família reside é:</h3>

            <Select
              options={opcoesSituacaoMoradia}
              onChange={situacaoMoradiaSelected}
              value={situacaoMoradia || opcoes.moradia}
              name="situacaomoradia"
            />
            {errorMoradia && <span>Selecione alguma opção!</span>}

            <h3>* Cedida por quem? (Caso sua moradia seja cedida)</h3>
            <Input type="text" name="cedida" disabled={moradiaCedida} />

            <h3>* Sua moradia é localizada em:</h3>
            <ContainerRadio>
              <div>
                <input
                  type="radio"
                  name="zona"
                  onChange={zonaSelecionada}
                  defaultChecked={opcoes.zona && opcoes.zona === 1}
                  value="1"
                />
                <label>Zona rural</label>
              </div>

              <div>
                <input
                  type="radio"
                  name="zona"
                  onChange={zonaSelecionada}
                  defaultChecked={opcoes.zona && opcoes.zona === 2}
                  value="2"
                />
                <label>Zona urbana</label>
              </div>
            </ContainerRadio>

            <h3>
              * Qual seu principal meio de transporte para chegar à faculdade?
            </h3>

            <Select
              options={opcoesMeioTransporte}
              onChange={meioTransporteSelected}
              name="meiotransporte"
              value={meioTransporte || opcoes.transporte}
            />
            {errorMeioTransporte && <span>Selecione alguma opção!</span>}

            <h3>* Em que tipo de escola você cursou o ensino médio:</h3>

            <Select
              options={opcoesEscolaEnsinoMedio}
              onChange={escolaEnsinoMedioSelected}
              name="ensinomedio"
              value={escolaEnsinoMedio || opcoes.escola}
            />
            {errorEscola && <span>Selecione alguma opção!</span>}

            <h3>* Se estudou em escola particular, teve bolsa de estudos?</h3>

            <PrivateSchool
              disabled={escolaParticular}
              onChange={ensinoParticularSelected}
            >
              <div>
                <input
                  type="radio"
                  name="tevebolsaestudos"
                  onChange={possuiBolsaEstudos}
                  defaultChecked={opcoes.ensino_medio && opcoes.ensino_medio}
                  value="1"
                />
                <label>Sim</label>
              </div>

              <div>
                <input
                  type="radio"
                  name="tevebolsaestudos"
                  onChange={possuiBolsaEstudos}
                  defaultChecked={
                    opcoes.ensino_medio && opcoes.ensino_medio === 2
                  }
                  value="2"
                />
                <label>Não</label>
              </div>
            </PrivateSchool>

            <h3>% de bolsa de estudos na escola particular:</h3>
            <Input
              type="number"
              step="0.01"
              min="0"
              max="100"
              name="porcentagem"
              disabled={escolaParticular}
            />

            <h3>Já é formado em curso superior?</h3>
            <ContainerRadio>
              <div>
                <input
                  type="radio"
                  name="formadocursosuperior"
                  onChange={cursoSuperiorSelected}
                  defaultChecked={
                    opcoes.curso_superior && opcoes.curso_superior
                  }
                  value="1"
                />
                <label>Sim</label>
              </div>

              <div>
                <input
                  type="radio"
                  name="formadocursosuperior"
                  onChange={cursoSuperiorSelected}
                  defaultChecked={
                    opcoes.curso_superior && opcoes.curso_superior === 2
                  }
                  value="2"
                />
                <label>Não</label>
              </div>
            </ContainerRadio>

            <h3>* Em qual curso superior é formado?</h3>
            <Input
              type="text"
              name="cursosuperior"
              disabled={infoCursoSuperior}
            />

            <h3>* Em qual instituição é formado no curso superior?</h3>
            <Input
              type="text"
              name="instituicaosuperior"
              disabled={infoCursoSuperior}
            />
          </Content>

          {idSituacao <= 2 && <Button type="submit">Continuar</Button>}
        </Form>
      </Question>
    </Container>
  );
}

Informations.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
