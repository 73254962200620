/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { uuid } from 'uuidv4';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import filesize from 'filesize';
import * as Yup from 'yup';
import { MdImage, MdKeyboardBackspace, MdAttachFile } from 'react-icons/md';
import Input from '../../components/InputText';
import FileList from '../../components/FileList';
import Button from '../../components/Button';
import Modal from '../../components/ModalListDocuments';
import history from '../../services/history';
import api from '../../services/api';

import {
  Container,
  Header,
  ContainerTitle,
  Content,
  DropContainer,
  UploadMessage,
} from './styles';

export default function Anexos({ location }) {
  const [idFichaAnalise] = useState(location.state.idFichaAnalise);
  const [idEntidade] = useState(location.state.idEntidade);
  const [idSituacao] = useState(location.state.idSituacao);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [newFile, setNewFile] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [visibleButton, setVisibleButton] = useState(false);

  useEffect(() => {
    async function loadAnexos() {
      const response = await api.get(`anexos/${idFichaAnalise}`);

      const files = response.data.map((file) => ({
        id: file.id,
        name: file.ara_anexo,
        readableSize: filesize(file.size),
        type: file.type,
        uploaded: true,
        url: file.ara_url,
        descricao: file.ara_descricao,
      }));

      setUploadedFiles(files);
    }

    loadAnexos();
  }, [idFichaAnalise]);

  const updateFile = (id, data) => {
    const files = newFile.map((uploadedFile) =>
      id === uploadedFile.id ? { ...uploadedFile, ...data } : uploadedFile
    );

    setNewFile(files);

    if (data.uploaded === true) {
      setNewFile([]);
      setUploadedFiles(uploadedFiles.concat(files));
    }

    if (data.error === true) {
      setNewFile([]);
      setUploadedFiles(uploadedFiles.concat(files));
    }

    setVisibleButton(false);
  };
  const processUpload = (uploadedFile, descricao) => {
    const data = new FormData();

    data.append('arquivo', uploadedFile.file, uploadedFile.ara_anexo);
    data.append('ficha_analise_id', idFichaAnalise);
    data.append('entidade_id', idEntidade);
    data.append('ara_descricao', descricao);

    api
      .post('anexos', data, {
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));

          updateFile(uploadedFile.id, { progress });
        },
      })
      .then(async (response) => {
        updateFile(uploadedFile.id, {
          uploaded: true,
          id: response.data.id,
          url: response.data.ara_url,
          descricao: response.data.ara_descricao,
        });

        await api.put(`checkficha/${idFichaAnalise}`, {
          anexos: true,
        });
      })
      .catch(() => {
        updateFile(uploadedFile.id, {
          error: true,
        });
      });
  };

  const handleAddedFile = ({ descricao }) => {
    newFile.forEach((item) => item.file && processUpload(item, descricao));
  };

  const handleUploads = (files) => {
    const uploadsFiles = files.map((file) => ({
      file,
      id: uuid(),
      name: file.name,
      type: file.type,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    if (uploadsFiles) setVisibleButton(true);

    setNewFile(uploadsFiles);
  };

  const renderDragMessage = (isDragActive, isDragReject) => {
    if (!isDragActive) {
      return (
        <>
          <MdImage size={50} color="#fff" />
          <UploadMessage>Selecione ou arraste arquivos aqui...</UploadMessage>
          <small>Arquivos suportados: JPEG, PJPEG, PNG, WEBP e PDF</small>
          <small>Tamanho máximo por arquivo: 10MB</small>
        </>
      );
    }

    if (isDragReject) {
      return <UploadMessage type="error">Arquivo não suportado!</UploadMessage>;
    }

    return (
      <UploadMessage type="success">Solte os arquivos aqui!</UploadMessage>
    );
  };

  const handleDelete = async (id) => {
    await api.delete(`anexos/${id}`);

    const files = uploadedFiles.filter((uploadFile) => uploadFile.id !== id);

    setUploadedFiles(files);
  };

  const schema = Yup.object().shape({
    descricao: Yup.string().required('Campo obrigatório!'),
  });

  return (
    <Container>
      <Header>
        <button type="button" onClick={() => history.goBack()}>
          <MdKeyboardBackspace size={20} />
          <u>Voltar</u>
        </button>
        <ContainerTitle>
          <MdAttachFile size={30} />
          <h2>Anexos</h2>
        </ContainerTitle>

        <button
          type="button"
          className="documents"
          onClick={() => setOpenModal(true)}
        >
          Documentos exigidos
        </button>
      </Header>
      <Content>
        {idSituacao <= 2 && (
          <Dropzone
            accept="image/*,application/pdf"
            onDropAccepted={handleUploads}
            multiple={false}
          >
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
              <DropContainer
                {...getRootProps()}
                isDragActive={isDragActive}
                isDragReject={isDragReject}
              >
                <input {...getInputProps()} />
                {renderDragMessage(isDragActive, isDragReject)}
              </DropContainer>
            )}
          </Dropzone>
        )}
        {!!uploadedFiles.length || newFile.length >= 0 ? (
          <FileList
            files={uploadedFiles}
            onDelete={handleDelete}
            newFile={newFile}
            idSituacao={idSituacao}
          />
        ) : null}

        {visibleButton && (
          <Form schema={schema} onSubmit={handleAddedFile}>
            <h4>* Descrição do arquivo:</h4>
            <Input
              type="text"
              name="descricao"
              placeholder="CPF / RG / Conta ..."
            />
            <Button type="submit">Registrar imagens</Button>
          </Form>
        )}
      </Content>

      {openModal && (
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          idEntidade={idEntidade}
        />
      )}
    </Container>
  );
}

Anexos.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
