import styled from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled.div`
  display: grid;

  grid-template-columns: 160px auto;
  grid-template-rows: auto;

  grid-template-areas: 'ME DA';
`;

export const Menu = styled.div`
  grid-area: ME;
  display: flex;
  flex-direction: column;

  min-height: calc(100vh - 64px);

  background: #121921;
`;

export const Div = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 36px;
  width: 100%;
  border-radius: 4px;
  background: ${(props) => (props.active ? '#89c54b' : 'transparent')};

  font-size: 18px;
  color: #fff;
  padding-left: 16px;
  cursor: pointer;

  &:hover {
    background: #89c54b;
    border-radius: 4px;
  }
`;

export const Title = styled.div`
  a {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 20px;
    color: #fff;

    border-bottom: 1px solid #fff2;
    margin: 12px 4px;
    padding-bottom: 10px;

    &:hover {
      color: #fffa;
    }

    svg {
      margin-left: 8px;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const ListActions = styled.div`
  position: absolute;
  width: 160px;
  left: calc(100% + 16px);
  top: -8px;

  background: #121921;
  color: #fff;
  border-radius: 4px;
  border: 1px solid #222830;
  padding: 15px 5px;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  z-index: 3;
  box-shadow: 0px 0px 2px #00000026;
  opacity: 1;

  &::after {
    content: '';
    position: absolute;
    left: -28px;
    top: 10px;
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid #121921;
    border-bottom: 14px solid transparent;
    border-top: 14px solid transparent;
    z-index: 3;
  }

  &::before {
    content: '';
    position: absolute;
    left: -28px;
    top: 8px;
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 16px solid #222830;
    border-bottom: 16px solid transparent;
    border-top: 16px solid transparent;
    z-index: 2;
  }
`;

export const Options = styled.div``;

export const Button = styled.button`
  border: 0;
  background: transparent;
  color: #fff;
  text-align: left;
  width: 100%;
  font-size: 14px;
  padding: 10px 5px;
  border-radius: 4px;

  &:hover {
    background: #222830;
  }
`;

export const Main = styled.div`
  grid-area: DA;
  display: flex;

  justify-content: center;
  align-items: center;

  position: relative;

  form {
    margin: 20px;

    input {
      height: 38px;
      margin: 5px 0;
      padding: 0 20px;

      border: 0;
      border-radius: 19px;
      background: #222830;
      color: #fff;

      &::placeholder {
        font-size: 14px;
        color: #fff8;
      }
    }

    input[type='radio'] {
      height: 15px;
      margin-right: 5px;
    }

    > button {
      height: 38px;
      background: #89c54b;
      border: 0;
      border-radius: 19px;
      margin: 25px 0;
      padding: 8px 24px;
      color: #fff;

      &:hover {
        background: ${darken(0.1, '#89c54b')};
      }
    }

    a {
      float: right;
      margin-bottom: 10px;
    }

    span {
      color: #f64c75;
      font-size: 12px;
      margin: 0 0 10px;
    }
  }
`;
