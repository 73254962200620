import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  width: 100%;
  background: #121921;
  border-radius: 8px;
  margin: 20px;

  form {
    width: 100%;
    padding: 0 20px;

    > button {
      float: right;
    }
  }
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  margin: 24px 0;

  a {
    position: absolute;
    left: 20px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;

  margin: 5px 0;

  span {
    width: 100%;
    float: left;
  }

  &.ativo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input[type='checkbox'] {
    position: relative;
    width: 64px;
    -webkit-appearance: none;
    box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
    cursor: pointer;
    height: 28px;

    &:checked {
      background: #03a9f4;
    }

    &:before {
      content: '';
      position: absolute;
      width: 28px;
      height: 28px;
      border-radius: 14px;
      top: 0;
      left: 0;
      transform: scale(1.1);
      background: #fff;
      transition: 0.5s;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
    }

    &:checked:before {
      left: 36px;
    }
  }
`;

export const Columns2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 10px;
`;

export const Label = styled.div`
  color: #fff;
`;

export const Main = styled.div`
  padding-bottom: 20px;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  color: #fff8;

  th,
  td {
    border-spacing: auto;
    padding: 16px;
    text-align: left;
    border-bottom: 1px solid #fff2;
  }

  tbody {
    tr {
      &:hover {
        background: #222830;
      }

      svg {
        color: #fff;
      }
    }

    td {
      > button {
        background: transparent;
        border: 0;

        svg {
          &:hover {
            color: #89c54b;
          }
        }
      }
    }
  }
`;

export const Row = styled.div`
  width: calc(100% - 40px);
  height: 2px;
  margin: 20px;

  background: #222830;
`;

export const Modal = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff5;
  z-index: 10;
  display: ${(props) => (props.open ? 'block' : 'none')};
`;
