import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: center;

  button {
    width: 120px;
    margin: 20px 0px 0px 0px;
    margin-left: 15px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`;

export const Card = styled.div`
  position: relative;
  background: #121921;
  border-radius: 8px;
  width: 600px;
  transition: 400ms;
  padding: 25px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const List = styled.ul`
  overflow-y: auto;
  width: 100%;
  max-height: 400px;
  height: auto;

  ::-webkit-scrollbar-track {
    background-color: none;
  }

  ::-webkit-scrollbar-corner {
    margin-left: 5px;
  }
  ::-webkit-scrollbar {
    width: 5px;
    padding-left: 5px;
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: #dad7d7;
    border-radius: 8px;
  }

  li {
    margin-top: 20px;
    line-height: 20px;
    text-align: justify;
    font-size: 15px;
    padding: 10px;

    border: 1px solid #eee;
  }
`;

export const Icon = styled(MdClose)`
  position: absolute;
  height: 30px;
  width: 30px;
  color: #fff;
  top: 5px;
  right: 5px;
  cursor: pointer;
`;
