import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  background: #121921;
  width: 80%;
  margin: 15px auto;
  border-radius: 8px;
  color: #fff;

  @media (max-width: 800px) {
    width: 95%;
  }

  form {
    width: 95%;
  }

  h2 {
    font-weight: lighter;
    padding: 20px;
  }
`;

export const Content = styled.div`
  display: grid;
  align-items: center;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  margin-bottom: 20px;

  @media (max-width: 855px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

export const ContainerInfo = styled.div`
  h4 {
    margin: 5px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    padding: 10px;

    color: #999;
    background: none;

    svg {
      margin-right: 5px;
    }
  }
`;
