import { takeLatest, put, all } from 'redux-saga/effects';

import { handleEntitySuccess } from './actions';

export function* setEntity({ entidade_id }) {
  yield put(handleEntitySuccess(entidade_id));
}

export function setEntityOut() {}
export default all([
  takeLatest('@entity/HANDLE_ENTITY_REQUEST', setEntity),
  takeLatest('@entity/HANDLE_ENTITY_OUT', setEntityOut),
]);
