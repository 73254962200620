import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import { MdPerson } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../components/Button';
import Input from '../../components/InputText';
import Header from '../../components/HeaderForm';
import { Container } from './styles';
import history from '../../services/history';
import api from '../../services/api';
import { mascaraCep, mascaraCelular } from '../../utils/masks';

export default function PersonalData({ location }) {
  const [idFichaAnalise] = useState(location.state.idFichaAnalise);
  const [idSituacao] = useState(location.state.idSituacao);

  const [dadosPessoais, setDadosPessoais] = useState({});

  const onKeyUpCep = useCallback((event) => {
    mascaraCep(event);
  }, []);

  const onKeyUpCelular = useCallback((event) => {
    mascaraCelular(event);
  }, []);

  useEffect(() => {
    async function loadDadosPessoais() {
      
        const { data } = await api.get(`fichaanalisesocial/${idFichaAnalise}`);

        setDadosPessoais({
          cep: data.fas_alu_cep,
          cidade: data.fas_alu_cidade,
          complemento: data.fas_alu_complemento,
          endereco: data.fas_alu_endereco,
          bairro: data.fas_alu_bairro,
          numero: data.fas_alu_numero,
          uf: data.fas_alu_uf,
          telefone: data.fas_alu_telefone,
          celular: data.fas_alu_celular,
        });
     
    }
    loadDadosPessoais();
  }, [idFichaAnalise]);

  const schema = Yup.object().shape({
    cep: Yup.string()
      .min(3, 'Digite um CEP válido')
      .required('Campo obrigatório'),
    cidade: Yup.string()
      .min(3, 'Digite uma cidade válida')
      .required('Campo obrigatório'),
    complemento: Yup.string(),
    endereco: Yup.string()
      .min(5, 'Mínimo de 5 caracteres')
      .required('Campo obrigatório'),
    bairro: Yup.string()
      .min(5, 'Mínimo de 5 caracteres')
      .required('Campo obrigatório'),
    numero: Yup.string().required('Campo obrigatório'),
    telefone: Yup.string(),
    uf: Yup.string()
      .min(2, 'Digite uma UF válida')
      .required('Campo obrigatório'),
    celular: Yup.string().required('Campo obrigatório'),
  });

  async function handlePersonalData(data) {
    try {
      await api.put(`fichaanalisesocial/${idFichaAnalise}`, {
        fas_alu_cep: data.cep,
        fas_alu_cidade: data.cidade,
        fas_alu_complemento: data.complemento,
        fas_alu_endereco: data.endereco,
        fas_alu_bairro: data.bairro,
        fas_alu_numero: data.numero,
        fas_alu_uf: data.uf,
        fas_alu_telefone: data.telefone,
        fas_alu_celular: data.celular,
      });

      await api.put(`checkficha/${idFichaAnalise}`, {
        dados_pessoais: true,
      });

      toast.success('Dados atualizados com sucesso!');
      history.goBack();
    } catch (error) {
      toast.error(`Erro ao atualizar os dados! `, error.response.data.message);
    }
  }

  return (
    <Container>
      <Header title="Dados Pessoais" icon={MdPerson} />

      <Form
        schema={schema}
        initialData={dadosPessoais}
        onSubmit={handlePersonalData}
      >
        <h2>* Endereço</h2>

        <h4>Cep:</h4>
        <Input type="text" name="cep" autoComplete="off" onKeyUp={onKeyUpCep} />

        <h4>Cidade:</h4>
        <Input type="text" name="cidade" autoComplete="off" />

        <h4>Logradouro / Rua:</h4>
        <Input type="text" name="endereco" autoComplete="off" />

        <h4>Bairro:</h4>
        <Input type="text" name="bairro" autoComplete="off" />

        <h4>Número residencial:</h4>
        <Input type="number" name="numero" autoComplete="off" min="0" />

        <h4>Complemento:</h4>
        <Input type="text" name="complemento" autoComplete="off" />

        <h4>UF:</h4>
        <Input type="text" name="uf" autoComplete="off" maxLength={2} />

        <h2>* Contato</h2>

        <h4>Telefone:</h4>
        <Input
          type="text"
          name="telefone"
          autoComplete="off"
          onKeyUp={onKeyUpCelular}
        />

        <h4>Celular:</h4>
        <Input
          type="text"
          name="celular"
          autoComplete="off"
          onKeyUp={onKeyUpCelular}
        />

        {idSituacao <= 2 && <Button type="submit">Continuar</Button>}
      </Form>
    </Container>
  );
}
PersonalData.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
