import React, { useState } from 'react';
import { Form, Input } from '@rocketseat/unform';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { Container, Header, Item, Label } from './styles';

const schema = Yup.object().shape({
  nie_descricao: Yup.string().required('A descrição é obrigatório!'),
});

export default function EditAluno({ location }) {
  const [editNivelEnsino] = useState(location.state.nivelEnsino);

  async function handleEdit(nivelEnsino) {
    try {
      await api.put(`/nivelensino/${editNivelEnsino.id}`, nivelEnsino);

      toast.success('Nivel de Ensino editado com sucesso!');

      history.push('/admin/niveisensino');
    } catch (error) {
      toast.error(`Erro ao editar Nivel de Ensino, verifique os dados!`);
    }
  }

  return (
    <Container>
      <Header>
        <Link to="/admin/niveisensino">
          <u>Voltar</u>
        </Link>
        <h1>Edição de Nivel de Ensino</h1>
      </Header>

      <Form schema={schema} onSubmit={handleEdit} initialData={editNivelEnsino}>
        <Item>
          <Label>Descrição de Nivel de Ensino</Label>
          <Input type="text" name="nie_descricao" placeholder="Descrição" />
        </Item>

        <button type="submit">Salvar</button>
      </Form>
    </Container>
  );
}

EditAluno.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
