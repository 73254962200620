import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #121921;
    border-radius: 8px;
    max-width: 340px;

    img {
      height: 50px;
      margin: 30px 0px 0px 0px;
    }

    header {
      text-align: center;
      width: 100%;
      padding: 20px;
      border-bottom: 2px solid #fff8;
    }

    main {
      text-align: center;
      width: 100%;
      font-size: 18px;
      color: #fff8;
      border-bottom: 2px solid #fff8;
      background: #222830;

      > p {
        padding: 60px 20px;
      }
    }

    footer {
      padding: 20px;
    }
  }

  > p {
    position: absolute;
    bottom: 15px;
    right: 15px;
    color: #999;
    font-size: 15px;
    padding: 20px 0 4px 0;
  }
`;
