import styled from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
`;

export const Content = styled.div`
  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 340px;
    background: #121921;
    border-radius: 8px;

    img {
      height: 50px;
      margin: 30px 0px 40px 0px;
    }

    h1,
    h2 {
      color: #fff;
      text-align: center;
    }

    form {
      margin: 20px;

      button {
        height: 38px;
        width: 100%;
        background: #89c54b;
        border: 0;
        border-radius: 19px;
        margin: 25px 0;
        color: #fff;
        transition: 0.2s;

        &:hover {
          background: ${darken(0.1, '#89c54b')};
        }
      }

      input {
        margin: 5px 0px;
      }

      a {
        float: right;
        margin-bottom: 10px;
      }

      span {
        color: #f64c75;
        font-size: 12px;
        margin: 0 0 10px;
      }
    }

    a {
      margin-bottom: 40px;
    }
  }

  p {
    position: absolute;
    bottom: 15px;
    right: 15px;
    color: #999;
    font-size: 15px;
    padding: 20px 0 4px 0;
  }
`;
