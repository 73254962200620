import React, { useState } from 'react';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import api from '../../../../services/api';
import history from '../../../../services/history';
import { store } from '../../../../store';

import { Container, Header, Item, Columns2, Label } from './styles';

const schema = Yup.object().shape({
  cuv_valor: Yup.string().required('Valor do curso é obrigatório!'),
});

export default function EditeValorCurso({ location }) {
  const { entity } = store.getState().entity;
  const [periodo_letivo_id] = useState(location.state.periodo_letivo_id);
  const [ValorCurso] = useState(location.state.curso);

  async function handleEdit({ cuv_valor }) {
    try {
      await api.put(`/cursovalor/${ValorCurso.id}`, {
        entidade_id: entity,
        curso_id: ValorCurso.curso.id,
        periodo_letivo_id,
        cuv_valor,
      });
      toast.success('Valor do curso alterado com sucesso!');
      history.goBack();
    } catch (error) {
      toast.error(`Erro ao  alterar o Valor do curso, verifique os dados!`);
    }
  }

  return (
    <Container>
      <Header>
        <h1>Edição do Valor do curso</h1>
      </Header>

      <Form schema={schema} onSubmit={handleEdit} initialData={ValorCurso}>
        <Columns2>
          <Item>
            <Label>{`Valor do curso ${ValorCurso.curso.cur_descricao}: `}</Label>
            <Input
              type="number"
              min="0"
              step="0.01"
              name="cuv_valor"
              placeholder="Valor do Curso"
              autoFocus
            />
          </Item>
        </Columns2>

        <button type="submit">Salvar</button>
      </Form>
    </Container>
  );
}

EditeValorCurso.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
