import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  background: #121921;
  width: 80%;
  margin: 15px auto;
  border-radius: 8px;
  color: #fff;

  @media (max-width: 800px) {
    width: 95%;
  }

  a {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    padding: 10px;

    svg {
      margin-right: 5px;
    }
  }
`;

export const Question = styled.div`
  display: flex;
  justify-content: left;

  form {
    margin: 20px;
    display: flex;
    flex-direction: column;

    input[type='radio'] {
      margin: 5px;
    }
  }
`;

export const Content = styled.div`
  h3 {
    margin: 10px 0px;
  }

  span {
    color: #f64c75;
    font-size: 12px;
  }
`;

export const ContainerRadio = styled.div`
  display: flex;

  flex-direction: column;

  input {
    width: 15px;
    height: 15px;
  }

  div {
    margin-top: 5px;
    display: flex;
    align-items: center;
  }
`;

export const PrivateSchool = styled.div`
  display: flex;
  flex-direction: column;

  opacity: ${(props) => (props.disabled ? 0.2 : 1)};
  input {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }

  div {
    margin-top: 5px;
    display: flex;
    align-items: center;
  }
`;
