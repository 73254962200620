import React, { useEffect, useState } from 'react';
import { MdKeyboardBackspace, MdLibraryBooks } from 'react-icons/md';
import PropTypes from 'prop-types';
import { Container, Table, Header } from './styles';
import history from '../../../../services/history';
import Button from '../../../../components/Button';

import api from '../../../../services/api';

export default function ListaPendencias({ location }) {
  const [idFichaAnalise] = useState(location.state.idFichaAnalise);
  const [idSituacao] = useState(location.state.idSituacao);

  const [pendencias, setPendencias] = useState([]);

  useEffect(() => {
    async function loadPendencias() {
      const response = await api.get(`pendencias/${idFichaAnalise}`);

      setPendencias(response.data);
    }
    loadPendencias();
  }, [idFichaAnalise]);

  return (
    <Container>
      <Header>
        <MdLibraryBooks size={30} />
        <h2>Pendências</h2>

        <button type="button" onClick={() => history.goBack()}>
          <MdKeyboardBackspace size={20} />
          <u>Voltar</u>
        </button>
      </Header>

      {idSituacao >= 2 && idSituacao <= 3 && (
        <Button
          type="button"
          onClick={() =>
            history.push({
              pathname: '/order/cadastar/pendencia',
              state: { idFichaAnalise },
            })
          }
        >
          Inserir pendência
        </Button>
      )}

      {!!pendencias.length && (
        <Table>
          <thead>
            <tr>
              <th>Data</th>
              <th>Descrição</th>
            </tr>
          </thead>
          <tbody>
            {pendencias.map((pendencia) => (
              <tr key={pendencia.id}>
                <td>{pendencia.pen_data}</td>
                <td>{pendencia.pen_descricao}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
}
ListaPendencias.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
