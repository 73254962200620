import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import { MdAttachMoney } from 'react-icons/md';
import { toast } from 'react-toastify';
import Button from '../../../components/Button';
import Input from '../../../components/InputText';
import TabelaDetalhes from '../DetalhesBens/TabelaDetalheBens';
import Header from '../../../components/HeaderForm';
import { Container, Value } from './styles';
import history from '../../../services/history';
import api from '../../../services/api';

export default function EditarBens({ location }) {
  const [idFicha] = useState(location.state.idFicha);
  const [idEntidade] = useState(location.state.idEntidade);
  const [idBem] = useState(location.state.idBem);
  const [idBensAnalises] = useState(location.state.idBensAnalises);
  const [tipoBem] = useState(location.state.tipoBem);
  const [nomeBem] = useState(location.state.nomeBem);
  const [quantidadeBem] = useState(location.state.quantidadeBem);

  async function handleEditBens({ quantidade }) {
    try {
      await api.put(`bensanalises/${idBensAnalises}`, {
        bem_qtd: quantidade,
      });

      const idFichaAnalise = idFicha;

      await api.put(`checkficha/${idFichaAnalise}`, {
        bens: true,
      });

      toast.success('Valor atualizado com sucesso!');

      history.goBack();
    } catch (error) {
      toast.error('Erro ao atualizar valor do bem!');
    }
  }

  return (
    <Container>
      <Header title="Bens" icon={MdAttachMoney} />
      <span>* Edite a quantidade do bem selecionado abaixo: </span>
      <Form
        initialData={{ quantidade: quantidadeBem }}
        onSubmit={handleEditBens}
      >
        <Value>
          <span>{nomeBem}</span>
          <label htmlFor="valor">Quantidade:</label>
          <Input
            type="number"
            min="0"
            name="quantidade"
            autoComplete="off"
            autoFocus
	    required
          />
        </Value>

        <Button type="submit">Salvar</Button>
      </Form>

      {tipoBem >= 3 && tipoBem <= 5 && (
        <TabelaDetalhes
          idBem={idBem}
          idFicha={idFicha}
          idEntidade={idEntidade}
        />
      )}
    </Container>
  );
}
EditarBens.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
