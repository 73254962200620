export function signInRequest(email, password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password },
  };
}

export function signInSuccess(token, usuario) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, usuario },
  };
}

export function signUpRequest(usu_cpf, usu_email, usu_nome, usu_password) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: {
      usu_cpf,
      usu_email,
      usu_nome,
      usu_password,
    },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}
