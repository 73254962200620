import styled from 'styled-components';
import { darken } from 'polished';
import { MdClose } from 'react-icons/md';

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: center;

  button {
    width: 120px;
    margin: 20px 0px 0px 0px;
  }
  .delete {
    background: #db132f;
    margin-left: 10px;
    &:hover {
      background: ${darken(0.07, '#db132f')};
    }
  }
`;

export const Card = styled.div`
  position: relative;
  background: #121921;
  border-radius: 8px;
  width: 600px;
  transition: 400ms;
  padding: 25px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Icon = styled(MdClose)`
  position: absolute;
  height: 30px;
  width: 30px;
  color: #fff;
  top: 5px;
  right: 5px;
  cursor: pointer;
`;
