import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import { MdKeyboardBackspace, MdLibraryBooks } from 'react-icons/md';
import { toast } from 'react-toastify';
import Input from '../../../../components/InputText';
import Button from '../../../../components/Button';
import { store } from '../../../../store';
import { Container, Header } from './styles';
import history from '../../../../services/history';
import api from '../../../../services/api';

export default function CadastroPendencias({ location }) {
  const [idFichaAnalise] = useState(location.state.idFichaAnalise);
  const [date] = useState(new Date().toLocaleDateString());

  const { entity } = store.getState().entity;
  async function handleSubmit({ entrevista }) {
    try {
      await api.post(`pendencias`, {
        entidade_id: entity,
        ficha_analise_id: idFichaAnalise,
        pen_descricao: entrevista,
        pen_data: new Date(),
      });

      const { data } = await api.put(`fichaanalisesocial/${idFichaAnalise}`, {
        situacao_id: 2,
      });

      toast.success('Pendência enviada com sucesso!');
      history.push({
        pathname: '/order/edit',
        state: {
          idFichaAnalise,
          idEntidade: entity,
          idSituacao: data.situacao_id,
        },
      });
    } catch (error) {
      toast.error('Erro ao enviar pendência');
    }
  }

  return (
    <Container>
      <Header>
        <MdLibraryBooks size={30} />
        <h2>Pendências</h2>

        <button type="button" onClick={() => history.goBack()}>
          <MdKeyboardBackspace size={20} />
          <u>Voltar</u>
        </button>
      </Header>

      <Form onSubmit={handleSubmit}>
        <h3>Data:</h3>
        <Input type="text" name="data" value={date} disabled />

        <h3>Descrição da pendência:</h3>
        <Input multiline type="text" name="entrevista" autoFocus />

        <Button type="submit">Continuar</Button>
      </Form>
    </Container>
  );
}
CadastroPendencias.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
