import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import Input from '../../components/InputText';
import { mascaraCpf } from '../../utils/masks';
import { signUpRequest } from '../../store/modules/auth/actions';

import logo from '../../assets/images/logo.png';

const schema = Yup.object().shape({
  usu_nome: Yup.string().required('O Nome é obrigatório!'),
  usu_email: Yup.string()
    .email('Insira um E-mail válido!')
    .required('O E-mail é obrigatório!'),
  usu_password: Yup.string().min(6, 'Senha de 6 digitos no minimo!'),
  usu_cpf: Yup.string().required('O CPF é obrigatório!'),
});

export default function SignUp() {
  const dispatch = useDispatch();

  function handleSubmit({ usu_cpf, usu_email, usu_nome, usu_password }) {
    dispatch(signUpRequest(usu_cpf, usu_email, usu_nome, usu_password));
  }

  const cpfOnKeyUp = useCallback((event) => {
    mascaraCpf(event);
  }, []);

  return (
    <div>
      <img src={logo} alt="Bolsas de Estudo" />

      <h1>Cadastre-se</h1>

      <Form schema={schema} onSubmit={handleSubmit}>
        <Input type="text" name="usu_nome" placeholder="Nome" />
        <Input
          type="email"
          name="usu_email"
          placeholder="E-mail"
          autoComplete="off"
          required
        />
        <Input
          type="password"
          name="usu_password"
          placeholder="Senha"
          required
        />
        <Input
          type="text"
          name="usu_cpf"
          placeholder="CPF"
          required
          onKeyUp={cpfOnKeyUp}
        />

        <button type="submit">Cadastrar</button>
      </Form>

      <div>
        <Link to="/">
          <u>Voltar</u>
        </Link>
      </div>

      <p>Desenvolvedores: Rodrigo Barreto & Pedro Gomes</p>
    </div>
  );
}
