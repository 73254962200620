import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Content } from './styles';
import logo from '../../assets/images/logo.png';

export default function NotFound() {
  const location = useLocation();

  return (
    <Content>
      <div>
        <img src={logo} alt="Bolsas de Estudo" />

        <header>
          <h1>Página não encontrada</h1>
        </header>

        <main>
          <p>
            A página solicitada <u>{location.pathname}</u> não existe.
          </p>
        </main>

        <footer>
          <Link to="/">
            <u>Voltar para tela de login</u>
          </Link>
        </footer>
      </div>

      <p>Desenvolvedores: Rodrigo Barreto & Pedro Gomes</p>
    </Content>
  );
}
