import React, { useState } from 'react';
import { Form, Input, Check } from '@rocketseat/unform';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { Container, Header, Item, Label, Columns2 } from './styles';

const schema = Yup.object().shape({
  doc_descricao: Yup.string().required('A descrição é obrigatório!'),
  doc_ativo: Yup.string().required('Ativo é obrigatório!'),
});

export default function EditDocumento({ location }) {
  const [editDocumento] = useState(location.state.documento);

  async function handleEdit(documento) {
    try {
      await api.put(`/documentos/${editDocumento.id}`, documento);

      toast.success('Documento editado com sucesso!');

      history.push('/admin/documentos');
    } catch (error) {
      toast.error(`Erro ao editar Documento, verifique os dados!`);
    }
  }

  return (
    <Container>
      <Header>
        <Link to="/admin/documentos">
          <u>Voltar</u>
        </Link>
        <h1>Edição de Documento</h1>
      </Header>

      <Form schema={schema} onSubmit={handleEdit} initialData={editDocumento}>
        <Columns2>
          <Item>
            <Label>Descrição do Documento</Label>
            <Input type="text" name="doc_descricao" placeholder="Descrição" />
          </Item>

          <Item className="ativo">
            <Label>Ativo</Label>
            <Check type="checkbox" name="doc_ativo" />
          </Item>
        </Columns2>

        <button type="submit">Salvar</button>
      </Form>
    </Container>
  );
}

EditDocumento.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
