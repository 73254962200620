import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@rocketseat/unform';
import Input from '../../components/InputText';

import { updateProfileRequest } from '../../store/modules/user/actions';

import { Container } from './styles';

export default function Profile() {
  const dispatch = useDispatch();
  const usuario = useSelector((state) => state.user.profile);

  function handleSubmit(data) {
    const upData = { id: usuario.id, ...data };
    dispatch(updateProfileRequest(upData));
  }

  return (
    <Container>
      <div>
        <h2>Atualização de Cadastro</h2>
        <Form initialData={usuario} onSubmit={handleSubmit}>
          <Input type="text" name="usu_nome" placeholder="Nome" />
          <Input
            type="email"
            name="usu_email"
            placeholder="E-mail"
            autoComplete="off"
          />
          <Input type="text" name="usu_cpf" placeholder="CPF" disabled />

          <hr />
          <h4>Senha atual:</h4>
          <Input type="password" name="usu_oldPassword" />
          <h4>Nova senha:</h4>
          <Input type="password" name="usu_password" />
          <h4>Confirmação de senha:</h4>
          <Input type="password" name="usu_confirm_password" />

          <button type="submit">Atualizar perfil</button>
        </Form>
        <div>
          <Link to="/">
            <u>Voltar</u>
          </Link>
        </div>
      </div>
    </Container>
  );
}
