import React, { useState, useEffect } from 'react';
import {
  MdSearch,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdBorderColor,
  MdDoneAll,
  MdClear,
} from 'react-icons/md';

import api from '../../../services/api';
import history from '../../../services/history';

import { store } from '../../../store';

import {
  Container,
  Header,
  Search,
  Input,
  Button,
  Main,
  Table,
  Footer,
} from './styles';

export default function Bens() {
  const { entity } = store.getState().entity;

  const [bens, setBens] = useState();
  const [filter, setFilter] = useState();
  const [bensTotal, setBensTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limitFromPage] = useState(10);
  const [initialNumberFromPage, setInitialNumberFromPage] = useState();
  const [numberFromPage, setNumberFromPage] = useState();

  const [bens_tipos] = useState([
    {
      label: 'Urbano',
      value: 1,
    },
    {
      label: 'Rural',
      value: 2,
    },
    {
      label: 'Auto',
      value: 3,
    },
    {
      label: 'Moto',
      value: 4,
    },
    {
      label: 'Caminhão',
      value: 5,
    },
  ]);

  useEffect(() => {
    async function loadBens() {
      const response = await api.get('bens', {
        params: { nomeBem: filter, page, entidade_id: entity },
      });

      setBens(response.data);
      setBensTotal(response.headers.x_total_count);
    }

    loadBens();
  }, [filter, page, entity]);

  useEffect(() => {
    function handleLimits() {
      if (page >= 1) {
        if (page * limitFromPage > bensTotal) {
          setNumberFromPage(bensTotal);
        } else {
          setNumberFromPage(page * limitFromPage);
        }
      } else {
        setNumberFromPage(1);
      }

      if (page >= 1) {
        if (bensTotal <= 0) {
          setInitialNumberFromPage(0);
        } else {
          setInitialNumberFromPage(page * limitFromPage + 1 - limitFromPage);
        }
      }
    }

    handleLimits();
  }, [page, bensTotal, numberFromPage, limitFromPage]);

  function handlePageSum() {
    const numberpages = Math.ceil(bensTotal / limitFromPage);

    if (page < numberpages) {
      setPage(page + 1);
    }
  }

  function handlePageSubtract() {
    if (!(page <= 1)) {
      setPage(page - 1);
    }
  }

  function handleNewBem() {
    history.push('/admin/newbem');
  }

  function handleEditBem(bem) {
    history.push({
      pathname: '/admin/editbem',
      state: { bem },
    });
  }

  return (
    <Container>
      <Header>
        <h1>Gerenciamento de Bens</h1>

        <Search>
          <Input>
            <MdSearch size={20} />
            <input
              placeholder="Buscar Bem"
              onChange={(event) => setFilter(event.target.value)}
              type="text"
            />
          </Input>
          <Button type="button" onClick={handleNewBem}>
            Novo
          </Button>
        </Search>
      </Header>
      <Main>
        <Table>
          <thead>
            <tr>
              <th>Ações</th>
              <th>ID</th>
              <th>Descrição</th>
              <th>Tipo</th>
              <th>Ativo</th>
            </tr>
          </thead>
          <tbody>
            {bens &&
              bens.map((bem) => (
                <tr key={bem.id}>
                  <td>
                    <button type="button" onClick={() => handleEditBem(bem)}>
                      <MdBorderColor size={20} />
                    </button>
                  </td>
                  <td>{bem.id}</td>
                  <td>{bem.bem_descricao}</td>
                  <td>
                    {bens_tipos.map(
                      (bemTipo) =>
                        bemTipo.value === bem.bem_tipo && bemTipo.label
                    )}
                  </td>
                  <td>
                    {bem.bem_ativo ? (
                      <MdDoneAll size={20} color="#89c54b" />
                    ) : (
                      <MdClear size={20} color="#f64c75" />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Main>

      <Footer>
        <div>
          <MdKeyboardArrowLeft size={24} onClick={handlePageSubtract} />
          {page}
          <MdKeyboardArrowRight size={24} onClick={handlePageSum} />
        </div>

        <div>
          <span>{initialNumberFromPage}</span>
          <span>a</span>
          <span>{numberFromPage && numberFromPage}</span>
          <span>de</span>
          <span>{bensTotal}</span>
        </div>
      </Footer>
    </Container>
  );
}
