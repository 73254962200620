import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import RecuperarSenha from '../pages/RecuperarSenha';
import NotFound from '../pages/404';
import Dashboard from '../pages/Dashboard';
import Perfil from '../pages/Perfil';
import Order from '../pages/Order';
import OrderEdit from '../pages/Order/OrderEdit';
import InformacoesBasicasCadastro from '../pages/InformacoesBasicasCadastro';
import DadosPessoais from '../pages/DadosPessoais';
import Enderecos from '../pages/Enderecos';
import Informacoes from '../pages/Informacoes';
import Justificativa from '../pages/Justificativa';
import ListaComposicaoFamiliar from '../pages/ComposicaoFamiliar/ListarComposicaoFamiliar';
import CadastrarComposicaoFamiliar from '../pages/ComposicaoFamiliar/CadastrarComposicaoFamiliar';
import EditarComposicaoFamiliar from '../pages/ComposicaoFamiliar/EditarComposicaoFamiliar';
import DespesasMensais from '../pages/DespesasMensais/ListaDespesasMensais';
import EditarDespesasMensais from '../pages/DespesasMensais/EditarDespesaMensal';
import ListaBens from '../pages/Bens/ListaBens';
import EditarBens from '../pages/Bens/EditarBens';
import EditarDetalhesBens from '../pages/Bens/DetalhesBens/EditarDetalhesBens';
import CadastarDetalhesBens from '../pages/Bens/DetalhesBens/CadastrarDetalhesBens';
import ListaFiadores from '../pages/Fiadores/ListaFiadores';
import CadastrarFiadores from '../pages/Fiadores/CadastrarFiadores';
import EditarFiadores from '../pages/Fiadores/EditarFiadores';

import Admin from '../pages/adm/Dashboard';

import Entidades from '../pages/adm/Entidades';
import NewEntidade from '../pages/adm/Entidades/NewEntidade';
import EditEntidade from '../pages/adm/Entidades/EditEntidade';

import Cursos from '../pages/adm/Cursos';
import NewCurso from '../pages/adm/Cursos/NewCurso';
import EditCurso from '../pages/adm/Cursos/EditCurso';

import PeriodoLetivo from '../pages/adm/PeriodoLetivo';
import NewPeriodoLetivo from '../pages/adm/PeriodoLetivo/NewPeriodoLetivo';
import EditPeriodoLetivo from '../pages/adm/PeriodoLetivo/EditPeriodoLetivo';
import EditValorCurso from '../pages/adm/PeriodoLetivo/EditValorCurso';

import Alunos from '../pages/adm/Alunos';
import NewAluno from '../pages/adm/Alunos/NewAluno';
import EditAluno from '../pages/adm/Alunos/EditAluno';

import Usuarios from '../pages/adm/Usuarios';
import EditUsuario from '../pages/adm/Usuarios/EditUsuario';
import NewUsuario from '../pages/adm/Usuarios/NewUsuario';

import Periodos from '../pages/adm/Periodos';
import NewPeriodo from '../pages/adm/Periodos/NewPeriodo';
import EditPeriodo from '../pages/adm/Periodos/EditPeriodo';

import Bens from '../pages/adm/Bens';
import NewBem from '../pages/adm/Bens/NewBem';
import EditBem from '../pages/adm/Bens/EditBem';

import Despesas from '../pages/adm/Despesas';
import NewDespesa from '../pages/adm/Despesas/NewDespesa';
import EditDespesa from '../pages/adm/Despesas/EditDespesa';

import NivelEnsino from '../pages/adm/NivelEnsino';
import NewNivelEnsino from '../pages/adm/NivelEnsino/NewNivelEnsino';
import EditNivelEnsino from '../pages/adm/NivelEnsino/EditNivelEnsino';

import Documentos from '../pages/adm/Documentos';
import NewDocumento from '../pages/adm/Documentos/NewDocumento';
import EditDocumento from '../pages/adm/Documentos/EditDocumento';

import AvisosLegais from '../pages/adm/AvisosLegais';
import NewAvisoLegal from '../pages/adm/AvisosLegais/NewAvisoLegal';
import EditAvisoLegal from '../pages/adm/AvisosLegais/EditAvisoLegal';

import Parecer from '../pages/adm/Parecer';

import CadastrarPendencias from '../pages/adm/Pendencias/CadastroPendencias';
import ListarPendencias from '../pages/adm/Pendencias/ListaPendencias';

import Analise from '../pages/adm/Analise';

import Anexos from '../pages/Anexos';
import Bolsas from '../pages/adm/Bolsas';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/signup" exact component={SignUp} />
      <Route path="/recover" exact component={RecuperarSenha} />

      <Route path="/dashboard" exact component={Dashboard} isPrivate />
      <Route path="/profile" exact component={Perfil} isPrivate />
      <Route path="/order/" exact component={Order} isPrivate />
      <Route path="/order/edit" exact component={OrderEdit} isPrivate />

      <Route
        path="/edit/informations"
        exact
        component={InformacoesBasicasCadastro}
        isPrivate
      />

      <Route
        path="/order/personaldata"
        exact
        component={DadosPessoais}
        isPrivate
      />
      <Route path="/order/address" exact component={Enderecos} isPrivate />
      <Route
        path="/order/informations"
        exact
        component={Informacoes}
        isPrivate
      />
      <Route
        path="/order/justification"
        exact
        component={Justificativa}
        isPrivate
      />

      <Route
        path="/order/familycomposition"
        exact
        component={ListaComposicaoFamiliar}
        isPrivate
      />

      <Route
        path="/order/familycomposition/register"
        exact
        component={CadastrarComposicaoFamiliar}
        isPrivate
      />

      <Route
        path="/order/familycomposition/edit"
        exact
        component={EditarComposicaoFamiliar}
        isPrivate
      />

      <Route path="/order/anexos" exact component={Anexos} isPrivate />

      <Route
        path="/order/despesas"
        exact
        component={DespesasMensais}
        isPrivate
      />

      <Route
        path="/order/despesas/edit"
        exact
        component={EditarDespesasMensais}
        isPrivate
      />

      <Route path="/order/bens" exact component={ListaBens} isPrivate />
      <Route path="/order/bens/edit" exact component={EditarBens} isPrivate />
      <Route
        path="/order/editar/bens/detalhes"
        exact
        component={EditarDetalhesBens}
        isPrivate
      />

      <Route
        path="/order/cadastrar/bens/detalhes"
        exact
        component={CadastarDetalhesBens}
        isPrivate
      />

      <Route path="/order/fiadores" exact component={ListaFiadores} isPrivate />

      <Route
        path="/order/cadastar/fiadores"
        exact
        component={CadastrarFiadores}
        isPrivate
      />

      <Route
        path="/order/editar/fiadores"
        exact
        component={EditarFiadores}
        isPrivate
      />

      <Route path="/profile" exact component={Perfil} isPrivate isAdmin />
      <Route path="/order" exact component={Order} isPrivate />

      <Route path="/admin" exact component={Admin} isPrivate isAdmin />
      <Route
        path="/admin/entidades"
        exact
        component={Entidades}
        isPrivate
        isAdmin
      />

      <Route
        path="/admin/newentidade"
        exact
        component={NewEntidade}
        isPrivate
        isAdmin
      />

      <Route
        path="/admin/editentidade"
        exact
        component={EditEntidade}
        isPrivate
        isAdmin
      />

      <Route path="/admin/cursos" exact component={Cursos} isPrivate isAdmin />

      <Route
        path="/admin/newcurso"
        exact
        component={NewCurso}
        isPrivate
        isAdmin
      />

      <Route
        path="/admin/editcurso"
        exact
        component={EditCurso}
        isPrivate
        isAdmin
      />

      <Route
        path="/admin/periodosletivo"
        exact
        component={PeriodoLetivo}
        isPrivate
        isAdmin
      />

      <Route
        path="/admin/newperiodoletivo"
        exact
        component={NewPeriodoLetivo}
        isPrivate
        isAdmin
      />

      <Route
        path="/admin/editperiodoletivo"
        exact
        component={EditPeriodoLetivo}
        isPrivate
        isAdmin
      />

      <Route
        path="/admin/valorcurso"
        exact
        component={EditValorCurso}
        isPrivate
        isAdmin
      />

      <Route path="/admin/alunos" exact component={Alunos} isPrivate isAdmin />
      <Route
        path="/admin/newaluno"
        exact
        component={NewAluno}
        isPrivate
        isAdmin
      />

      <Route
        path="/admin/editaluno"
        exact
        component={EditAluno}
        isPrivate
        isAdmin
      />

      <Route
        path="/admin/usuarios"
        exact
        component={Usuarios}
        isPrivate
        isAdmin
      />

      <Route
        path="/admin/editusuario"
        exact
        component={EditUsuario}
        isPrivate
        isAdmin
      />

      <Route
        path="/admin/newusuario"
        exact
        component={NewUsuario}
        isPrivate
        isAdmin
      />

      <Route
        path="/admin/periodos"
        exact
        component={Periodos}
        isPrivate
        isAdmin
      />

      <Route
        path="/admin/newperiodo"
        exact
        component={NewPeriodo}
        isPrivate
        isAdmin
      />

      <Route
        path="/admin/editperiodo"
        exact
        component={EditPeriodo}
        isPrivate
        isAdmin
      />

      <Route path="/admin/bens" exact component={Bens} isPrivate isAdmin />
      <Route path="/admin/newbem" exact component={NewBem} isPrivate isAdmin />
      <Route
        path="/admin/editbem"
        exact
        component={EditBem}
        isPrivate
        isAdmin
      />

      <Route
        path="/admin/despesas"
        exact
        component={Despesas}
        isPrivate
        isAdmin
      />
      <Route
        path="/admin/newdespesa"
        exact
        component={NewDespesa}
        isPrivate
        isAdmin
      />
      <Route
        path="/admin/editdespesa"
        exact
        component={EditDespesa}
        isPrivate
        isAdmin
      />

      <Route
        path="/admin/niveisensino"
        exact
        component={NivelEnsino}
        isPrivate
        isAdmin
      />
      <Route
        path="/admin/newnivelensino"
        exact
        component={NewNivelEnsino}
        isPrivate
        isAdmin
      />
      <Route
        path="/admin/editnivelensino"
        exact
        component={EditNivelEnsino}
        isPrivate
        isAdmin
      />

      <Route
        path="/admin/documentos"
        exact
        component={Documentos}
        isPrivate
        isAdmin
      />
      <Route
        path="/admin/newdocumento"
        exact
        component={NewDocumento}
        isPrivate
        isAdmin
      />
      <Route
        path="/admin/editdocumento"
        exact
        component={EditDocumento}
        isPrivate
        isAdmin
      />

      <Route
        path="/admin/avisos"
        exact
        component={AvisosLegais}
        isPrivate
        isAdmin
      />
      <Route
        path="/admin/newaviso"
        exact
        component={NewAvisoLegal}
        isPrivate
        isAdmin
      />
      <Route
        path="/admin/editaviso"
        exact
        component={EditAvisoLegal}
        isPrivate
        isAdmin
      />

      <Route
        path="/admin/editaviso"
        exact
        component={EditAvisoLegal}
        isPrivate
        isAdmin
      />

      <Route
        path="/order/parecer"
        exact
        component={Parecer}
        isPrivate
        isAdmin
      />

      <Route
        path="/order/cadastar/pendencia"
        exact
        component={CadastrarPendencias}
        isPrivate
        isAdmin
      />

      <Route
        path="/order/pendencias"
        exact
        component={ListarPendencias}
        isPrivate
        isAdmin
      />

      <Route
        path="/order/analise"
        exact
        component={Analise}
        isPrivate
        isAdmin
      />

      <Route path="/admin/bolsas" exact component={Bolsas} isPrivate isAdmin />

      <Route path="*" component={NotFound} />
    </Switch>
  );
}
