import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { MdKeyboardBackspace, MdBorderColor } from 'react-icons/md';
import { Form } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import { store } from '../../../store';
import api from '../../../services/api';
import Input from '../../../components/InputText';
import history from '../../../services/history';
import RegistrationItems from '../../../components/Order';
import ModalDelete from '../../../components/ModalConfirmDelete';
import ModalAvisoLegal from '../../../components/ModalAvisoLegal';
import {
  Container,
  Header,
  Content,
  ContainerInfo,
  Button,
  ButtonEdit,
  ContainerButtons,
} from './styles';

export default function OrderEdit({ location }) {
  const [idFichaAnalise] = useState(location.state.idFichaAnalise);
  const [idEntidade] = useState(location.state.idEntidade);
  const [idSituacao] = useState(location.state.idSituacao);

  const { usu_admin } = store.getState().user.profile || false;

  const [initialData, setInitialData] = useState([]);

  const [requerimento, setRequerimento] = useState(null);

  const [openModalAviso, setOpenModalAviso] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [checks, setChecks] = useState();
  const [checksVisible, setChecksVisible] = useState(true);

  useEffect(() => {
    async function loadChecks() {
      const { data } = await api.get(`checkficha/${idFichaAnalise}`);
      setChecks(data);

      if (
        data.dados_pessoais &&
        data.outras_informacoes &&
        data.justificativa &&
        data.composicao_familiar &&
        data.despesas_mensais &&
        data.anexos
      )
        setChecksVisible(false);
    }

    loadChecks();
  }, [idFichaAnalise]);

  useEffect(() => {
    async function loadInformacoesFicha() {
      
        const { data } = await api.get(`fichaanalisesocial/${idFichaAnalise}`);
        setInitialData({
          usu_nome: data.fas_alu_nome,
          usu_email: data.fas_alu_email,
          usu_cpf: data.fas_alu_cpf,
          usu_rg: data.fas_alu_rg,
          usu_ra: data.fas_alu_ra,
          usu_dataNascimento: data.fas_alu_nascimento,
        });
        setRequerimento(data.fas_requerimento);
            
    }
    loadInformacoesFicha();
  }, [idFichaAnalise]);

  async function handleCompleteReview() {
    try {
      if (idSituacao === 1 || idSituacao === 2) {
        await api.put(`fichaanalisesocial/${idFichaAnalise}`, {
          situacao_id: 3,
        });
      }

      if (idSituacao === 3 && usu_admin) {
        await api.put(`fichaanalisesocial/${idFichaAnalise}`, {
          situacao_id: 4,
        });
      }

      if (idSituacao === 4) {
        await api.put(`fichaanalisesocial/${idFichaAnalise}`, {
          situacao_id: 3,
        });
      }

      if (idSituacao === 4) {
        toast.success('O pedido foi estornado!');
        history.goBack();
        return;
      }

      if (usu_admin) {
        history.push('/admin/bolsas');
        toast.success('Pedido concluído com sucesso!');
      } else {
        await api.put(`calculoindices/${idFichaAnalise}`);
        history.push('/dashboard');
        toast.success(
          'Seu pedido foi concluído, aguarde o resultado em breve!'
        );
      }
    } catch (error) {
      toast.error('Erro ao submeter conclusão do pedido!', error);
    }
  }

  async function handleDelete(id) {
    try {
      await api.delete(`fichaanalisesocial/${id}`);
      toast.success('Ficha deletada com sucesso!');

      if (usu_admin) history.push('/admin/bolsas');
      else history.goBack();
    } catch {
      toast.error('Erro ao deletar ficha análise!');
    }
  }

  function backPage() {
    if (usu_admin) history.push('/admin/bolsas');
    else history.push('/dashboard');
  }

  return (
    <Container>
      <Header>
        <h2>Solicitação de Bolsa</h2>
        <button type="button" onClick={backPage}>
          <MdKeyboardBackspace size={20} />
          <u>Voltar</u>
        </button>
      </Header>
      <ButtonEdit>
        <MdBorderColor
          size={30}
          color="#fff"
          onClick={() =>
            history.push({
              pathname: '/edit/informations',
              state: {
                idFichaAnalise,
                dadosFormulario: initialData,
              },
            })
          }
        />
      </ButtonEdit>

      <Form initialData={initialData}>
        <Content>
          <ContainerInfo>
            <h4>Nome:</h4>
            <Input type="text" name="usu_nome" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Email:</h4>
            <Input type="email" name="usu_email" autoComplete="off" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>CPF:</h4>
            <Input type="text" name="usu_cpf" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>RG:</h4>
            <Input type="text" name="usu_rg" placeholder="RG" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>RA: (00000-0 para alunos do 1º semestre)</h4>
            <Input type="text" name="usu_ra" placeholder="RA" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Data de nascimento:</h4>
            <Input
              type="date"
              name="usu_dataNascimento"
              placeholder="Data Nascimento"
              maxLength={10}
              disabled
            />
          </ContainerInfo>
        </Content>

        <ContainerButtons>
          {!usu_admin && idSituacao <= 2 && (
            <>
              <Button
                type="button"
                disabled={checksVisible}
                onClick={() => setOpenModalAviso(true)}
              >
                Enviar pedido
              </Button>

              <button
                type="button"
                className="delete"
                onClick={() => setOpenModalDelete(true)}
              >
                Excluir ficha
              </button>
            </>
          )}

          {usu_admin && idSituacao === 4 && (
            <>
              <button type="submit" className="delete">
                Excluir ficha
              </button>
              <button type="button" onClick={handleCompleteReview}>
                Estornar análise
              </button>
            </>
          )}

          {usu_admin && idSituacao >= 1 && idSituacao <= 3 && (
            <>
              <button type="button" onClick={handleCompleteReview}>
                Análise concluida
              </button>
              <button
                type="button"
                className="delete"
                onClick={() => setOpenModalDelete(true)}
              >
                Excluir ficha
              </button>
            </>
          )}
        </ContainerButtons>
      </Form>
      <RegistrationItems
        idFichaAnalise={idFichaAnalise}
        idEntidade={idEntidade}
        idRequerimento={requerimento}
        idSituacao={idSituacao}
        usuarioAdmin={usu_admin}
        checksInformations={checks}
      />

      {openModalAviso && (
        <ModalAvisoLegal
          open={openModalAviso}
          onClose={() => setOpenModalAviso(false)}
          idFichaAnalise={idFichaAnalise}
          idEntidade={idEntidade}
          usuarioAdmin={usu_admin}
        />
      )}

      {openModalDelete && (
        <ModalDelete
          open={openModalDelete}
          onClose={() => setOpenModalDelete(false)}
          itemId={idFichaAnalise}
          onDelete={handleDelete}
        />
      )}
    </Container>
  );
}
OrderEdit.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
