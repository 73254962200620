import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import { MdAttachMoney } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Header from '../../../../components/HeaderForm';
import Button from '../../../../components/Button';
import Input from '../../../../components/InputText';
import { Container, Value } from './styles';
import history from '../../../../services/history';
import api from '../../../../services/api';

export default function CadastarBens({ location }) {
  const [idBem] = useState(location.state.idBem);
  const [idEntidade] = useState(location.state.idEntidade);
  const [idFicha] = useState(location.state.idFicha);

  const schema = Yup.object().shape({
    modelo: Yup.string().required('Campo obrigatório!'),
    marca: Yup.string().required('Campo obrigatório!'),
    ano: Yup.string().required('Campo obrigatório!'),
  });

  async function handleBensDetalhes({ modelo, marca, ano }) {
    try {
      await api.post(`bensanalisesdetalhes`, {
        entidade_id: idEntidade,
        ficha_analise_id: idFicha,
        bem_id: idBem,
        bad_modelo: modelo,
        bad_marca: marca,
        bad_ano: ano,
      });

      toast.success('Registros cadastrados com sucesso!');

      history.goBack();
    } catch (error) {
      toast.error('Erro ao cadastrar!');
    }
  }

  return (
    <Container>
      <Header title="Bens" icon={MdAttachMoney} />
      <span>* Registre os detalhes do bem selecionado abaixo: </span>

      <Form schema={schema} onSubmit={handleBensDetalhes}>
        <Value>
          <label htmlFor="modelo">Modelo:</label>
          <Input type="text" name="modelo" autoComplete="off" />

          <label htmlFor="marca">Marca:</label>
          <Input type="text" name="marca" autoComplete="off" />

          <label htmlFor="ano">Ano:</label>
          <Input type="text" min="0" name="ano" autoComplete="off" />
        </Value>

        <Button type="submit">Salvar</Button>
      </Form>
    </Container>
  );
}
CadastarBens.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
