import React, { useEffect, useState } from 'react';
import {
  MdDoneAll,
  MdClear,
  MdBorderColor,
  MdPeople,
  MdDeleteForever,
} from 'react-icons/md';
import { differenceInYears } from 'date-fns';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Container, Table } from './styles';
import history from '../../../services/history';
import Header from '../../../components/HeaderForm';
import formatPrice from '../../../utils/formatPrice';
import Button from '../../../components/Button';
import api from '../../../services/api';
import Modal from '../../../components/ModalConfirmDelete';

export default function FamilyCompositionList({ location }) {
  const [idFichaAnalise] = useState(location.state.idFichaAnalise);
  const [idSituacao] = useState(location.state.idSituacao);
  const [idEntidade] = useState(location.state.idEntidade);
  const [isAdmin] = useState(location.state.usuarioAdmin);
  const [openModal, setOpenModal] = useState(false);
  const [itemId, setItemId] = useState(null);

  const [composicaoFamiliar, setComposicaoFamiliar] = useState([]);
  const [valoresTotais, setValoresTotais] = useState([]);

  useEffect(() => {
    async function loadComposicaoFamiliar() {
     
        const { data } = await api.get(
          `composicaofamiliar/ficha/${idFichaAnalise}`
        );

        const composicoes = data.composicaoFamiliar.map((registro) => ({
          ...registro,
          salario: formatPrice(registro.cfm_salario),
          outrasRendas: formatPrice(registro.cfm_outras_rendas_valor),
          gastos: formatPrice(registro.cfm_gasto_ensino),
          idade: differenceInYears(
            new Date(),
            new Date(registro.cfm_nascimento)
          ),
        }));
        setComposicaoFamiliar(composicoes);
        setValoresTotais({
          somaSalarios: formatPrice(data.valoresTotais.somaSalarios),
          somaOutrasRendas: formatPrice(data.valoresTotais.somaOutrasRendas),
          somaGastosEnsinoParticular: formatPrice(
            data.valoresTotais.somaGastosEnsinoParticular
          ),
        });
     
    }
    loadComposicaoFamiliar();
  }, [idFichaAnalise]);

  async function handleDelete(id) {
    try {
      await api.delete(`composicaofamiliar/${id}`);
      toast.success('Membro familiar deletado com sucesso!');

      history.goBack();
    } catch (error) {
      toast.error(
        `Erro ao deletar membro familiar!, ${error.response.data.message} `
      );
    }
  }

  return (
    <Container>
      <Header title="Composição familiar" icon={MdPeople} />

      {idSituacao <= 2 && (
        <Button
          className="button"
          type="button"
          onClick={() =>
            history.push({
              pathname: '/order/familycomposition/register',
              state: { idFichaAnalise, idEntidade },
            })
          }
        >
          Adicionar novo membro
        </Button>
      )}

      {!!composicaoFamiliar.length && (
        <Table idSituacao={idSituacao} isAdmin={isAdmin}>
          <thead>
            <tr>
              <th>Editar</th>
              <th>Próprio aluno</th>
              <th>Nome</th>
              <th>Parentesco</th>
              <th>Necessidade especial</th>
              <th>Idade</th>
              <th>Estado Civil</th>
              <th>Profissão</th>
              <th>Salário</th>
              <th>Outras Rendas</th>
              <th>Gastos com ensino particular</th>
            </tr>
          </thead>
          <tbody>
            {composicaoFamiliar.map(({ id, ...composicao }) => (
              <tr key={id}>
                <td>
                  <div>
                    <button type="button">
                      <MdBorderColor
                        size={20}
                        color="#fff"
                        onClick={() =>
                          history.push({
                            pathname: '/order/familycomposition/edit',
                            state: {
                              idFichaAnalise,
                              idEntidade,
                              idComposicao: id,
                            },
                          })
                        }
                      />
                    </button>
                    |
                    <button type="button">
                      <MdDeleteForever
                        size={20}
                        color="#f64c75"
                        onClick={() => {
                          setOpenModal(true);
                          setItemId(id);
                        }}
                      />
                    </button>
                  </div>
                </td>
                <td>
                  {composicao.cfm_cadastro_aluno ? (
                    <MdDoneAll size={20} color="#89c54b" />
                  ) : (
                    <MdClear size={20} color="#f64c75" />
                  )}
                </td>
                <td>{composicao.cfm_nome}</td>
                <td>{composicao.cfm_parentesco}</td>
                <td>
                  {composicao.cfm_necessidade_especial === 1 ? (
                    <MdDoneAll size={20} color="#89c54b" />
                  ) : (
                    <MdClear size={20} color="#f64c75" />
                  )}
                </td>
                <td>{composicao.idade}</td>
                <td>{composicao.cfm_estado_civil}</td>
                <td>{composicao.cfm_profissao}</td>
                <td>{composicao.salario}</td>
                <td>{composicao.outrasRendas}</td>
                <td>{composicao.gastos}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={idSituacao >= 3 && !isAdmin ? '7' : '8'}>
                Total Geral({composicaoFamiliar.length})
              </td>
              <td>{valoresTotais.somaSalarios}</td>
              <td>{valoresTotais.somaOutrasRendas}</td>
              <td>{valoresTotais.somaGastosEnsinoParticular}</td>
            </tr>
          </tfoot>
        </Table>
      )}

      {openModal && (
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          itemId={itemId}
          onDelete={handleDelete}
        />
      )}
    </Container>
  );
}
FamilyCompositionList.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
