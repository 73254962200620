import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  background: #121921;
  width: 80%;
  margin: 15px auto;
  border-radius: 8px;
  color: #fff;

  @media (max-width: 800px) {
    width: 95%;
  }

  h2 {
    font-weight: lighter;
    padding: 20px;
  }

  form {
    position: relative;
    width: 95%;
    margin: 20px;

    span {
      color: #f64c75;
      font-size: 12px;
      margin: 0;
    }
  }
`;

export const Screen = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: rgba(0, 0, 0, 0.8);

  svg {
    color: #89c54b;
  }

  h2 {
    text-align: center;
    font-weight: bold;
    color: #f0f0f5;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 855px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

export const ContainerInfo = styled.div`
  h4 {
    margin: 5px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    padding: 10px;

    color: #999;
    background: none;

    svg {
      margin-right: 5px;
    }
  }
`;
