import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import api from '../../services/api';
import history from '../../services/history';
import { InputSelect } from '../InputSelect';
import Button from '../Button';
import { Container, Card, Icon } from './styles';

export default function Modal({ onClose, open }) {
  const [entidade, setEntidade] = useState(0);

  const loadEntidade = useCallback(async () => {
    const response = await api
      .get('/entidade')
      .then((entidades) => {
        const options = entidades.data.map((entidade) => ({
          value: entidade.id,
          label: entidade.ent_nome_fantasia,
        }));
        return options;
      })
      .catch(() => {
        toast.error('Erro ao buscar entidades');
      });
    return response;
  }, []);

  const entidadeSelected = useCallback((selectedOption) => {
    setEntidade(selectedOption);
  }, []);

  const schema = Yup.object().shape({
    entidade: Yup.string().required('Campo obrigatório!'),
  });

  const closeModal = useCallback(() => {
    onClose();
    setEntidade(0);
  }, [onClose]);

  return (
    open && (
      <Container>
        <Card>
          <h3>Selecione uma entidade:</h3>

          <Form schema={schema}>
            <InputSelect
              loadOptions={loadEntidade}
              onChange={entidadeSelected}
              name="entidade"
              value={entidade}
              required
            />
            {entidade.value > 0 && (
              <Button
                type="button"
                onClick={() =>
                  history.push('/order', {
                    idEntidade: entidade.value,
                  })
                }
              >
                Continuar
              </Button>
            )}
          </Form>
          <Icon onClick={closeModal} />
        </Card>
      </Container>
    )
  );
}

Modal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
Modal.defaultProps = {
  onClose: true,
  open: true,
};
