import styled, { css } from 'styled-components';
import { darken } from 'polished';

const dragActive = css`
  border-color: #89c54b;
`;

const dragReject = css`
  border-color: #f64c75;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  padding: 5px;

  background: #121921;
  width: 80%;
  margin: 15px auto;
  border-radius: 8px;
  color: #fff;

  @media (max-width: 700px) {
    width: 95%;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 20px;

  background: #121921;
  padding: 20px;
  border-radius: 8px;
  color: #fff8;

  form {
    margin: 10px 0px;

    h4 {
      margin: 5px 0px;
    }

    span {
      color: #f64c75;
      font-size: 12px;
      margin: 0 0 10px;
    }
  }

  & + button {
    margin-top: 10px;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-weight: lighter;
    padding: 20px 5px;
  }

  button {
    display: flex;
    align-items: center;
    padding: 10px;

    color: #999;
    background: none;

    svg {
      margin-right: 5px;
    }
  }

  @media (max-width: 400px) {
    display: flex;
    flex-direction: column;

    button {
      position: relative;
      padding: 10;
    }
  }

  .documents {
    background: #89c54b;
    border: 0;
    border-radius: 18px;
    color: #fff;
    margin: 10px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.1, '#89c54b')};
    }
  }
`;

export const ContainerTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const DropContainer = styled.div.attrs({
  className: 'dropzone',
})`
  border: 2px dashed #222830;
  border-radius: 8px;
  cursor: pointer;

  padding: 30px 0px;

  display: flex;
  flex-direction: column;
  align-items: center;

  transition: height 0.2s ease;

  ${(props) => props.isDragActive && dragActive}
  ${(props) => props.isDragReject && dragReject}

  @media (max-width: 400px) {
    text-align: center;
  }

  small {
    margin-top: 10px;
  }
`;

const messageColors = {
  default: '#fff8',
  error: '#f64c75',
  success: '#89c54b',
};

export const UploadMessage = styled.strong`
  color: ${(props) => messageColors[props.type] || 'default'};

  margin-top: 10px;
  font-size: 20px;
`;
