import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import Input from '../../components/InputText';

import { signInRequest } from '../../store/modules/auth/actions';

import logo from '../../assets/images/logo.png';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um E-mail válido!')
    .required('O E-mail é obrigatório!'),
  password: Yup.string().required('A Senha é obrigatória!'),
});

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  function handleSubmit({ email, password }) {
    dispatch(signInRequest(email, password));
  }

  return (
    <>
      <div>
        <img src={logo} alt="Bolsas de Estudo" />
        <h1>Login</h1>

        <Form schema={schema} onSubmit={handleSubmit}>
          <Input
            type="email"
            name="email"
            placeholder="E-mail"
            autoComplete="off"
            required
          />

          <Input type="password" name="password" placeholder="Senha" required />

          <Link to="/recover">Recuperar Senha</Link>

          <button type="submit">{loading ? 'CARREGANDO...' : 'ENTRAR'}</button>
        </Form>

        <Link to="signup">Cadastre-se</Link>
      </div>

      <p>Desenvolvedores: Rodrigo Barreto & Pedro Gomes</p>
    </>
  );
}
