import React, { useEffect, useState } from 'react';
import { MdBorderColor, MdContacts } from 'react-icons/md';
import PropTypes from 'prop-types';
import Header from '../../../components/HeaderForm';
import { Container, Table } from './styles';
import history from '../../../services/history';
import Button from '../../../components/Button';
import formatPrice from '../../../utils/formatPrice';
import api from '../../../services/api';

export default function ListaFiadores({ location }) {
  const [idFichaAnalise] = useState(location.state.idFichaAnalise);
  const [idEntidade] = useState(location.state.idEntidade);

  const [fiadores, setFiadores] = useState([]);

  useEffect(() => {
    async function loadFiadores() {
      const { data } = await api.get(`fiadores/${idFichaAnalise}`);
      setFiadores(
        data.map((fiador) => ({
          ...fiador,
          rendaMensal: formatPrice(fiador.fia_renda_mensal),
          rendaMensalConjuge: formatPrice(fiador.fia_conjuge_renda_mensal),
        }))
      );
    }
    loadFiadores();
  }, [idFichaAnalise]);

  return (
    <Container>
      <Header title="Fiadores" icon={MdContacts} />

      <Button
        className="button"
        type="button"
        onClick={() =>
          history.push({
            pathname: '/order/cadastar/fiadores',
            state: { idFichaAnalise, idEntidade },
          })
        }
      >
        Inserir novo Fiador
      </Button>

      {!!fiadores.length && (
        <Table>
          <thead>
            <tr>
              <th>Editar</th>
              <th>Nome</th>
              <th>CPF</th>
              <th>Renda Mensal</th>
              <th>Conjuge</th>
              <th>CPF do Conjuge</th>
              <th>Renda mensal Conjuge</th>
            </tr>
          </thead>
          <tbody>
            {fiadores.map(({ id, ...fiador }) => (
              <tr key={id}>
                <td>
                  <button type="button">
                    <MdBorderColor
                      size={20}
                      color="#fff"
                      onClick={() =>
                        history.push({
                          pathname: '/order/editar/fiadores',
                          state: {
                            idFiador: id,
                            dadosFiador: fiador,
                          },
                        })
                      }
                    />
                  </button>
                </td>
                <td>{fiador.fia_nome}</td>
                <td>{fiador.fia_conjuge_cpf}</td>
                <td>{fiador.rendaMensal}</td>
                <td>{fiador.fia_conjuge}</td>
                <td>{fiador.fia_cpf}</td>
                <td>{fiador.rendaMensalConjuge}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
}
ListaFiadores.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
