import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import { MdAssignment } from 'react-icons/md';
import { toast } from 'react-toastify';
import Button from '../../../components/Button';
import Input from '../../../components/InputText';
import Header from '../../../components/HeaderForm';
import { Container, Value } from './styles';
import history from '../../../services/history';
import api from '../../../services/api';

export default function EditarDespesaMensal({ location }) {
  const [idDespesa] = useState(location.state.idDespesa);
  const [nomeDespesa] = useState(location.state.nomeDespesa);
  const [valorDespesa] = useState(location.state.valorDespesa);
  const [idFichaAnalise] = useState(location.state.idFichaAnalise);

  async function handleDespesaMensal({ valor }) {
    try {
      await api.put(`despesasmensais/${idDespesa}`, {
        dem_valor: valor,
      });

      toast.success('Valor atualizado com sucesso!');

      await api.put(`checkficha/${idFichaAnalise}`, {
        despesas_mensais: true,
      });

      history.goBack();
    } catch (error) {
      toast.error('Erro ao atualizar valor da despesa!');
    }
  }

  return (
    <Container>
      <Header title="Despesas Mensais" icon={MdAssignment} />
      <span>* Edite o valor da despesa abaixo: </span>
      <Form
        initialData={{ valor: valorDespesa }}
        onSubmit={handleDespesaMensal}
      >
        <Value>
          <span>{nomeDespesa}</span>
          <label htmlFor="valor">Valor (R$):</label>
          <Input
            type="number"
            name="valor"
            min="0"
            step="0.01"
            autoFocus
            autoComplete="off"
	    required
          />
        </Value>

        <Button type="submit">Salvar</Button>
      </Form>
    </Container>
  );
}
EditarDespesaMensal.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
