import axios from 'axios';

import invalidToken from './invalidToken';

const api = axios.create({
	baseURL: 'https://sis.fae.br:7070',
});

invalidToken(api);

export default api;
