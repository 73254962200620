import React, { useState, useCallback, useEffect } from 'react';
import { Form, Input, Check } from '@rocketseat/unform';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { Select } from '../../../../components/InputSelect';

import { Container, Header, Item, Label, Columns3 } from './styles';

const schema = Yup.object().shape({
  bem_descricao: Yup.string().required('O Bem é obrigatório!'),
  bem_ativo: Yup.string().required('Ativo é obrigatório!'),
});

export default function EditeBem({ location }) {
  const [editBem] = useState(location.state.bem);

  const [bemTipoSelected, setBemTipoSelected] = useState();

  const bem_tipo_selected = useCallback((selectedOption) => {
    setBemTipoSelected(selectedOption);
  }, []);

  const [bens_tipos] = useState([
    {
      label: 'Urbano',
      value: 1,
    },
    {
      label: 'Rural',
      value: 2,
    },
    {
      label: 'Auto',
      value: 3,
    },
    {
      label: 'Moto',
      value: 4,
    },
    {
      label: 'Caminhão',
      value: 5,
    },
  ]);

  useEffect(() => {
    const opcaoBem = bens_tipos.find(
      (opcao) => opcao.value === editBem.bem_tipo
    );

    setBemTipoSelected(opcaoBem);
  }, [bens_tipos, editBem]);

  async function handleEdit({ bem_ativo, bem_descricao }) {
    try {
      await api.put(`/bens/${editBem.id}`, {
        bem_ativo,
        bem_descricao,
        bem_tipo: bemTipoSelected.value,
      });

      toast.success('Bem editado com sucesso!');

      history.push('/admin/bens');
    } catch (error) {
      toast.error(`Erro ao editar Bem, verifique os dados!`);
    }
  }

  return (
    <Container>
      <Header>
        <Link to="/admin/bens">
          <u>Voltar</u>
        </Link>
        <h1>Edição de Bem</h1>
      </Header>

      <Form schema={schema} onSubmit={handleEdit} initialData={editBem}>
        <Columns3>
          <Item>
            <Label>Periodo</Label>
            <Input type="text" name="bem_descricao" placeholder="Periodo" />
          </Item>

          <Item className="select">
            <label>Tipo de bem</label>
            <Select
              options={bens_tipos}
              onChange={bem_tipo_selected}
              placeholder="Selecione um tipo"
              name="bem_tipo"
              value={bemTipoSelected}
            />
          </Item>

          <Item className="ativo">
            <Label>Ativo</Label>
            <Check type="checkbox" name="bem_ativo" />
          </Item>
        </Columns3>

        <button type="submit">Salvar</button>
      </Form>
    </Container>
  );
}

EditeBem.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
