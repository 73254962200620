import React, { useState, useCallback } from 'react';
import { Form, Input } from '@rocketseat/unform';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Select } from '../../../../components/InputSelect';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { store } from '../../../../store';

import { Container, Header, Item, Columns2, Erro } from './styles';

const schema = Yup.object().shape({
  bem_descricao: Yup.string().required('A descrição é obrigatório!'),
});

export default function NewBem() {
  const { entity } = store.getState().entity;
  const [bemTipoSelected, setBemTipoSelected] = useState(0);
  const [errorSelect, setErrorSelect] = useState(false);
  const [bens_tipos] = useState([
    {
      label: 'Urbano',
      value: 1,
    },
    {
      label: 'Rural',
      value: 2,
    },
    {
      label: 'Auto',
      value: 3,
    },
    {
      label: 'Moto',
      value: 4,
    },
    {
      label: 'Caminhão',
      value: 5,
    },
  ]);

  const bem_tipo_selected = useCallback((selectedOption) => {
    setBemTipoSelected(selectedOption);
  }, []);

  async function handleSubmit({ bem_descricao }) {
    if (bemTipoSelected === 0) {
      setErrorSelect(true);
      return;
    }

    try {
      await api.post('bens', {
        entidade_id: entity,
        bem_descricao,
        bem_tipo: bemTipoSelected.value,
      });

      toast.success('Bem cadastrado com sucesso!');

      history.push('/admin/bens');
    } catch (error) {
      toast.error('Erro ao cadastrar Bem, verifique os dados!');
    }
  }

  return (
    <Container>
      <Header>
        <Link to="/admin/bens">
          <u>Voltar</u>
        </Link>
        <h1>Gerenciamento de Bens</h1>
      </Header>

      <Form schema={schema} onSubmit={handleSubmit}>
        <Columns2>
          <Item>
            <Input
              type="text"
              name="bem_descricao"
              placeholder="Descrição do Bem"
            />
          </Item>
          <Item className="select">
            <Select
              options={bens_tipos}
              onChange={bem_tipo_selected}
              placeholder="Selecione um tipo"
              name="bem_tipo"
              value={bemTipoSelected}
            />
            {errorSelect && <Erro>Selecione uma opção</Erro>}
          </Item>
        </Columns2>

        <button type="submit">Cadastrar</button>
      </Form>
    </Container>
  );
}
