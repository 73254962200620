import React, { useState, useCallback } from 'react';
import { MdContacts } from 'react-icons/md';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Input from '../../../components/InputText';
import Header from '../../../components/HeaderForm';
import { mascaraCep, mascaraCpf, mascaraCelular } from '../../../utils/masks';
import { Container } from './styles';
import Button from '../../../components/Button';
import history from '../../../services/history';

import api from '../../../services/api';

export default function EditarFiadores({ location }) {
  const [idFiador] = useState(location.state.idFiador);
  const [dadosFiador] = useState(location.state.dadosFiador);
  const [inititalData] = useState({
    nome: dadosFiador.fia_nome,
    datanascimento: dadosFiador.fia_nascimento,
    cpf: dadosFiador.fia_cpf,
    rg: dadosFiador.fia_rg,
    profissao: dadosFiador.fia_profissao,
    rendamensal: dadosFiador.fia_renda_mensal,
    estadocivil: dadosFiador.fia_estado_civil,
    naturalidade: dadosFiador.fia_naturalidade,
    telefone: dadosFiador.fia_telefone,
    celular: dadosFiador.fia_celular,
    email: dadosFiador.fia_email,

    cep: dadosFiador.fia_endereco_cep,
    cidade: dadosFiador.fia_endereco_cidade,
    complemento: dadosFiador.fia_endereco_complemento,
    endereco: dadosFiador.fia_endereco,
    bairro: dadosFiador.fia_endereco_bairro,
    numero: dadosFiador.fia_endereco_numero,
    uf: dadosFiador.fia_endereco_uf,

    nomeconjuge: dadosFiador.fia_conjuge,
    datanascimentoconjuge: dadosFiador.fia_conjuge_nascimento,
    cpfconjuge: dadosFiador.fia_conjuge_cpf,
    rgconjuge: dadosFiador.fia_conjuge_rg,
    profissaoconjuge: dadosFiador.fia_conjuge_profissao,
    rendamensalconjuge: dadosFiador.fia_conjuge_renda_mensal,
  });

  const onKeyUpCep = useCallback((event) => {
    mascaraCep(event);
  }, []);

  const onKeyUpCpf = useCallback((event) => {
    mascaraCpf(event);
  }, []);

  const onKeyUpCelular = useCallback((event) => {
    mascaraCelular(event);
  }, []);

  const schema = Yup.object().shape({
    nome: Yup.string().required('Campo obrigatório'),
    datanascimento: Yup.string().required('Campo obrigatório'),
    cpf: Yup.string().required('Campo obrigatório'),
    rg: Yup.string(),
    profissao: Yup.string(),
    rendamensal: Yup.string(),
    estadocivil: Yup.string(),
    naturalidade: Yup.string(),
    telefone: Yup.string(),
    celular: Yup.string().required(),
    email: Yup.string().email(),

    cep: Yup.string(),
    cidade: Yup.string(),
    complemento: Yup.string(),
    endereco: Yup.string(),
    bairro: Yup.string(),
    numero: Yup.string(),
    uf: Yup.string(),

    nomeconjuge: Yup.string(),
    datanascimentoconjuge: Yup.string(),
    cpfconjuge: Yup.string(),
    rgconjuge: Yup.string(),
    profissaoconjuge: Yup.string(),
    rendamensalconjuge: Yup.string(),
  });

  async function handleCadastarFiador(data) {
    try {
      await api.put(`fiadores/${idFiador}`, {
        fia_nome: data.nome,
        fia_nascimento: data.datanascimento,
        fia_cpf: data.cpf,
        fia_rg: data.rg,
        fia_profissao: data.profissao,
        fia_renda_mensal: data.rendamensal,
        fia_estado_civil: data.estadocivil,
        fia_naturalidade: data.naturalidade,
        fia_conjuge: data.nomeconjuge,
        fia_conjuge_nascimento: data.datanascimentoconjuge,
        fia_conjuge_cpf: data.cpfconjuge,
        fia_conjuge_rg: data.rgconjuge,
        fia_conjuge_profissao: data.profissaoconjuge,
        fia_conjuge_renda_mensal: data.rendamensalconjuge,
        fia_endereco_cep: data.cep,
        fia_endereco: data.endereco,
        fia_endereco_numero: data.numero,
        fia_endereco_complemento: data.complemento,
        fia_endereco_bairro: data.bairro,
        fia_endereco_cidade: data.cidade,
        fia_endereco_uf: data.uf,
        fia_telefone: data.telefone,
        fia_celular: data.celular,
        fia_email: data.email,
      });

      toast.success('Fiador atualizado com sucesso!');

      history.goBack();
    } catch (error) {
      toast.error('Erro ao atualizar, verifique os dados novamente!');
    }
  }

  return (
    <Container>
      <Header title="Fiadores" icon={MdContacts} />

      <span>
        * Preencha os campos abaixo para atualizar o fiador selecionado
      </span>
      <Form
        schema={schema}
        initialData={inititalData}
        onSubmit={handleCadastarFiador}
      >
        <h2>* Dados Pessoais</h2>

        <h4>Nome do fiador:</h4>
        <Input type="text" name="nome" autoComplete="off" />

        <h4>Data de nascimento:</h4>
        <Input type="date" name="datanascimento" autoComplete="off" />

        <h4>CPF:</h4>
        <Input type="text" name="cpf" autoComplete="off" onKeyUp={onKeyUpCpf} />

        <h4>RG:</h4>
        <Input type="text" name="rg" autoComplete="off" />

        <h4>Profissão:</h4>
        <Input type="text" name="profissao" autoComplete="off" />

        <h4>Renda mensal:</h4>
        <Input type="number" step="0.01" name="rendamensal" autoComplete="off" />

        <h4>Estado civil:</h4>
        <Input type="text" name="estadocivil" autoComplete="off" />

        <h4>Naturalidade:</h4>
        <Input type="text" name="naturalidade" autoComplete="off" />

        <h4>Telefone:</h4>
        <Input
          type="text"
          name="telefone"
          autoComplete="off"
          onKeyUp={onKeyUpCelular}
        />

        <h4>Celular:</h4>
        <Input
          type="text"
          name="celular"
          autoComplete="off"
          onKeyUp={onKeyUpCelular}
        />

        <h4>Email:</h4>
        <Input type="text" name="email" autoComplete="off" />

        <h2>* Endereço</h2>

        <h4>Cep:</h4>
        <Input type="text" name="cep" autoComplete="off" onKeyUp={onKeyUpCep} />
        <h4>Cidade:</h4>
        <Input type="text" name="cidade" autoComplete="off" />
        <h4>Complemento:</h4>
        <Input type="text" name="complemento" autoComplete="off" />
        <h4>Rua:</h4>
        <Input type="text" name="endereco" autoComplete="off" />
        <h4>Bairro:</h4>
        <Input type="text" name="bairro" autoComplete="off" />
        <h4>Número residencial:</h4>
        <Input type="number" name="numero" autoComplete="off" min="0" />
        <h4>UF:</h4>
        <Input type="text" name="uf" autoComplete="off" maxLength={2} />

        <h2>* Conjuge</h2>

        <h4>Nome do Conjuge:</h4>
        <Input type="text" name="nomeconjuge" autoComplete="off" />

        <h4>Data de nascimento:</h4>
        <Input type="date" name="datanascimentoconjuge" autoComplete="off" />

        <h4>CPF:</h4>
        <Input
          type="text"
          name="cpfconjuge"
          autoComplete="off"
          onKeyUp={onKeyUpCpf}
        />

        <h4>RG:</h4>
        <Input type="text" name="rgconjuge" autoComplete="off" />

        <h4>Profissão:</h4>
        <Input type="text" name="profissaoconjuge" autoComplete="off" />

        <h4>Renda mensal:</h4>
        <Input type="number" step="0.01" name="rendamensalconjuge" autoComplete="off" />

        <Button type="submit">Atualizar fiador</Button>
      </Form>
    </Container>
  );
}
EditarFiadores.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
