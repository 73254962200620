import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import Input from '../../components/InputText';

import api from '../../services/api';
import history from '../../services/history';

import logo from '../../assets/images/logo.png';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um E-mail válido!')
    .required('O E-mail é obrigatório!'),
});

export default function SignIn() {
  const [loading, setLoading] = useState();

  async function handleSubmit({ email }) {
    try {
      setLoading(true);

      await api.post('recuperarsenha', { usu_email: email });

      toast.success('Senha recuperada com sucesso, verifique seu e-mail!');

      setLoading(false);

      history.push('/');
    } catch (error) {
      setLoading(false);

      toast.error(`${error.response.data.message}!`);
    }
  }

  return (
    <>
      <div>
        <img src={logo} alt="Bolsas de Estudo" />
        <h2>Recuperação de senha</h2>
        <br />
        <Form schema={schema} onSubmit={handleSubmit}>
          <h4>Digite seu e-mail abaixo: </h4>
          <Input type="email" name="email" autoComplete="off" required />

          {loading ? (
            <button disabled type="submit">
              CARREGANDO...
            </button>
          ) : (
            <button type="submit">Recuperar senha</button>
          )}
        </Form>

        <Link to="/">
          <u>Voltar</u>
        </Link>
      </div>

      <p>Desenvolvedores: Rodrigo Barreto & Pedro Gomes</p>
    </>
  );
}
