import React, { useState, useEffect, useCallback } from 'react';
import {
  MdSearch,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdBorderColor,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import api from '../../../services/api';
import history from '../../../services/history';
import { store } from '../../../store';
import formatPrice from '../../../utils/formatPrice';
import ModalExcel from '../../../components/ModalExcel';
import { InputSelect, Select } from '../../../components/InputSelect';

import {
  Container,
  Header,
  Search,
  InputFilter,
  Button,
  Main,
  Table,
  Footer,
  Item,
  Filter,
} from './styles';

export default function Fichas() {
  const { entity } = store.getState().entity;

  const [fichas, setFichas] = useState();
  const [urlExcel, setUrlExcel] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [filter, setFilter] = useState();
  const [fichasTotal, setFichasTotal] = useState();
  const [page, setPage] = useState(1);
  const [limitFromPage] = useState(10);
  const [numberFromPage, setNumberFromPage] = useState();
  const [initialNumberFromPage, setInitialNumberFromPage] = useState();

  const [visible, setVisible] = useState(false);
  const [situacaoSelected, setSituacaoSelected] = useState(0);
  const [periodoLetivo, setPeriodoLetivo] = useState('');
  const [curso, setCurso] = useState('');
  const [situacao] = useState([
    {
      label: 'Pedido em aberto',
      value: 1,
    },
    {
      label: 'Pedido com Pendência',
      value: 2,
    },
    {
      label: 'Pedido em análise',
      value: 3,
    },
    {
      label: 'Pedido analisado',
      value: 4,
    },
  ]);

  const situacao_selected = useCallback((selectedOption) => {
    setSituacaoSelected(selectedOption);
  }, []);

  useEffect(() => {
    async function loadEntidades() {
      const response = await api.get('fichaanaliseadmin', {
        params: {
          nomeAluno: filter,
          page,
          entidade_id: entity,
          periodoLetivo: periodoLetivo.label,
          tipoSituacao: situacaoSelected.label,
          nomeCurso: curso.label,
        },
      });

      const fichasAlunos = response.data.map((ficha) => ({
        ...ficha,
        valorFormatado: formatPrice(ficha.fas_valor_mensalidade),
        dataCriacao: format(new Date(ficha.created_at), 'dd/MM/yyyy'),
        ultimaAtualizacao: format(new Date(ficha.updated_at), 'dd/MM/yyyy'),
      }));

      setFichas(fichasAlunos);

      setFichasTotal(response.headers.x_total_count);
    }

    loadEntidades();
  }, [filter, page, entity, periodoLetivo, situacaoSelected, curso]);

  useEffect(() => {
    function handleLimits() {
      if (page >= 1) {
        if (page * limitFromPage > fichasTotal) {
          setNumberFromPage(fichasTotal);
        } else {
          setNumberFromPage(page * limitFromPage);
        }
      } else {
        setNumberFromPage(1);
      }

      if (page >= 1) {
        if (fichasTotal <= 0) {
          setInitialNumberFromPage(0);
        } else {
          setInitialNumberFromPage(page * limitFromPage + 1 - limitFromPage);
        }
      }
    }

    handleLimits();
  }, [page, fichasTotal, numberFromPage, limitFromPage]);

  const loadPeriodosLetivos = useCallback(async () => {
    const response = await api
      .get(`/periodoletivo/${entity}`)
      .then((periodoletivo) => {
        const options = periodoletivo.data.map((periodo) => ({
          value: periodo.id,
          label: periodo.pel_descricao,
        }));
        return options;
      })
      .catch(() => {
        toast.error('Erro ao buscar periodos letivos');
      });
    return response;
  }, [entity]);

  const loadCursos = useCallback(async () => {
    const response = await api
      .get(`/curso/${entity}`)
      .then((cursos) => {
        const options = cursos.data.map((curso) => ({
          value: curso.id,
          label: curso.cur_descricao,
        }));
        return options;
      })
      .catch(() => {
        toast.error('Erro ao buscar os cursos');
      });
    return response;
  }, [entity]);

  function handlePageSum() {
    const numberpages = Math.ceil(fichasTotal / limitFromPage);

    if (page < numberpages) {
      setPage(page + 1);
    }
  }

  function handleNewEntidade() {
    history.push({
      pathname: '/order',
      state: {
        idEntidade: entity,
      },
    });
  }

  async function handleExcel() {
    const response = await api.get('excel', {
      params: {
        situacao: situacaoSelected.value,
        curso: curso.value,
        anoletivo: periodoLetivo.value,
      },
    });
    setUrlExcel(response.data);
    setOpenModal(true);
  }

  function handlePageSubtract() {
    if (!(page <= 1)) {
      setPage(page - 1);
    }
  }

  function handleEditFicha(idFichaAnalise, idSituacao) {
    history.push({
      pathname: '/order/edit',
      state: {
        idFichaAnalise,
        idEntidade: entity,
        idSituacao,
      },
    });
  }

  function LimpaFiltros() {
    setPeriodoLetivo('');
    setSituacaoSelected('');
    setCurso('');
  }

  function handleVisible() {
    setVisible(!visible);
  }

  return (
    <Container>
      <Header>
        <h1>Pedidos de Bolsas de Estudo</h1>

        <Search>
          <InputFilter>
            <div className="icone">
              <button onClick={handleVisible} type="button">
                <MdSearch size={20} />
              </button>
            </div>
            <input
              placeholder="Buscar Aluno"
              onChange={(event) => setFilter(event.target.value)}
              type="text"
            />

            <Filter visible={visible}>
              <Item>
                <InputSelect
                  placeholder="Ano letivo"
                  loadOptions={loadPeriodosLetivos}
                  onChange={setPeriodoLetivo}
                  name="periodoletivo"
                  value={periodoLetivo}
                />
              </Item>
              <Item>
                <InputSelect
                  placeholder="Curso"
                  loadOptions={loadCursos}
                  onChange={setCurso}
                  onMenuOpen={loadCursos}
                  name="periodoletivo"
                  value={curso}
                />
              </Item>
              <Item>
                <Select
                  options={situacao}
                  onChange={situacao_selected}
                  placeholder="Situação"
                  name="bem_tipo"
                  value={situacaoSelected}
                />
              </Item>

              <button onClick={LimpaFiltros} type="button">
                Limpar filtros
              </button>
            </Filter>
          </InputFilter>

          <Button type="button" onClick={handleExcel}>
            Excel
          </Button>

          <Button type="button" onClick={handleNewEntidade}>
            Nova
          </Button>
        </Search>
      </Header>
      <Main>
        <Table>
          <thead>
            <tr>
              <th>Ações</th>
              <th>Situação</th>
              <th>Nome</th>
              <th>Curso</th>
              <th>Periodo cursando</th>
              <th>RA</th>
              <th>E-mail</th>
              <th>Bolsa %</th>
              <th>Mensalidade com desconto</th>
              <th>Data de criação</th>
              <th>Última atualização</th>
            </tr>
          </thead>
          <tbody>
            {fichas &&
              fichas.map(({ id, ...ficha }) => (
                <tr key={id}>
                  <td>
                    <button
                      type="button"
                      onClick={() => handleEditFicha(id, ficha.situacao.id)}
                    >
                      <MdBorderColor size={20} />
                    </button>
                  </td>
                  <td>{ficha.situacao.sit_descricao}</td>
                  <td>{ficha.fas_alu_nome}</td>
                  <td>{ficha.curso.cur_descricao}</td>
                  <td>{ficha.periodo.per_descricao}</td>
                  <td>{ficha.fas_alu_ra}</td>
                  <td>{ficha.fas_alu_email}</td>
                  <td>
                    {ficha.fas_bolsam && ficha.situacao.id === 4
                      ? `${ficha.fas_bolsam}%`
                      : '0%'}
                  </td>
                  <td>{ficha.valorFormatado || 0}</td>
                  <td>{ficha.dataCriacao}</td>
                  <td>{ficha.ultimaAtualizacao}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Main>

      <Footer>
        <div>
          <MdKeyboardArrowLeft size={24} onClick={handlePageSubtract} />
          {page}
          <MdKeyboardArrowRight size={24} onClick={handlePageSum} />
        </div>

        <div>
          <span>{initialNumberFromPage}</span>
          <span>a</span>
          <span>{numberFromPage && numberFromPage}</span>
          <span>de</span>
          <span>{fichasTotal}</span>
        </div>
      </Footer>

      {openModal && (
        <ModalExcel
          open={openModal}
          onClose={() => setOpenModal(false)}
          urlExcel={urlExcel}
        />
      )}
    </Container>
  );
}
