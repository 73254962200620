import React from 'react';
import { Form, Input, Check } from '@rocketseat/unform';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import history from '../../../../services/history';
import { store } from '../../../../store';

import { Container, Header, Columns2, Item, Label } from './styles';

const schema = Yup.object().shape({
  des_descricao: Yup.string().required('O Nome é obrigatório!'),
  des_ind_dd: Yup.string().required('Ativo é obrigatório!'),
});

export default function NewDespesa() {
  const { entity } = store.getState().entity;

  async function handleSubmit({ des_descricao, des_ind_dd }) {
    try {
      await api.post('despesa', {
        des_descricao,
        des_ind_dd,
        entidade_id: entity,
      });

      toast.success('Despesa cadastrada com sucesso!');

      history.push('/admin/despesas');
    } catch (error) {
      toast.error('Erro ao cadastrar Despesa, verifique os dados!');
    }
  }

  return (
    <Container>
      <Header>
        <Link to="/admin/despesas">
          <u>Voltar</u>
        </Link>
        <h1>Gerenciamento de Despesas</h1>
      </Header>

      <Form schema={schema} onSubmit={handleSubmit}>
        <Columns2>
          <Item>
            <Label>Descrição da Despesa</Label>
            <Input type="text" name="des_descricao" placeholder="Descrição" />
          </Item>
          <Item className="ativo">
            <Label>Entra no cálculo do Índice DD</Label>
            <Check type="checkbox" name="des_ind_dd" />
          </Item>
        </Columns2>

        <button type="submit">Cadastrar</button>
      </Form>
    </Container>
  );
}
