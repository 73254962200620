import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  width: 100%;
  background: #121921;
  border-radius: 8px;
  margin: 20px;

  form {
    width: 100%;
    padding: 0 20px;

    button {
      float: right;
    }
  }
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  margin: 24px 0;

  a {
    position: absolute;
    left: 20px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;

  span {
    width: 100%;
    float: left;
  }
`;

export const Columns2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 10px;
`;

export const Columns3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0 10px;
`;

export const Row = styled.div`
  height: 2px;
  margin: 20px 5px;

  background: #222830;
`;

export const Info = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 0 20px;

  svg {
    cursor: pointer;
    margin: 16px 8px 16px 0;
    color: #fff;
  }

  span {
    padding: 16px;
    width: 100%;
    color: #856404;
    background: #ffcd30;
    border: 1px solid #ffcd30;
    text-align: center;

    border-radius: 4px;
    display: ${(props) => (props.visible ? 'block' : 'none')};
  }
`;
