import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MdWarning } from 'react-icons/md';
import { toast } from 'react-toastify';
import Button from '../Button';
import api from '../../services/api';
import history from '../../services/history';

import { Container, Card, Title, List, Icon } from './styles';

export default function ModalAvisoLegal({
  onClose,
  open,
  idFichaAnalise,
  idEntidade,
  usuarioAdmin,
}) {
  const [avisosLegais, setAvisosLegais] = useState([]);

  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    async function loadAvisosLegais() {
      const response = await api.get(`avisoslegais/${idEntidade}`);

      setAvisosLegais(response.data);
    }

    loadAvisosLegais();
  }, [idEntidade]);

  async function handleCompleteReview() {
    try {
      await api.put(`fichaanalisesocial/${idFichaAnalise}`, {
        situacao_id: 3,
      });

      await api.put(`calculoindices/${idFichaAnalise}`);

      if (usuarioAdmin) history.push('/admin/bolsas');
      else history.push('/dashboard');

      toast.success('Seu pedido foi concluído, aguarde o resultado em breve!');
    } catch (error) {
      toast.error('Erro ao enviar pedido!');
    }
  }

  return (
    open && (
      <Container>
        <Card>
          <Title>
            <MdWarning size={30} color="#ffcd30" />
            <h1>Aviso Legal</h1>
          </Title>

          <List>
            {avisosLegais.map((avisolegal) => (
              <li key={avisolegal.id}>{avisolegal.avl_descricao}</li>
            ))}
          </List>

          <Button onClick={handleCompleteReview}>Concordo</Button>
          <Icon onClick={closeModal} />
        </Card>
      </Container>
    )
  );
}

ModalAvisoLegal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  idFichaAnalise: PropTypes.number.isRequired,
  idEntidade: PropTypes.number.isRequired,
  usuarioAdmin: PropTypes.bool.isRequired,
};
ModalAvisoLegal.defaultProps = {
  onClose: true,
  open: true,
};
