import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdExitToApp, MdAccountCircle } from 'react-icons/md';

import { signOut } from '../../store/modules/auth/actions';
import { handleEntityOut } from '../../store/modules/entity/actions';

import logo from '../../assets/images/logo.png';
import logoMin from '../../assets/images/Logo.svg';

import { Container, Content, Profile } from './styles';

export default function Header() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);

  function handleSignOut() {
    dispatch(signOut());
    dispatch(handleEntityOut());
  }

  return (
    <Container>
      <Content>
        <nav>
          <img className="logo" src={logo} alt="Bolsas de Estudo" />
          <img className="logoMin" src={logoMin} alt="Bolsas de Estudo" />
        </nav>

        <aside>
          <Profile>
            <div>
              <strong>{profile && profile.usu_nome}</strong>
              <div>
                <Link to="/profile">
                  <MdAccountCircle />
                  <span>Perfil</span>
                </Link>
                <button type="button" onClick={handleSignOut}>
                  <span>Sair</span>
                  <MdExitToApp />
                </button>
              </div>
            </div>
          </Profile>
        </aside>
      </Content>
    </Container>
  );
}
