import React from 'react';
import PropTypes from 'prop-types';
import { MdKeyboardBackspace } from 'react-icons/md';
import history from '../../services/history';
import { Header } from './styles';

export default function HeaderForm({ title, icon: Icon }) {
  return (
    <Header>
      <Icon size={30} />
      <h2>{title}</h2>
      <button type="button" onClick={() => history.goBack()}>
        <MdKeyboardBackspace size={20} />
        <u>Voltar</u>
      </button>
    </Header>
  );
}

HeaderForm.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.any]),
};

HeaderForm.defaultProps = {
  icon: '',
};
