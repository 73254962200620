import React, { useState } from 'react';
import { Form } from '@rocketseat/unform';
import PropTypes from 'prop-types';
import { MdKeyboardBackspace } from 'react-icons/md';
import { toast } from 'react-toastify';
import history from '../../services/history';
import api from '../../services/api';
import Input from '../../components/InputText';
import Button from '../../components/Button';

import { Container, Header } from './styles';

export default function InformacoesCadastro({ location }) {
  const [idFichaAnalise] = useState(location.state.idFichaAnalise);
  const [dadosFormulario] = useState(location.state.dadosFormulario);

  async function handleSaveInformations(data) {
    try {
      await api.put(`fichaanalisesocial/${idFichaAnalise}`, {
        fas_alu_ra: data.usu_ra,
        fas_alu_nome: data.usu_nome,
        fas_alu_rg: data.usu_rg,
        fas_alu_email: data.usu_email,
      });

      toast.success('Dados atualizados com sucesso!');
      history.goBack();
    } catch (error) {
      toast.error(
        `Erro ao atualizar os dados!, ${error.response.data.message}`
      );
    }
  }

  return (
    <Container>
      <Header>
        <h2>Atualização de informações</h2>
        <button type="button" onClick={() => history.goBack()}>
          <MdKeyboardBackspace size={20} />
          <u>Voltar</u>
        </button>
      </Header>
      <Form initialData={dadosFormulario} onSubmit={handleSaveInformations}>
        <h4>Nome:</h4>
        <Input type="text" name="usu_nome" />
        <h4>Data de nascimento:</h4>
        <Input
          type="date"
          name="usu_dataNascimento"
          placeholder="Data Nascimento"
          maxLength={10}
          disabled
        />
        <h4>CPF:</h4>
        <Input type="text" name="usu_cpf" disabled />

        <h4>Email:</h4>
        <Input type="email" name="usu_email" autoComplete="off" />

        <h4>RG:</h4>
        <Input type="text" name="usu_rg" placeholder="RG" />
        <h4>RA: (00000-0 para alunos do 1º semestre)</h4>
        <Input type="text" name="usu_ra" placeholder="RA" />

        <Button type="submit">Atualizar informações</Button>
      </Form>
    </Container>
  );
}
InformacoesCadastro.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
