import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import AuthLayout from '../pages/_layouts/auth';
import DefaultLayout from '../pages/_layouts/default';
import AdminLayout from '../pages/_layouts/admin';

import { store } from '../store';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  isAdmin,
  ...rest
}) {
  const { signed } = store.getState().auth;

  const { usu_admin } = store.getState().user.profile || false;

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if (signed && !usu_admin && isAdmin) {
    return <Redirect to="/dashboard" />;
  }

  if (signed && !isPrivate && !usu_admin) {
    return <Redirect to="/dashboard" />;
  }

  if (signed && !isPrivate && usu_admin) {
    return <Redirect to="/admin" />;
  }

  const Layout = signed ? DefaultLayout : AuthLayout;

  const LayoutDefault = signed && usu_admin ? AdminLayout : Layout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <LayoutDefault>
          <Component {...props} />
        </LayoutDefault>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isAdmin: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  isAdmin: false,
};
