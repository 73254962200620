import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  background: #121921;
  padding: 0 30px;
`;

export const Content = styled.div`
  height: 64px;
  margin: 0 auto;
  color: #fff8;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 500px) {
    .logo {
      display: none;
    }
  }

  @media (min-width: 501px) {
    .logoMin {
      display: none;
    }
  }

  img {
    height: 40px;
  }
`;

export const Profile = styled.div`
  margin-left: 20px;
  padding-left: 20px;
  border-left: 1px solid #fff2;

  div > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 0;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff8;

      &:hover {
        color: ${darken(0.2, '#fff8')};
      }

      span {
        padding: 2px;
      }
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;

      color: #f64c75;
      padding-left: 15px;
      border: 0;
      background: transparent;

      &:hover {
        color: ${darken(0.2, '#f64c75')};
      }
      span {
        padding: 2px;
      }
    }
  }
`;
