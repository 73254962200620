import React from 'react';
import { Form, Input } from '@rocketseat/unform';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { store } from '../../../../store';

import { Container, Header, Item } from './styles';

const schema = Yup.object().shape({
  pel_descricao: Yup.string().required('A Descrição é obrigatório!'),
});

export default function NewAnoLetivo() {
  const { entity } = store.getState().entity;

  async function handleSubmit({ pel_descricao }) {
    try {
      await api.post('periodoletivo', { entidade_id: entity, pel_descricao });

      toast.success('Ano Letivo cadastrado com sucesso!');

      history.push('/admin/periodosletivo');
    } catch (error) {
      toast.error('Erro ao cadastrar Ano letivo, verifique os dados!');
    }
  }

  return (
    <Container>
      <Header>
        <Link to="/admin/periodosletivo">
          <u>Voltar</u>
        </Link>
        <h1>Gerenciamento de Anos Letivos</h1>
      </Header>

      <Form schema={schema} onSubmit={handleSubmit}>
        <Item>
          <Input type="text" name="pel_descricao" placeholder="Ano Letivo" />
        </Item>

        <button type="submit">Cadastrar</button>
      </Form>
    </Container>
  );
}
