import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    font-weight: lighter;
    padding: 20px 5px;
  }

  button {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    padding: 10px;

    color: #999;
    background: none;

    svg {
      margin-right: 5px;
    }
  }

  @media (max-width: 400px) {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-top: 15px;

    button {
      position: relative;
      padding: 0;

      margin-top: 10px;
    }

    h2 {
      margin: 0;
      padding: 5px;
    }
  }
`;
