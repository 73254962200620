import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import { MdKeyboardBackspace } from 'react-icons/md';
import { FaRegEdit } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Input from '../../../components/InputText';
import Button from '../../../components/Button';
import { Container, Header } from './styles';
import history from '../../../services/history';
import api from '../../../services/api';

export default function Parecer({ location }) {
  const [idFichaAnalise] = useState(location.state.idFichaAnalise);
  const [idSituacao] = useState(location.state.idSituacao);

  const [dadosEntrevista, setDadosEntrevista] = useState({});

  useEffect(() => {
    async function loadDadosFicha() {
      const { data } = await api.get(`fichaanalisesocial/${idFichaAnalise}`);

      setDadosEntrevista({
        entrevista: data.fas_entrevista,
        parecer: data.fas_parecer,
      });
    }
    loadDadosFicha();
  }, [idFichaAnalise]);

  async function handleSubmit({ entrevista, parecer }) {
    try {
      await api.put(`fichaanalisesocial/${idFichaAnalise}`, {
        fas_parecer: parecer,
        fas_entrevista: entrevista,
      });

      toast.success('Registros atualizados com sucesso!');
      history.goBack();
    } catch (error) {
      toast.error('Erro ao atualizar os dados, verifique suas informações');
    }
  }

  return (
    <Container>
      <Header>
        <FaRegEdit size={30} />
        <h2>Parecer</h2>

        <button type="button" onClick={() => history.goBack()}>
          <MdKeyboardBackspace size={20} />
          <u>Voltar</u>
        </button>
      </Header>

      <Form
        onSubmit={handleSubmit}
        initialData={{
          entrevista: dadosEntrevista.entrevista,
          parecer: dadosEntrevista.parecer,
        }}
      >
        <h3>Entrevista:</h3>

        <Input multiline type="text" name="entrevista" />
        <h3>Parecer:</h3>

        <Input multiline type="text" name="parecer" />

        {idSituacao === 2 || idSituacao === 3 ? (
	  <Button type="submit">Continuar</Button>
	) : (
	  ''
	)}
      </Form>
    </Container>
  );
}
Parecer.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
