import React, { useState } from 'react';
import { Form, Input, Check } from '@rocketseat/unform';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { Container, Header, Item, Label, Columns2 } from './styles';

const schema = Yup.object().shape({
  avl_descricao: Yup.string().required('A descrição é obrigatório!'),
  avl_ativo: Yup.string().required('Ativo é obrigatório!'),
});

export default function EditAviso({ location }) {
  const [editAviso] = useState(location.state.aviso);

  async function handleEdit(avisoLegal) {
    try {
      await api.put(`/avisoslegais/${editAviso.id}`, avisoLegal);

      toast.success('Aviso Legal editado com sucesso!');

      history.push('/admin/avisos');
    } catch (error) {
      toast.error(`Erro ao editar Aviso Legal, verifique os dados!`);
    }
  }

  return (
    <Container>
      <Header>
        <Link to="/admin/avisos">
          <u>Voltar</u>
        </Link>
        <h1>Edição de Aviso Legal</h1>
      </Header>

      <Form schema={schema} onSubmit={handleEdit} initialData={editAviso}>
        <Columns2>
          <Item>
            <Label>Descrição do Aviso Legal</Label>
            <Input
              multiline
              rows="10"
              type="text"
              name="avl_descricao"
              placeholder="Descrição"
            />
          </Item>

          <Item className="ativo">
            <Label>Ativo</Label>
            <Check type="checkbox" name="avl_ativo" />
          </Item>
        </Columns2>

        <button type="submit">Salvar</button>
      </Form>
    </Container>
  );
}

EditAviso.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
