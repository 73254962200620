import React, { useCallback, useState, useEffect } from 'react';
import { Form } from '@rocketseat/unform';
import { MdLocationOn } from 'react-icons/md';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Button from '../../components/Button';
import Input from '../../components/InputText';
import Header from '../../components/HeaderForm';
import { Container } from './styles';
import history from '../../services/history';
import api from '../../services/api';
import { mascaraCep, mascaraCelular } from '../../utils/masks';

export default function Address({ location }) {
  const [idFichaAnalise] = useState(location.state.idFichaAnalise);
  const [idSituacao] = useState(location.state.idSituacao);

  const [outroEndereco, setOutroEndereco] = useState({});

  const cepOnKeyUp = useCallback((event) => {
    mascaraCep(event);
  }, []);

  const celularOnKeyUp = useCallback((event) => {
    mascaraCelular(event);
  }, []);

  const schema = Yup.object().shape({
    cep: Yup.string()
      .min(3, 'Digite um CEP válido')
      .required('Campo obrigatório'),
    cidade: Yup.string()
      .min(3, 'Digite uma cidade válida')
      .required('Campo obrigatório'),
    complemento: Yup.string(),
    endereco: Yup.string()
      .min(5, 'Mínimo de 5 caracteres')
      .required('Campo obrigatório'),
    bairro: Yup.string()
      .min(5, 'Mínimo de 5 caracteres')
      .required('Campo obrigatório'),
    numero: Yup.string().required('Campo obrigatório'),
    uf: Yup.string()
      .min(2, 'Digite uma UF válida')
      .required('Campo obrigatório'),
    telefone: Yup.string(),
    celular: Yup.string().required('Campo obrigatório'),
  });

  useEffect(() => {
    async function loadEnderecos() {
     
        const { data } = await api.get(`fichaanalisesocial/${idFichaAnalise}`);

        setOutroEndereco({
          cep: data.fas_alu_cep_outro,
          cidade: data.fas_alu_cidade_outro,
          complemento: data.fas_alu_complemento_outro,
          endereco: data.fas_alu_endereco_outro,
          bairro: data.fas_alu_bairro_outro,
          numero: data.fas_alu_numero_outro,
          uf: data.fas_alu_uf_outro,
          telefone: data.fas_alu_telefone_outro,
          celular: data.fas_alu_celular_outro,
        });
           
    }
    loadEnderecos();
  }, [idFichaAnalise]);

  async function handlePersonalData(data) {
    try {
      await api.put(`fichaanalisesocial/${idFichaAnalise}`, {
        fas_alu_cep_outro: data.cep,
        fas_alu_cidade_outro: data.cidade,
        fas_alu_complemento_outro: data.complemento,
        fas_alu_endereco_outro: data.endereco,
        fas_alu_bairro_outro: data.bairro,
        fas_alu_numero_outro: data.numero,
        fas_alu_uf_outro: data.uf,
        fas_alu_telefone_outro: data.telefone,
        fas_alu_celular_outro: data.celular,
      });

      toast.success('Dados atualizados com sucesso!');
      history.goBack();
    } catch (error) {
      toast.error(`Erro ao atualizar os dados! `, error.response.data.message);
    }
  }

  return (
    <Container>
      <Header title="Outros endereços" icon={MdLocationOn} />
      <span>* Preencha os campos abaixo caso tenha algum outro endereço</span>
      <Form
        schema={schema}
        initialData={outroEndereco}
        onSubmit={handlePersonalData}
      >
        <h2> Endereço</h2>

        <h4>Cep:</h4>
        <Input type="text" name="cep" autoComplete="off" onKeyUp={cepOnKeyUp} />
        <h4>Cidade:</h4>
        <Input type="text" name="cidade" autoComplete="off" />
        <h4>Logradouro / Rua:</h4>
        <Input type="text" name="endereco" autoComplete="off" />
        <h4>Bairro:</h4>
        <Input type="text" name="bairro" autoComplete="off" />
        <h4>Número residencial:</h4>
        <Input type="number" name="numero" autoComplete="off" min="0" />
        <h4>Complemento:</h4>
        <Input type="text" name="complemento" autoComplete="off" />
        <h4>UF:</h4>
        <Input type="text" name="uf" autoComplete="off" maxLength={2} />

        <h2>Contato</h2>

        <h4>Telefone:</h4>
        <Input
          type="text"
          name="telefone"
          autoComplete="off"
          onKeyUp={celularOnKeyUp}
        />
        <h4>Celular:</h4>
        <Input
          type="text"
          name="celular"
          autoComplete="off"
          onKeyUp={celularOnKeyUp}
        />
        {idSituacao <= 2 && <Button type="submit">Continuar</Button>}
      </Form>
    </Container>
  );
}
Address.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
