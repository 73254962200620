import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  background: #121921;
  width: 80%;
  margin: 15px auto;
  border-radius: 8px;
  color: #fff;

  h4 {
    margin: 10px 0;
  }

  @media (max-width: 800px) {
    width: 95%;
  }

  form {
    button {
      width: 180px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-bottom: 2px solid #fff8;

  h2 {
    font-weight: lighter;
    padding: 20px 5px;
  }

  button {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    padding: 10px;

    color: #999;
    background: none;

    svg {
      margin-right: 5px;
    }
  }
  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin-top: 15px;

    button {
      position: relative;
      padding: 0;

      margin: 10px 0;
    }

    h2 {
      margin: 0;
      padding: 5px;
    }
  }
`;
