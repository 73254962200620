import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  background: #121921;
  width: 80%;
  margin: 15px auto;
  border-radius: 8px;
  color: #fff;

  @media (max-width: 800px) {
    width: 95%;
  }

  form {
    margin: 20px;

    span {
      color: #f64c75;
      font-size: 12px;
      margin: 0px 0px 10px;
    }
  }

  textarea {
    width: 100%;
    height: 150px;
    padding: 20px;
    resize: vertical;
  }

  h3 {
    margin: 5px 0px;
  }

  a {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    padding: 10px;

    svg {
      margin-right: 5px;
    }
  }
`;
