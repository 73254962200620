/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Container, Card, Icon } from './styles';

export default function ModalExcel({ onClose, open, urlExcel }) {
  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    open && (
      <Container>
        <Card>
          <h3>Click abaixo para fazer download 🚀</h3>

          <a href={urlExcel} download>
            Excel Download
          </a>

          <Icon onClick={closeModal} />
        </Card>
      </Container>
    )
  );
}

ModalExcel.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,

  urlExcel: PropTypes.string,
};
ModalExcel.defaultProps = {
  onClose: true,
  open: true,
  urlExcel: null,
};
