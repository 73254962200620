import React, { useState } from 'react';
import { Form, Input, Check } from '@rocketseat/unform';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { Container, Header, Item, Columns2, Label } from './styles';

const schema = Yup.object().shape({
  cur_descricao: Yup.string().required('A Descrição é obrigatório!'),
  cur_ativo: Yup.bool().required('Ativo é obrigatório!'),
});

export default function EditCurso({ location }) {
  const [editCurso] = useState(location.state.curso);

  async function handleEdit(curso) {
    try {
      await api.put(`/curso/${editCurso.id}`, curso);

      toast.success('Curso editado com sucesso!');

      history.push('/admin/cursos');
    } catch (error) {
      toast.error(`Erro ao editar Curso, verifique os dados!`);
    }
  }

  return (
    <Container>
      <Header>
        <Link to="/admin/cursos">
          <u>Voltar</u>
        </Link>
        <h1>Edição de Cursos</h1>
      </Header>

      <Form schema={schema} onSubmit={handleEdit} initialData={editCurso}>
        <Columns2>
          <Item>
            <Label>Descrição do Curso</Label>
            <Input
              type="text"
              name="cur_descricao"
              placeholder="Descrição do Curso"
            />
          </Item>

          <Item className="ativo">
            <Label>Ativo</Label>
            <Check type="checkbox" name="cur_ativo" />
          </Item>
        </Columns2>

        <button type="submit">Salvar</button>
      </Form>
    </Container>
  );
}

EditCurso.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
