import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  position: relative;

  background: #121921;
  width: 80%;
  margin: 15px auto;
  border-radius: 8px;
  color: #fff;

  @media (max-width: 700px) {
    width: 95%;
  }

  @media (max-width: 400px) {
    span {
      margin-top: 10px;
    }
  }

  span {
    color: #fff8;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 10px;

    span {
      font-size: 15px;
      font-weight: bold;
    }
  }

  h2 {
    font-weight: lighter;
    padding: 20px;
  }
`;

export const Value = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  padding: 20px;
  border: 1px solid #fff8;
  border-radius: 4px;

  label {
    margin-bottom: 3px;
  }

  span {
    color: #fff8 !important;
    text-align: center;
    margin-bottom: 15px;
  }
`;

export const Content = styled.div`
  border: 1px solid #fff8;
`;
