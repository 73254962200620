import React, { useState } from 'react';
import { Form, Input, Check } from '@rocketseat/unform';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { Container, Header, Item, Columns3, Label } from './styles';

const schema = Yup.object().shape({
  des_descricao: Yup.string().required('O Nome é obrigatório!'),
  des_ativo: Yup.string().required('Ativo é obrigatório!'),
  des_ind_dd: Yup.string().required('Ativo é obrigatório!'),
});

export default function EditDespesa({ location }) {
  const [editDespesa] = useState(location.state.despesa);

  async function handleEdit(despesa) {
    try {
      await api.put(`/despesa/${editDespesa.id}`, despesa);

      toast.success('Despesa editada com sucesso!');

      history.push('/admin/despesas');
    } catch (error) {
      toast.error(`Erro ao editar Despesa, verifique os dados!`);
    }
  }

  return (
    <Container>
      <Header>
        <Link to="/admin/despesas">
          <u>Voltar</u>
        </Link>
        <h1>Edição de Despesa</h1>
      </Header>

      <Form schema={schema} onSubmit={handleEdit} initialData={editDespesa}>
        <Columns3>
          <Item>
            <Label>Descrição da despesa</Label>
            <Input type="text" name="des_descricao" placeholder="Descrição" />
          </Item>

          <Item className="ativo">
            <Label>Entra no cálculo do Índice DD</Label>
            <Check type="checkbox" name="des_ind_dd" />
          </Item>

          <Item className="ativo">
            <Label>Ativo</Label>
            <Check type="checkbox" name="des_ativo" />
          </Item>
        </Columns3>

        <button type="submit">Salvar</button>
      </Form>
    </Container>
  );
}

EditDespesa.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
