import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Check } from '@rocketseat/unform';
import { MdPeople } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Header from '../../../components/HeaderForm';
import Input from '../../../components/InputText';
import Button from '../../../components/Button';
import { Select, InputSelect } from '../../../components/InputSelect';
import {
  Container,
  ContainerInfoPessoais,
  Content,
  ContainerRadio,
} from './styles';
import history from '../../../services/history';
import api from '../../../services/api';

export default function FamilyCompositionEdit({ location }) {
  const [idFichaAnalise] = useState(location.state.idFichaAnalise);
  const [idEntidade] = useState(location.state.idEntidade);
  const [idComposicao] = useState(location.state.idComposicao);

  const [composicaoFamiliar, setComposicaoFamiliar] = useState({});
  const [formalInformal, setFormalInformal] = useState('');
  const [nivelEscolar, setNivelEscolar] = useState('');

  const [opcoesFormalInformal] = useState([
    {
      label: 'Formal',
      value: '1',
    },
    {
      label: 'Informal',
      value: '2',
    },
  ]);

  const [possuiProLabore, setPossuiProLabore] = useState(2);
  const [possuiOutrasRendas, setPossuiOutrasRendas] = useState(2);
  const [possuiSeguroDesemprego, setPossuiSeguroDesemprego] = useState(2);
  const [possuiNecessidade, setPossuiNecessidade] = useState(2);
  const [escolaridade, setEscolaridade] = useState(2);

  const [visibleInfosProLabore, setVisibleInfosProLabore] = useState(false);
  const [visibleInfoOutrasRendas, setVisibleInfoOutrasRendas] = useState(false);
  const [visibleInfosNecessidade, setVisibleInfosNecessidade] = useState(false);
  const [visibleInfosEntidade, setVisibleInfosEntidade] = useState(false);
  const [visibleProprioAluno, setVisibleProprioAluno] = useState(false);

  const [initialNivelEnsino, setInitialNivelEnsino] = useState([]);

  const loadNiveisEnsino = useCallback(async () => {
    const response = await api
      .get('/nivelensino', {
        params: {
          entidade_id: idEntidade,
        },
      })
      .then((nivelensino) => {
        const options = nivelensino.data.map((nivel) => ({
          value: nivel.id,
          label: nivel.nie_descricao,
        }));
        return options;
      })
      .catch(() => {
        toast.error('Erro ao buscar niveis de ensino');
      });

    setInitialNivelEnsino(response);
    return response;
  }, [idEntidade]);

  useEffect(() => {
    async function loadComposicaoFamiliar() {
      const { data } = await api.get(`composicaofamiliar/${idComposicao}`);

      const opcaoTipoTrabalho = opcoesFormalInformal.find(
        (opcao) => opcao.value === data.cfm_tipo
      );

      const opcaoNivelEnsino = initialNivelEnsino.find(
        (opcao) => opcao.value === data.nivel_ensino_id
      );
      if (data.cfm_prolabore === 2) setVisibleInfosProLabore(true);

      if (data.cfm_outras_rendas === 2) setVisibleInfoOutrasRendas(true);

      if (data.cfm_necessidade_especial === 2) setVisibleInfosNecessidade(true);

      if (data.cfm_estudante_entidade === 2) setVisibleInfosEntidade(true);

      if (data.cfm_cadastro_aluno) setVisibleProprioAluno(true);

      setComposicaoFamiliar({ ...data, opcaoTipoTrabalho, opcaoNivelEnsino });

      setPossuiProLabore(data.cfm_prolabore);
      setPossuiOutrasRendas(data.cfm_outras_rendas);
      setPossuiSeguroDesemprego(data.cfm_seguro_desemprego);
      setPossuiNecessidade(data.cfm_necessidade_especial);
      setEscolaridade(data.cfm_estudante_entidade);
    }
    loadComposicaoFamiliar();
  }, [idComposicao, opcoesFormalInformal, initialNivelEnsino]);

  const opcoesFormaInformalSelected = useCallback((selectedOption) => {
    setFormalInformal(selectedOption);
  }, []);

  const selectedProprioAluno = useCallback((selectedOption) => {
    const { checked } = selectedOption.target;

    if (checked === true) setVisibleProprioAluno(true);
    else setVisibleProprioAluno(false);
  }, []);

  const nivelEnsinoSelected = useCallback((selectedOption) => {
    setNivelEscolar(selectedOption);
  }, []);

  const selectedProLabore = useCallback((selectedOption) => {
    const { value } = selectedOption.target;
    if (value === '1') setVisibleInfosProLabore(false);
    else setVisibleInfosProLabore(true);

    setPossuiProLabore(value);
  }, []);

  const selectedOutrasRendas = useCallback((selectedOption) => {
    const { value } = selectedOption.target;
    if (value === '1') setVisibleInfoOutrasRendas(false);
    else setVisibleInfoOutrasRendas(true);

    setPossuiOutrasRendas(value);
  }, []);

  const selectedSeguroDesemprego = useCallback((selectedOption) => {
    const { value } = selectedOption.target;

    setPossuiSeguroDesemprego(value);
  }, []);

  const selectedNecessidadeEspecial = useCallback((selectedOption) => {
    const { value } = selectedOption.target;
    if (value === '1') setVisibleInfosNecessidade(false);
    else setVisibleInfosNecessidade(true);

    setPossuiNecessidade(value);
  }, []);

  const selectedEntidade = useCallback((selectedOption) => {
    const { value } = selectedOption.target;
    if (value === '1') setVisibleInfosEntidade(false);
    else setVisibleInfosEntidade(true);

    setEscolaridade(value);
  }, []);

  const schema = Yup.object().shape({
    nome: Yup.string().required('Nome obrigatório!'),
    aluno: Yup.string(),
    parentesco: Yup.string().when(['aluno'], {
      is: false,
      then: Yup.string().required().min(3, 'Campo obrigatório'),
    }),
    datanascimento: Yup.string().required('Campo obrigatório!'),
    estadocivil: Yup.string().min(3, 'Mínimo 3 digitos!'),
    profissao: Yup.string(),
    salario: Yup.string().min(
      1,
      'Este campo não pode ser vazio, digite um valor mínimo'
    ),
    nomeProlabore: Yup.string(),
    cnpjProlabore: Yup.string(),
    ramoProlabore: Yup.string(),
    descricaoRenda: Yup.string(),
    valorRenda: Yup.string().required('Digite um valor mínimo ou 0'),
    necessidadeEspecial: Yup.string(),
    curso: Yup.string(),
    periodo: Yup.string(),
    gastos: Yup.string().required('Digite um valor mínimo ou 0'),
  });

  async function handlePersonalData(data) {
    try {
      await api.put(`composicaofamiliar/${idComposicao}`, {
        entidade_id: idEntidade,
        ficha_analise_id: idFichaAnalise,
        cfm_nome: data.nome,
        cfm_parentesco: data.parentesco,
        cfm_nascimento: data.datanascimento,
        cfm_estado_civil: data.estadocivil,
        cfm_profissao: data.profissao,
        cfm_salario: data.salario,
        cfm_tipo: formalInformal.value || '',
        cfm_prolabore: possuiProLabore,
        cfm_prolabore_empresa: data.nomeProlabore,
        cfm_prolabore_cnpj: data.cnpjProlabore,
        cfm_prolabore_ramo: data.ramoProlabore,
        cfm_seguro_desemprego: possuiSeguroDesemprego,
        cfm_outras_rendas: possuiOutrasRendas,
        cfm_outras_rendas_descricao: data.descricaoRenda,
        cfm_outras_rendas_valor: data.valorRenda,
        cfm_necessidade_especial: possuiNecessidade,
        cfm_necessidade_qual: data.necessidadeEspecial,
        cfm_estudante_entidade: escolaridade,
        cfm_curso: data.curso,
        cfm_periodo: data.periodo,
        nivel_ensino_id: nivelEscolar.value,
        cfm_cadastro_aluno: data.aluno,
        cfm_gasto_ensino: data.gastos,
      });

      await api.put(`checkficha/${idFichaAnalise}`, {
        composicao_familiar: true,
      });
      toast.success('Membro atualizado com sucesso');
      history.goBack();
    } catch (error) {
      toast.error(
        `Erro ao atualizar membro familiar! ${error.response.data.message}`
      );
    }
  }

  return (
    <Container>
      <Header title="Composição familiar" icon={MdPeople} />

      <Form
        schema={schema}
        onSubmit={handlePersonalData}
        initialData={{
          nome: composicaoFamiliar.cfm_nome,
          aluno: composicaoFamiliar.cfm_cadastro_aluno,
          parentesco: composicaoFamiliar.cfm_parentesco,
          datanascimento: composicaoFamiliar.cfm_nascimento,
          estadocivil: composicaoFamiliar.cfm_estado_civil,
          profissao: composicaoFamiliar.cfm_profissao,
          salario: composicaoFamiliar.cfm_salario,
          nomeProlabore: composicaoFamiliar.cfm_prolabore_empresa,
          cnpjProlabore: composicaoFamiliar.cfm_prolabore_cnpj,
          ramoProlabore: composicaoFamiliar.cfm_prolabore_ramo,
          descricaoRenda: composicaoFamiliar.cfm_outras_rendas_descricao,
          valorRenda: composicaoFamiliar.cfm_outras_rendas_valor,
          necessidadeEspecial: composicaoFamiliar.cfm_necessidade_qual,
          curso: composicaoFamiliar.cfm_curso,
          periodo: composicaoFamiliar.cfm_periodo,
          gastos: composicaoFamiliar.cfm_gasto_ensino,
        }}
      >
        <h2>* Informações pessoais</h2>
        <h4>Nome:</h4>
        <ContainerInfoPessoais>
          <Input type="text" name="nome" />

          <Check
            name="aluno"
            onChange={selectedProprioAluno}
            label="Estas informações são do próprio aluno"
          />
        </ContainerInfoPessoais>

        <Content>
          <h4>Parentesco:</h4>
          <Input
            type="text"
            name="parentesco"
            placeholder="PAI / MÃE / IRMÃO "
            disabled={visibleProprioAluno}
          />
          <h4>Data de nascimento:</h4>
          <Input type="date" name="datanascimento" />
          <h4>Estado civil:</h4>
          <Input type="text" name="estadocivil" />

          <h2>* Trabalho</h2>

          <h4>Profissão:</h4>
          <Input type="text" name="profissao" />
          <h4>Salário: (R$)</h4>
          <Input
            type="number"
            step="0.01"
            min="0"
            minLength="1"
            name="salario"
          />
          <h4>Tipo de trabalho:</h4>
          <Select
            options={opcoesFormalInformal}
            onChange={opcoesFormaInformalSelected}
            name="formalInformal"
            value={formalInformal || composicaoFamiliar.opcaoTipoTrabalho}
            className="inputSelect"
          />

          <h2>* Pró-Labore</h2>

          <h4>Possui pró-labore?</h4>

          <ContainerRadio>
            <div>
              <input
                type="radio"
                name="prolabore"
                onChange={selectedProLabore}
                defaultChecked={
                  composicaoFamiliar.cfm_prolabore &&
                  composicaoFamiliar.cfm_prolabore
                }
                value="1"
              />
              <label>Sim</label>
            </div>

            <div>
              <input
                type="radio"
                name="prolabore"
                onChange={selectedProLabore}
                defaultChecked={
                  composicaoFamiliar.cfm_prolabore &&
                  composicaoFamiliar.cfm_prolabore === 2
                }
                value="2"
              />
              <label>Não</label>
            </div>
          </ContainerRadio>

          <h4>Nome da empresa:</h4>
          <Input
            type="text"
            name="nomeProlabore"
            disabled={visibleInfosProLabore}
          />
          <h4>CNPJ da empresa:</h4>
          <Input
            type="text"
            name="cnpjProlabore"
            disabled={visibleInfosProLabore}
          />
          <h4>Ramo da empresa:</h4>
          <Input
            type="text"
            name="ramoProlabore"
            disabled={visibleInfosProLabore}
          />
          <h2>* Rendas:</h2>

          <h4>Possui outras rendas?</h4>
          <ContainerRadio>
            <div>
              <input
                type="radio"
                name="outrasrendas"
                onChange={selectedOutrasRendas}
                defaultChecked={
                  composicaoFamiliar.cfm_outras_rendas &&
                  composicaoFamiliar.cfm_outras_rendas
                }
                value="1"
              />
              <label>Sim</label>
            </div>

            <div>
              <input
                type="radio"
                name="outrasrendas"
                onChange={selectedOutrasRendas}
                defaultChecked={
                  composicaoFamiliar.cfm_outras_rendas &&
                  composicaoFamiliar.cfm_outras_rendas === 2
                }
                value="2"
              />
              <label>Não</label>
            </div>
          </ContainerRadio>

          <h4>Descrição da renda:</h4>
          <Input
            type="text"
            name="descricaoRenda"
            disabled={visibleInfoOutrasRendas}
          />
          <h4>Valor da renda:</h4>
          <Input
            type="number"
            step="0.01"
            min="0"
            name="valorRenda"
            disabled={visibleInfoOutrasRendas}
          />

          <h2>* Outras informações:</h2>

          <h4>Está recebendo seguro desemprego?</h4>

          <ContainerRadio>
            <div>
              <input
                type="radio"
                name="segurodesemprego"
                onChange={selectedSeguroDesemprego}
                defaultChecked={
                  composicaoFamiliar.cfm_seguro_desemprego &&
                  composicaoFamiliar.cfm_seguro_desemprego
                }
                value="1"
              />
              <label>Sim</label>
            </div>

            <div>
              <input
                type="radio"
                name="segurodesemprego"
                onChange={selectedSeguroDesemprego}
                defaultChecked={
                  composicaoFamiliar.cfm_seguro_desemprego &&
                  composicaoFamiliar.cfm_seguro_desemprego === 2
                }
                value="2"
              />
              <label>Não</label>
            </div>
          </ContainerRadio>

          <h4>Possui necessidade especial?</h4>
          <ContainerRadio>
            <div>
              <input
                type="radio"
                name="necessidadeespecial"
                onChange={selectedNecessidadeEspecial}
                defaultChecked={
                  composicaoFamiliar.cfm_necessidade_especial &&
                  composicaoFamiliar.cfm_necessidade_especial
                }
                value="1"
              />
              <label>Sim</label>
            </div>

            <div>
              <input
                type="radio"
                name="necessidadeespecial"
                onChange={selectedNecessidadeEspecial}
                defaultChecked={
                  composicaoFamiliar.cfm_necessidade_especial &&
                  composicaoFamiliar.cfm_necessidade_especial === 2
                }
                value="2"
              />
              <label>Não</label>
            </div>
          </ContainerRadio>

          <h4>Informe a necessidade especial:</h4>
          <Input
            type="text"
            name="necessidadeEspecial"
            disabled={visibleInfosNecessidade}
          />

          <h2>* Escolaridade</h2>
          <h4>Estuda nessa entidade?</h4>

          <ContainerRadio>
            <div>
              <input
                type="radio"
                name="entidade"
                onChange={selectedEntidade}
                defaultChecked={
                  composicaoFamiliar.cfm_estudante_entidade &&
                  composicaoFamiliar.cfm_estudante_entidade
                }
                value="1"
              />
              <label>Sim</label>
            </div>

            <div>
              <input
                type="radio"
                name="entidade"
                onChange={selectedEntidade}
                defaultChecked={
                  composicaoFamiliar.cfm_estudante_entidade &&
                  composicaoFamiliar.cfm_estudante_entidade === 2
                }
                value="2"
              />
              <label>Não</label>
            </div>
          </ContainerRadio>

          <h4>Nome do curso:</h4>
          <Input type="text" name="curso" disabled={visibleInfosEntidade} />
          <h4>Em qual período:</h4>
          <Input type="text" name="periodo" disabled={visibleInfosEntidade} />

          <h4>Nível escolar:</h4>

          <InputSelect
            loadOptions={loadNiveisEnsino}
            onChange={nivelEnsinoSelected}
            name="nivelensino"
            value={nivelEscolar || composicaoFamiliar.opcaoNivelEnsino}
          />

          <h4>Gastos com ensino particular:</h4>
          <Input type="number" step="0.01" min="0" name="gastos" />
        </Content>

        <Button type="submit">Atualizar membro</Button>
      </Form>
    </Container>
  );
}
FamilyCompositionEdit.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
