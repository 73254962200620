import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  width: 100%;
  background: #121921;
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
  color: #fff;
`;

export const Header = styled.div`
  h1 {
    text-align: center;
    padding-bottom: 20px;
  }
`;

export const Search = styled.div`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid #fff2;
  padding: 20px 0;
`;

export const InputFilter = styled.div`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  input {
    height: 38px;
    padding: 0px 38px;
    border: 0;
    background: #222830;
    border-radius: 19px;
    color: #fff;

    &::placeholder {
      color: #fff8;
    }
  }

  .icone {
    position: absolute;
    z-index: 1;
    left: 8px;
    color: #fff;

    border-right: 1px solid #fff2;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      background: transparent;

      &:hover {
        background: #89c54b;
        border-radius: 2px;
        color: #333;
        transition: 0.2s ease;
      }
      color: #fff;
      margin-right: 4px;
    }
  }
`;

export const Button = styled.button`
  height: 38px;
  background: #89c54b;
  color: #fff;
  border: 0;
  outline: 0;

  padding: 0 24px;
  border-radius: 19px;

  &:hover {
    background: ${darken(0.1, '#89c54b')};
  }
`;

export const Main = styled.div`
  padding-bottom: 20px;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  color: #fff8;

  th,
  td {
    border-spacing: auto;
    padding: 16px;
    text-align: left;
    border-bottom: 1px solid #fff2;
  }

  tbody {
    tr {
      &:hover {
        background: #222830;
      }

      svg {
        color: #fff;
      }
    }

    td {
      > button {
        background: transparent;
        border: 0;

        svg {
          &:hover {
            color: #89c54b;
          }
        }
      }
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100px;

    border-radius: 4px;
    background: #222830;
    padding: 4px;
  }

  svg {
    background: #121921;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background: #89c54b;
    }
  }

  span {
    padding: 4px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;

  span {
    width: 100%;
    float: left;
  }
`;

export const Filter = styled.div`
  position: absolute;
  background: #121921;
  border-radius: 4px;
  border: 2px solid #222830;

  display: ${(props) => (props.visible ? 'block' : 'none')};
  top: 60px;
  left: 0px;

  > div {
    padding: 5px 10px;
  }

  button {
    height: 38px;
    background: #89c54b;
    color: #fff;
    border: 0;
    outline: 0;

    margin: 10px;

    padding: 0 24px;
    border-radius: 19px;

    &:hover {
      background: ${darken(0.1, '#89c54b')};
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: 0px;
    top: -33px;
    width: 0;
    height: 0;
    border-left: 16px solid transparent;
    border-bottom: 16px solid #222830;
    border-right: 16px solid transparent;
    border-top: 16px solid transparent;
    z-index: 2;
  }
`;
