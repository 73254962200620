import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GoGraph } from 'react-icons/go';
import { MdKeyboardBackspace } from 'react-icons/md';
import { Form } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import Button from '../../../components/Button';
import Input from '../../../components/InputText';
import history from '../../../services/history';
import formatPrice from '../../../utils/formatPrice';
import { Container, Header, Content, ContainerInfo } from './styles';

export default function Analise({ location }) {
  const [idFichaAnalise] = useState(location.state.idFichaAnalise);
  const [idSituacao] = useState(location.state.idSituacao);
  const [indices, setIndices] = useState({});

  useEffect(() => {
    async function loadIndices() {
      const { data } = await api.get(`fichaanaliseadmin/${idFichaAnalise}`);

      setIndices({
        dm_c: data.fichaAnalise.fas_ind_dmc,
        pne_c: data.fichaAnalise.fas_ind_pnec,
        mgfip_c: data.fichaAnalise.fas_ind_mgfipc,
        dt_c: data.fichaAnalise.fas_ind_dtc,
        dd_c: data.fichaAnalise.fas_ind_ddc,
        rural_c: data.fichaAnalise.fas_ind_bgf_ruralc,
        urbano_c: data.fichaAnalise.fas_ind_bgf_urbanoc,
        auto_c: data.fichaAnalise.fas_ind_bgf_autoc,
        moto_c: data.fichaAnalise.fas_ind_bgf_motoc,
        caminhao_c: data.fichaAnalise.fas_ind_bgf_caminhaoc,
        dm_m: data.fichaAnalise.fas_ind_dmm,
        pne_m: data.fichaAnalise.fas_ind_pnem,
        mgfip_m: data.fichaAnalise.fas_ind_mgfipm,
        dt_m: data.fichaAnalise.fas_ind_dtm,
        dd_m: data.fichaAnalise.fas_ind_ddm,
        rural_m: data.fichaAnalise.fas_ind_bgf_ruralm,
        urbano_m: data.fichaAnalise.fas_ind_bgf_urbanom,
        auto_m: data.fichaAnalise.fas_ind_bgf_autom,
        moto_m: data.fichaAnalise.fas_ind_bgf_motom,
        caminhao_m: data.fichaAnalise.fas_ind_bgf_caminhaom,
        grupo_familiar: data.fichaAnalise.fas_ind_gf,
        renda_familiar: formatPrice(data.fichaAnalise.fas_renda_familiar),
        per_capita: formatPrice(data.fichaAnalise.fas_renda_percapita),
        carencia_c: formatPrice(data.fichaAnalise.fas_ind_carenciac),
        carencia_m: formatPrice(data.fichaAnalise.fas_ind_carenciam),
        comprometimento: data.fichaAnalise.fas_comprometimento_renda?.toFixed(
          2
        ),
        bolsa_c: `${data.fichaAnalise.fas_bolsac || 0}%`,
        bolsa_m: data.fichaAnalise.fas_bolsam || 0,
        valor_curso: formatPrice(data.cursoValores.cuv_valor),
        valor_desconto: formatPrice(data.fichaAnalise.fas_valor_desconto),
        valor_mensalidade: formatPrice(data.fichaAnalise.fas_valor_mensalidade),
      });
    }
    loadIndices();
  }, [idFichaAnalise]);

  async function handleAtualizarIndices(data) {
    try {
      await api.put(`calculoindices/${idFichaAnalise}`, {
        fas_ind_dmm: data.dm_m,
        fas_ind_pnem: data.pne_m,
        fas_ind_mgfipm: data.mgfip_m,
        fas_ind_dtm: data.dt_m,
        fas_ind_ddm: data.dd_m,
        fas_ind_bgf_ruralm: data.rural_m,
        fas_ind_bgf_urbanom: data.urbano_m,
        fas_ind_bgf_autom: data.auto_m,
        fas_ind_bgf_motom: data.moto_m,
        fas_ind_bgf_caminhaom: data.caminhao_m,
        fas_bolsam: data.bolsa_m,
      });

      toast.success('Indices atualizados com sucesso!');
      history.goBack();
    } catch (error) {
      toast.error('Erro ao atualizar índices!');
    }
  }

  return (
    <Container>
      <Header>
        <GoGraph size={20} />
        <h2>Análise</h2>
        <button type="button" onClick={() => history.goBack()}>
          <MdKeyboardBackspace size={20} />
          <u>Voltar</u>
        </button>
      </Header>
      <Form initialData={indices} onSubmit={handleAtualizarIndices}>
        <Content>
          <ContainerInfo>
            <h4>Índice DM (Calculado):</h4>
            <Input type="text" name="dm_c" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice PNE (Calculado):</h4>
            <Input type="email" name="pne_c" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice MGFIP (Calculado):</h4>
            <Input type="text" name="mgfip_c" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice DT (Calculado):</h4>
            <Input type="text" name="dt_c" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice DD (Calculado):</h4>
            <Input type="text" name="dd_c" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice BGF Rural (Calculado):</h4>
            <Input type="text" name="rural_c" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice BGF Urbano (Calculado):</h4>
            <Input type="text" name="urbano_c" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice BGF Auto (Calculado):</h4>
            <Input type="text" name="auto_c" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice BGF Moto (Calculado):</h4>
            <Input type="text" name="moto_c" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice BGF Caminhão (Calculado):</h4>
            <Input type="text" name="caminhao_c" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice DM (Modificado):</h4>
            <Input type="text" name="dm_m" />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice PNE (Modificado):</h4>
            <Input type="text" name="pne_m" />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice MGFIP (Modificado):</h4>
            <Input type="text" name="mgfip_m" />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice DT (Modificado):</h4>
            <Input type="text" name="dt_m" />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice DD (Modificado):</h4>
            <Input type="text" name="dd_m" />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice BGF Rural (Modificado):</h4>
            <Input type="text" name="rural_m" />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice BGF Urbano (Modificado):</h4>
            <Input type="text" name="urbano_m" />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice BGF Auto (Modificado):</h4>
            <Input type="text" name="auto_m" />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice BGF Moto (Modificado):</h4>
            <Input type="text" name="moto_m" />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice BGF Caminhão (Modificado):</h4>
            <Input type="text" name="caminhao_m" />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Grupo Familiar:</h4>
            <Input type="text" name="grupo_familiar" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Renda Familiar:</h4>
            <Input type="text" name="renda_familiar" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Renda Per Capita:</h4>
            <Input type="text" name="per_capita" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice de Carência (Calculado): </h4>
            <Input type="text" name="carencia_c" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Índice de Carência (Modificado): </h4>
            <Input type="text" name="carencia_m" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Comprometimento Renda: </h4>
            <Input type="text" name="comprometimento" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>% Bolsa (Calculada): </h4>
            <Input type="text" name="bolsa_c" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Valor do Curso: </h4>
            <Input type="text" name="valor_curso" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>% Bolsa (Concedida): </h4>
            <Input type="text" name="bolsa_m" />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Valor do Desconto: </h4>
            <Input type="text" name="valor_desconto" disabled />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Valor final da Mensalidade: </h4>
            <Input type="text" name="valor_mensalidade" disabled />
          </ContainerInfo>
        </Content>

        {idSituacao === 3 && <Button type="submit">Concluir análise</Button>}
      </Form>
    </Container>
  );
}
Analise.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
