import React, { useState, useEffect } from 'react';
import {
  MdSearch,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdBorderColor,
} from 'react-icons/md';

import api from '../../../services/api';
import history from '../../../services/history';

import { store } from '../../../store';

import {
  Container,
  Header,
  Search,
  Input,
  Button,
  Main,
  Table,
  Footer,
} from './styles';

export default function Periodos() {
  const { entity } = store.getState().entity;

  const [periodos, setPeriodos] = useState();
  const [filter, setFilter] = useState();
  const [periodosTotal, setPeriodosTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limitFromPage] = useState(10);
  const [initialNumberFromPage, setInitialNumberFromPage] = useState();
  const [numberFromPage, setNumberFromPage] = useState();

  useEffect(() => {
    async function loadPeriodos() {
      const response = await api.get('periodo', {
        params: { nomePeriodo: filter, page, entidade_id: entity },
      });

      setPeriodos(response.data);
      setPeriodosTotal(response.headers.x_total_count);
    }

    loadPeriodos();
  }, [filter, page, entity]);

  useEffect(() => {
    function handleLimits() {
      if (page >= 1) {
        if (page * limitFromPage > periodosTotal) {
          setNumberFromPage(periodosTotal);
        } else {
          setNumberFromPage(page * limitFromPage);
        }
      } else {
        setNumberFromPage(1);
      }

      if (page >= 1) {
        if (periodosTotal <= 0) {
          setInitialNumberFromPage(0);
        } else {
          setInitialNumberFromPage(page * limitFromPage + 1 - limitFromPage);
        }
      }
    }

    handleLimits();
  }, [page, periodosTotal, numberFromPage, limitFromPage]);

  function handlePageSum() {
    const numberpages = Math.ceil(periodosTotal / limitFromPage);

    if (page < numberpages) {
      setPage(page + 1);
    }
  }

  function handlePageSubtract() {
    if (!(page <= 1)) {
      setPage(page - 1);
    }
  }

  function handleNewPeriodo() {
    history.push('/admin/newperiodo');
  }

  function handleEditPeriodo(periodo) {
    history.push({
      pathname: '/admin/editperiodo',
      state: { periodo },
    });
  }

  return (
    <Container>
      <Header>
        <h1>Gerenciamento de Periodos</h1>

        <Search>
          <Input>
            <MdSearch size={20} />
            <input
              placeholder="Buscar periodo"
              onChange={(event) => setFilter(event.target.value)}
              type="text"
            />
          </Input>
          <Button type="button" onClick={handleNewPeriodo}>
            Novo
          </Button>
        </Search>
      </Header>
      <Main>
        <Table>
          <thead>
            <tr>
              <th>Ações</th>
              <th>ID</th>
              <th>Descrição</th>
            </tr>
          </thead>
          <tbody>
            {periodos &&
              periodos.map((periodo) => (
                <tr key={periodo.id}>
                  <td>
                    <button
                      type="button"
                      onClick={() => handleEditPeriodo(periodo)}
                    >
                      <MdBorderColor size={20} />
                    </button>
                  </td>
                  <td>{periodo.id}</td>
                  <td>{periodo.per_descricao}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Main>

      <Footer>
        <div>
          <MdKeyboardArrowLeft size={24} onClick={handlePageSubtract} />
          {page}
          <MdKeyboardArrowRight size={24} onClick={handlePageSum} />
        </div>

        <div>
          <span>{initialNumberFromPage}</span>
          <span>a</span>
          <span>{numberFromPage && numberFromPage}</span>
          <span>de</span>
          <span>{periodosTotal}</span>
        </div>
      </Footer>
    </Container>
  );
}
