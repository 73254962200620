import React, { useEffect, useState } from 'react';
import { MdBorderColor, MdAttachMoney } from 'react-icons/md';
import PropTypes from 'prop-types';
import Header from '../../../components/HeaderForm';
import { Container, Table } from './styles';
import history from '../../../services/history';

import api from '../../../services/api';

export default function ListaBens({ location }) {
  const [idFichaAnalise] = useState(location.state.idFichaAnalise);
  const [idEntidade] = useState(location.state.idEntidade);
  const [idSituacao] = useState(location.state.idSituacao);
  const [isAdmin] = useState(location.state.usuarioAdmin);

  const [bens, setBens] = useState([]);

  useEffect(() => {
    async function loadDespesasMensais() {
      const response = await api.get(`bensanalises/${idFichaAnalise}`);

      setBens(response.data);
    }
    loadDespesasMensais();
  }, [idFichaAnalise, idEntidade]);

  return (
    <Container>
      <Header title="Bens" icon={MdAttachMoney} />

      {!!bens.length && (
        <Table idSituacao={idSituacao} isAdmin={isAdmin}>
          <thead>
            <tr>
              <th>Editar</th>
              <th>Bem</th>
              <th>Quantidade</th>
            </tr>
          </thead>
          <tbody>
            {bens.map(({ id, ...bem }) => (
              <tr key={id}>
                <td>
                  <button type="button">
                    <MdBorderColor
                      size={20}
                      color="#fff"
                      onClick={() =>
                        history.push({
                          pathname: '/order/bens/edit',
                          state: {
                            idBensAnalises: id,
                            idBem: bem.bens.id,
                            idEntidade,
                            idFicha: idFichaAnalise,
                            nomeBem: bem.bens.bem_descricao,
                            tipoBem: bem.bens.bem_tipo,
                            quantidadeBem: bem.bem_qtd,
                          },
                        })
                      }
                    />
                  </button>
                </td>
                <td>{bem.bens.bem_descricao}</td>
                <td>{bem.bem_qtd}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
}
ListaBens.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
