export const opcoesDefaultParticipacaoEconomica = [
  {
    label: 'Você não trabalha e seus gastos são custeados',
    value: 1,
  },
  {
    label: 'Você trabalha e é independente financeiramente',
    value: 2,
  },
  {
    label: 'Você trabalha, mas não é independente financeiramente',
    value: 3,
  },
  {
    label: 'Você trabalha e é responsável pelo sustento da sua família',
    value: 4,
  },
];

export const opcoesDefaultSituacaoMoradia = [
  {
    label: 'Própria',
    value: 1,
  },
  {
    label: 'Cedida',
    value: 2,
  },
  {
    label: 'Alugada',
    value: 3,
  },
  {
    label: 'Financiada',
    value: 4,
  },
];

export const opcoesDefaultMeioTransporte = [
  {
    label: 'A pé / Bicicleta.',
    value: 1,
  },
  {
    label: 'Transporte coletivo (Ônibus/Van).',
    value: 2,
  },
  {
    label: 'Transporte escolar fornecido pelo município em que reside',
    value: 3,
  },
  {
    label: 'Transporte próprio (Carro/Moto)',
    value: 4,
  },
];

export const opcoesDefaultEscolaEnsinoMedio = [
  {
    label: 'Somente em escola pública',
    value: 1,
  },
  {
    label: 'Maior parte em escola pública',
    value: 2,
  },
  {
    label: 'Somente em escola particular',
    value: 3,
  },
  {
    label: 'Maior parte em escola particular',
    value: 4,
  },
];
