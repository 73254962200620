import React from 'react';
import { Form, Input } from '@rocketseat/unform';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import history from '../../../../services/history';
import { store } from '../../../../store';

import { Container, Header, Item } from './styles';

const schema = Yup.object().shape({
  doc_descricao: Yup.string().required('A descrição é obrigatório!'),
});

export default function NewDocumento() {
  const { entity } = store.getState().entity;

  async function handleSubmit({ doc_descricao }) {
    try {
      await api.post('documentos', { doc_descricao, entidade_id: entity });

      toast.success('Documeto cadastrado com sucesso');

      history.push('/admin/documentos');
    } catch (error) {
      toast.error('Erro ao cadastrar Documeto, verifique os dados!');
    }
  }

  return (
    <Container>
      <Header>
        <Link to="/admin/documentos">
          <u>Voltar</u>
        </Link>
        <h1>Gerenciamento de Documentos</h1>
      </Header>

      <Form schema={schema} onSubmit={handleSubmit}>
        <Item>
          <Input type="text" name="doc_descricao" placeholder="Descrição" />
        </Item>

        <button type="submit">Cadastrar</button>
      </Form>
    </Container>
  );
}
