import { signOut } from '../store/modules/auth/actions';

export default function InvalidToken(api) {
  api.registerInterceptWithStore = (store) => {
    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (
          error.response.status === 401 &&
          error.response.data.message === 'Token inválido'
        ) {
          store.dispatch(signOut());
        }
        return Promise.reject(error);
      }
    );
  };
}
