import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import ReactSelect from 'react-select';

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: '#222830',
    borderRadius: 19,
    border: 0,
    flexWrap: 'no-wrap',
  }),
  option: (provided) => ({
    ...provided,
    color: '#fff8',
    borderRadius: 8,
    padding: '10px 20px',
    backgroundColor: '#222830',
    '&:hover': {
      backgroundColor: '#121921',
    },
    cursor: 'pointer',
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#fff8',
    padding: '0 20px',
    margin: 0,
    position: 'absolute',
    top: '50%',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#fff',
    padding: '0 20px',
    position: 'absolute',
    top: '40%',
    margin: '4px 0px',
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    alignItems: 'center',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    backgroundColor: '#fff8',
    margin: '8px 0px !important',
    minWidth: 1,
  }),

  dropdownIndicator: (styles) => ({
    ...styles,
    color: '#fff8',
    alignItems: 'center',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: 0,
    marginTop: 'auto',
    height: 38,
  }),
  menu: (styles) => ({
    ...styles,
    color: '#fff8',
    backgroundColor: '#222830',
    borderRadius: 8,
    margin: '5px 0',
  }),
};

export function InputSelect({
  placeholder,
  loadOptions,
  onChange,
  name,
  value,
}) {
  return (
    <AsyncSelect
      noOptionsMessage={() => 'Nenhuma opção encontrada'}
      placeholder={placeholder}
      loadOptions={loadOptions}
      onChange={onChange}
      name={name}
      value={value}
      cacheOptions
      defaultOptions
      isSearchable={false}
      styles={customStyles}
      type="text"
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary50: '#fff8',
          primary: 'none',
        },
      })}
    />
  );
}

export function Select({ options, placeholder, name, onChange, value }) {
  return (
    <ReactSelect
      noOptionsMessage={() => 'Nenhuma opção encontrada'}
      options={options}
      styles={customStyles}
      placeholder={placeholder}
      isSearchable={false}
      onChange={onChange}
      value={value}
      name={name}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary50: '#fff8',
          primary: 'none',
        },
      })}
    />
  );
}

InputSelect.propTypes = {
  placeholder: PropTypes.elementType,
  loadOptions: PropTypes.elementType,
  onChange: PropTypes.elementType,
  name: PropTypes.elementType.isRequired,
  value: PropTypes.oneOfType([PropTypes.any]),
};

InputSelect.defaultProps = {
  placeholder: '',
  loadOptions: '',
  onChange: '',
  value: '',
};

Select.propTypes = {
  options: PropTypes.oneOfType([PropTypes.array]).isRequired,
  name: PropTypes.oneOfType([PropTypes.string]).isRequired,
  placeholder: PropTypes.elementType,
  onChange: PropTypes.elementType,
  value: PropTypes.oneOfType([PropTypes.any]),
};
Select.defaultProps = {
  placeholder: '',
  onChange: '',
  value: '',
};
