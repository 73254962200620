import React, { useState, useEffect } from 'react';
import { MdSearch, MdBorderColor, MdDoneAll, MdClear } from 'react-icons/md';
import api from '../../../services/api';
import history from '../../../services/history';

import {
  Container,
  Header,
  Search,
  Input,
  Button,
  Main,
  Table,
} from './styles';

export default function Entidades() {
  const [entidades, setEntidades] = useState();
  const [filter, setFilter] = useState();

  useEffect(() => {
    async function loadEntidades() {
      const response = await api.get('entidade', {
        params: { nomeFantasia: filter },
      });

      setEntidades(response.data);
    }

    loadEntidades();
  }, [filter]);

  function handleNewEntidade() {
    history.push('/admin/newentidade');
  }

  function handleEditEntidade(entidade) {
    history.push({ pathname: '/admin/editentidade', state: { entidade } });
  }

  return (
    <Container>
      <Header>
        <h1>Gerenciamento de Entidades</h1>

        <Search>
          <Input>
            <MdSearch size={20} />
            <input
              placeholder="Buscar Entidades"
              onChange={(event) => setFilter(event.target.value)}
              type="text"
            />
          </Input>
          <Button type="button" onClick={handleNewEntidade}>
            Nova
          </Button>
        </Search>
      </Header>
      <Main>
        <Table>
          <thead>
            <tr>
              <th>Ações</th>
              <th>ID</th>
              <th>Nome Fantasia</th>
              <th>CNPJ</th>
              <th>Cidade</th>
              <th>UF</th>
              <th>Telefone</th>
              <th>Ativo</th>
            </tr>
          </thead>
          <tbody>
            {entidades &&
              entidades.map((entidade) => (
                <tr key={entidade.id}>
                  <td>
                    <button
                      type="button"
                      onClick={() => handleEditEntidade(entidade)}
                    >
                      <MdBorderColor size={20} />
                    </button>
                  </td>
                  <td>{entidade.id}</td>
                  <td>{entidade.ent_nome_fantasia}</td>
                  <td>{entidade.ent_cnpj}</td>
                  <td>{entidade.ent_cidade}</td>
                  <td>{entidade.ent_uf}</td>
                  <td>{entidade.ent_telefone}</td>
                  <td>
                    {entidade.ent_ativo ? (
                      <MdDoneAll size={20} color="#89c54b" />
                    ) : (
                      <MdClear size={20} color="#f64c75" />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Main>
    </Container>
  );
}
