import React, { useState, useEffect } from 'react';
import {
  MdSearch,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdBorderColor,
  MdDoneAll,
  MdClear,
} from 'react-icons/md';

import api from '../../../services/api';
import history from '../../../services/history';

import { store } from '../../../store';

import {
  Container,
  Header,
  Search,
  Input,
  Button,
  Main,
  Table,
  Footer,
} from './styles';

export default function Cursos() {
  const { entity } = store.getState().entity;

  const [cursos, setCursos] = useState();
  const [filter, setFilter] = useState();
  const [cursosTotal, setCursosTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limitFromPage] = useState(10);
  const [initialNumberFromPage, setInitialNumberFromPage] = useState();
  const [numberFromPage, setNumberFromPage] = useState();

  useEffect(() => {
    async function loadCursos() {
      const response = await api.get('curso', {
        params: { nomeCurso: filter, page, entidade_id: entity },
      });

      setCursos(response.data);
      setCursosTotal(response.headers.x_total_count);
    }

    loadCursos();
  }, [filter, page, entity]);

  useEffect(() => {
    function handleLimits() {
      if (page >= 1) {
        if (page * limitFromPage > cursosTotal) {
          setNumberFromPage(cursosTotal);
        } else {
          setNumberFromPage(page * limitFromPage);
        }
      } else {
        setNumberFromPage(1);
      }

      if (page >= 1) {
        if (cursosTotal <= 0) {
          setInitialNumberFromPage(0);
        } else {
          setInitialNumberFromPage(page * limitFromPage + 1 - limitFromPage);
        }
      }
    }

    handleLimits();
  }, [page, cursosTotal, numberFromPage, limitFromPage]);

  function handlePageSum() {
    const numberpages = Math.ceil(cursosTotal / limitFromPage);

    if (page < numberpages) {
      setPage(page + 1);
    }
  }

  function handlePageSubtract() {
    if (!(page <= 1)) {
      setPage(page - 1);
    }
  }

  function handleNewCurso() {
    history.push('/admin/newcurso');
  }

  function handleEditCurso(curso) {
    history.push({ pathname: '/admin/editcurso', state: { curso } });
  }

  return (
    <Container>
      <Header>
        <h1>Gerenciamento de Cursos</h1>

        <Search>
          <Input>
            <MdSearch size={20} />
            <input
              placeholder="Buscar Curso"
              onChange={(event) => setFilter(event.target.value)}
              type="text"
            />
          </Input>
          <Button type="button" onClick={handleNewCurso}>
            Novo
          </Button>
        </Search>
      </Header>
      <Main>
        <Table>
          <thead>
            <tr>
              <th>Ações</th>
              <th>ID</th>
              <th>Descrição</th>
              <th>Ativo</th>
            </tr>
          </thead>
          <tbody>
            {cursos &&
              cursos.map((curso) => (
                <tr key={curso.id}>
                  <td>
                    <button
                      type="button"
                      onClick={() => handleEditCurso(curso)}
                    >
                      <MdBorderColor size={20} />
                    </button>
                  </td>
                  <td>{curso.id}</td>
                  <td>{curso.cur_descricao}</td>
                  <td>
                    {curso.cur_ativo ? (
                      <MdDoneAll size={20} color="#89c54b" />
                    ) : (
                      <MdClear size={20} color="#f64c75" />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Main>

      <Footer>
        <div>
          <MdKeyboardArrowLeft size={24} onClick={handlePageSubtract} />
          {page}
          <MdKeyboardArrowRight size={24} onClick={handlePageSum} />
        </div>

        <div>
          <span>{initialNumberFromPage}</span>
          <span>a</span>
          <span>{numberFromPage && numberFromPage}</span>
          <span>de</span>
          <span>{cursosTotal}</span>
        </div>
      </Footer>
    </Container>
  );
}
