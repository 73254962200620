import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import { MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Header from '../../components/HeaderForm';
import { Select } from '../../components/InputSelect';
import Input from '../../components/InputText';
import Button from '../../components/Button';
import { Container } from './styles';
import history from '../../services/history';
import api from '../../services/api';

export default function Justification({ location }) {
  const [idFichaAnalise] = useState(location.state.idFichaAnalise);
  const [idSituacao] = useState(location.state.idSituacao);

  const [opcoesRequerimento] = useState([
    {
      label: 'Solicitação BOLSA DE ESTUDO PARCIAL',
      value: 1,
    },
    {
      label: 'Reavaliação da concessão BOLSA DE ESTUDO PARCIAL',
      value: 2,
    },
    {
      label: 'Solicitação de desconto devido a Convênio com:',
      value: 3,
    },
    {
      label: 'Adesão ao programa UNIFAE Legal',
      value: 4,
    },
    {
      label: 'Outras demandas',
      value: 5,
    },
  ]);

  const [convenio, setConvenio] = useState(true);
  const [requerimento, setRequerimento] = useState('');

  const [dadosFicha, setDadosFicha] = useState({});

  useEffect(() => {
    async function loadDadosFicha() {
      const { data } = await api.get(`fichaanalisesocial/${idFichaAnalise}`);

      const opcaoRequerimento = opcoesRequerimento.find(
        (item) => item.value === data.fas_requerimento
      );
      if (
        data.fas_requerimento === 3 ||
        data.fas_requerimento === 4 ||
        data.fas_requerimento === 5
      ) {
        setConvenio(false);
      }

      setDadosFicha({
        opcaoRequerimento,
        justificativa: data.fas_justificativa,
        descricao_requerimento: data.fas_requerimento_descricao,
      });
    }
    loadDadosFicha();
  }, [idFichaAnalise, opcoesRequerimento]);

  const requerimentoSelected = useCallback((selectedOption) => {
    if (selectedOption.value === 3 || selectedOption.value === 5)
      setConvenio(false);
    else setConvenio(true);

    setRequerimento(selectedOption);
  }, []);

  const schema = Yup.object().shape({
    justificativa: Yup.string()
      .min(10, 'Mínimo de 10 caracteres!')
      .required('Campo obrigatório!'),
    descricao_requerimento: Yup.string(),
  });

  async function handleSubmit({ justificativa, descricao_requerimento }) {
    try {
      await api.put(`fichaanalisesocial/${idFichaAnalise}`, {
        fas_justificativa: justificativa,
        fas_requerimento: requerimento.value,
        fas_requerimento_descricao: descricao_requerimento,
      });

      await api.put(`checkficha/${idFichaAnalise}`, {
        justificativa: true,
      });

      toast.success('Registros atualizados com sucesso!');
      history.goBack();
    } catch (error) {
      toast.error(`Erro ao atualizar os dados!`, error.response.data.message);
    }
  }
  return (
    <Container>
      <Header title="Justificativa" icon={MdEdit} />

      <Form
        onSubmit={handleSubmit}
        schema={schema}
        initialData={{
          justificativa: dadosFicha.justificativa,
          descricao_requerimento: dadosFicha.descricao_requerimento,
        }}
      >
        <h3>
          Justifique abaixo os motivos que o leva solicitar a avaliação
          socioeconômica para obtenção de bolsa de estudos:
        </h3>
        <Input
          multiline
          type="text"
          name="justificativa"
          placeholder="Descrição"
          autoFocus
        />
        <h3>Venho requerer:</h3>
        <Select
          options={opcoesRequerimento}
          onChange={requerimentoSelected}
          name="opcaorequerimento"
          value={requerimento || dadosFicha.opcaoRequerimento}
        />
        <h3>Convênio / Outras Demandas:</h3>
        <Input type="text" name="descricao_requerimento" disabled={convenio} />
        {idSituacao <= 2 && <Button type="submit">Continuar</Button>}
      </Form>
    </Container>
  );
}
Justification.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
