import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { MdSettings, MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';

import api from '../../../services/api';
import history from '../../../services/history';

import { store } from '../../../store';

import { handleEntityRequest } from '../../../store/modules/entity/actions';

import Header from '../../../components/Header';

import {
  Wrapper,
  Menu,
  Title,
  Div,
  Button,
  Main,
  Container,
  ListActions,
  Options,
} from './styles';

export default function AdminLayout({ children }) {
  const dispatch = useDispatch();
  const { entity } = store.getState().entity;

  const [entidades, setEntidades] = useState();
  const [visible, setVisible] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    async function loadEntidade() {
      const response = await api.get('entidade');

      setEntidades(response.data);
    }

    loadEntidade();
  }, [children]);

  function handleToggleVisible(entidade_id) {
    dispatch(handleEntityRequest(entidade_id));

    setVisible(!visible);
    setActive(true);
  }

  function handleCurso() {
    history.push('/admin/cursos');
    setVisible(!visible);
  }

  function handlePeriodosLetivo() {
    history.push('/admin/periodosletivo');
    setVisible(!visible);
  }

  function handleAlunos() {
    history.push('/admin/alunos');
    setVisible(!visible);
  }

  function handleUsuarios() {
    history.push('/admin/usuarios');
    setVisible(!visible);
  }

  function handlePeriodos() {
    history.push('/admin/periodos');
    setVisible(!visible);
  }

  function handleBens() {
    history.push('/admin/bens');
    setVisible(!visible);
  }

  function handleDespesas() {
    history.push('/admin/despesas');
    setVisible(!visible);
  }

  function handleNivelEnsino() {
    history.push('/admin/niveisensino');
    setVisible(!visible);
  }

  function handleDocumentos() {
    history.push('/admin/documentos');
    setVisible(!visible);
  }

  function handleAvisos() {
    history.push('/admin/avisos');
    setVisible(!visible);
  }

  function handleSolicitacoes() {
    history.push('/admin/bolsas');
    setVisible(!visible);
  }

  return (
    <>
      <Header />

      <Wrapper>
        <Menu>
          <Title>
            <Link to="/admin/entidades">
              Entidades
              <MdSettings size={24} />
            </Link>
          </Title>
          {entidades &&
            entidades.map((entidade) => (
              <Container key={entidade.id}>
                {entidade.ent_ativo && (
                  <>
                    <Div
                      active={entity === entidade.id ? active : ''}
                      onClick={() => handleToggleVisible(entidade.id)}
                    >
                      {entidade.ent_nome_fantasia}
                      <MdKeyboardArrowRight size={20} />
                    </Div>

                    <ListActions
                      visible={entity === entidade.id ? visible : ''}
                    >
                      <Options>
                        <Button onClick={handleSolicitacoes}>
                          Solicitações de Bolsa
                        </Button>
                        <Button onClick={handleAlunos}>Alunos</Button>
                        <Button onClick={handleUsuarios}>Usuários</Button>
                        <Button onClick={handleCurso}>Cursos</Button>
                        <Button onClick={handlePeriodosLetivo}>
                          Ano Letivo
                        </Button>
                        <Button onClick={handlePeriodos}>Períodos</Button>
                        <Button onClick={handleBens}>Bens</Button>
                        <Button onClick={handleDespesas}>Despesas</Button>
                        <Button onClick={handleNivelEnsino}>
                          Nível de Ensino
                        </Button>
                        <Button onClick={handleDocumentos}>
                          Documentos Exigidos
                        </Button>
                        <Button onClick={handleAvisos}>Avisos Legais</Button>
                      </Options>
                    </ListActions>
                  </>
                )}
              </Container>
            ))}
        </Menu>
        <Main>{children}</Main>
      </Wrapper>
    </>
  );
}

AdminLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
