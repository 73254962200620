import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #89c54b90;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
  background: #121921;
  border-radius: 4px;

  text-align: center;
  font-size: 16px;
  margin: auto 5px;

  svg {
    color: #89c54b;
    margin: 0 10px;
  }
`;
