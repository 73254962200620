import React, { useState } from 'react';
import { Form, Input } from '@rocketseat/unform';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { Container, Header, Item, Label } from './styles';

const schema = Yup.object().shape({
  per_descricao: Yup.string().required('O Periodo é obrigatório!'),
});

export default function EditePeriodoLetivo({ location }) {
  const [editPerido] = useState(location.state.periodo);

  async function handleEdit(anoLetivo) {
    try {
      await api.put(`/periodo/${editPerido.id}`, anoLetivo);

      toast.success('Periodo editado com sucesso!');

      history.push('/admin/periodos');
    } catch (error) {
      toast.error(`Erro ao editar Periodo, verifique os dados!`);
    }
  }

  return (
    <Container>
      <Header>
        <Link to="/admin/periodos">
          <u>Voltar</u>
        </Link>
        <h1>Edição do Periodo</h1>
      </Header>

      <Form schema={schema} onSubmit={handleEdit} initialData={editPerido}>
        <Item>
          <Label>Periodo</Label>
          <Input type="text" name="per_descricao" placeholder="Periodo" />
        </Item>

        <button type="submit">Salvar</button>
      </Form>
    </Container>
  );
}

EditePeriodoLetivo.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
