import styled from 'styled-components';
import { MdClose } from 'react-icons/md';

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Card = styled.div`
  position: relative;
  background: #121921;
  border-radius: 8px;
  width: 600px;
  transition: 400ms;
  padding: 25px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  a {
    font-size: 16px;
    padding: 20px;
    text-decoration: underline;
  }
`;

export const Icon = styled(MdClose)`
  position: absolute;
  height: 30px;
  width: 30px;
  color: #fff;
  top: 5px;
  right: 5px;
  cursor: pointer;
`;
