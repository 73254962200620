import React, { useRef } from 'react';
import IdleTimer from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { signOut } from '../store/modules/auth/actions';
import { handleEntityOut } from '../store/modules/entity/actions';

import { store } from '../store';

function IdleTimerContainer() {
  const idleTimerRef = useRef(null);
  const dispatch = useDispatch();

  const onIdle = () => {
    const { signed } = store.getState().auth;

    if (signed === true) {
      dispatch(signOut());
      dispatch(handleEntityOut());
      toast.info('Você ficou ocioso, faça login novamente...!');
    }
  };

  return <IdleTimer ref={idleTimerRef} timeout={1200 * 1000} onIdle={onIdle} />;
}

export default IdleTimerContainer;
