import React, { useState, useEffect } from 'react';
import {
  MdSearch,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdBorderColor,
  MdDoneAll,
  MdClear,
} from 'react-icons/md';
import { format } from 'date-fns';

import api from '../../../services/api';
import history from '../../../services/history';

import {
  Container,
  Header,
  Search,
  Input,
  Button,
  Main,
  Table,
  Footer,
} from './styles';

export default function Alunos() {
  const [alunos, setAlunos] = useState();
  const [filter, setFilter] = useState();
  const [alunosTotal, setAlunosTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limitFromPage] = useState(10);
  const [initialNumberFromPage, setInitialNumberFromPage] = useState();
  const [numberFromPage, setNumberFromPage] = useState();

  useEffect(() => {
    async function loadAlunos() {
      const response = await api.get('usuario', {
        params: { alunoNome: filter, page },
      });

      const usuarios = response.data.map((usuario) => ({
        ...usuario,
        data: format(new Date(usuario.created_at), 'dd/MM/yyyy'),
      }));

      setAlunos(usuarios);
      setAlunosTotal(response.headers.x_total_count);
    }

    loadAlunos();
  }, [filter, page]);

  useEffect(() => {
    function handleLimits() {
      if (page >= 1) {
        if (page * limitFromPage > alunosTotal) {
          setNumberFromPage(alunosTotal);
        } else {
          setNumberFromPage(page * limitFromPage);
        }
      } else {
        setNumberFromPage(1);
      }

      if (page >= 1) {
        if (alunosTotal <= 0) {
          setInitialNumberFromPage(0);
        } else {
          setInitialNumberFromPage(page * limitFromPage + 1 - limitFromPage);
        }
      }
    }

    handleLimits();
  }, [page, alunosTotal, numberFromPage, limitFromPage]);

  function handlePageSum() {
    const numberpages = Math.ceil(alunosTotal / limitFromPage);

    if (page < numberpages) {
      setPage(page + 1);
    }
  }

  function handlePageSubtract() {
    if (!(page <= 1)) {
      setPage(page - 1);
    }
  }

  function handleNewCursoAluno() {
    history.push('/admin/newaluno');
  }

  function handleEditaluno(aluno) {
    history.push({ pathname: '/admin/editaluno', state: { aluno } });
  }

  return (
    <Container>
      <Header>
        <h1>Gerenciamento de Alunos</h1>

        <Search>
          <Input>
            <MdSearch size={20} />
            <input
              placeholder="Buscar Aluno"
              onChange={(event) => setFilter(event.target.value)}
              type="text"
            />
          </Input>
          <Button type="button" onClick={handleNewCursoAluno}>
            Novo
          </Button>
        </Search>
      </Header>
      <Main>
        <Table>
          <thead>
            <tr>
              <th>Ações</th>
              <th>ID</th>
              <th>Nome</th>
              <th>E-mail</th>
              <th>CPF</th>
              <th>Cadastro</th>
              <th>Ativo</th>
            </tr>
          </thead>
          <tbody>
            {alunos &&
              alunos.map((aluno) => (
                <tr key={aluno.id}>
                  <td>
                    <button
                      type="button"
                      onClick={() => handleEditaluno(aluno)}
                    >
                      <MdBorderColor size={20} />
                    </button>
                  </td>
                  <td>{aluno.id}</td>
                  <td>{aluno.usu_nome}</td>
                  <td>{aluno.usu_email}</td>
                  <td>{aluno.usu_cpf}</td>
                  <td>{aluno.data}</td>
                  <td>
                    {aluno.usu_ativo ? (
                      <MdDoneAll size={20} color="#89c54b" />
                    ) : (
                      <MdClear size={20} color="#f64c75" />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Main>

      <Footer>
        <div>
          <MdKeyboardArrowLeft size={24} onClick={handlePageSubtract} />
          {page}
          <MdKeyboardArrowRight size={24} onClick={handlePageSum} />
        </div>

        <div>
          <span>{initialNumberFromPage}</span>
          <span>a</span>
          <span>{numberFromPage && numberFromPage}</span>
          <span>de</span>
          <span>{alunosTotal}</span>
        </div>
      </Footer>
    </Container>
  );
}
