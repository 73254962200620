import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  div {
    width: 340px;
    background: #121921;
    border-radius: 8px;
    margin-top: 15px;

    h2 {
      text-align: center;
      margin: 30px 0px 40px 0px;
      color: #fff;
      font-weight: 400;
    }

    p {
      color: #fff4;
      font-size: 13px;
      padding: 20px 0 4px 0;
    }

    form {
      margin: 20px;

      hr {
        color: #fff2;
        margin: 15px 6px;
      }

      input {
        margin: 5px 0px;
      }

      button {
        height: 36px;
        width: 100%;
        background: #89c54b;
        border: 0;
        border-radius: 18px;
        margin: 20px 0;
        color: #fff;

        &:hover {
          background: ${darken(0.1, '#89c54b')};
        }
      }

      span {
        color: #f64c75;
        font-size: 12px;
        margin: 0 0 10px;
      }
    }

    div {
      display: flex;
      flex-direction: row;

      padding: 20px;
    }
  }
`;
