import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.ul`
  margin-top: 20px;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & + li {
      margin-top: 15px;
    }
  }

  div {
    display: flex;
    align-items: center;
  }
`;

export const ListInfo = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      width: 100%;
    }

    strong {
      width: 100%;
      margin: 5px 0px;
    }

    span {
      width: 100%;
      font-size: 12px;
      color: #999;

      button {
        border: 0;
        background: transparent;
        color: #f64c75;
        margin-left: 5px;
        cursor: pointer;
        transition: 0.2s;
        &:hover {
          color: ${darken(0.15, '#f64c75')};
        }
      }
    }
  }
`;

export const Preview = styled.img`
  width: 100%;

  max-width: 50px;
  height: 50px;
  border-radius: 4px;
  margin-right: 10px;
`;
