import styled from 'styled-components';
import { Input as Inputs } from '@rocketseat/unform';

export const Input = styled(Inputs)`
  width: 100%;
  height: 38px;
  padding: 0 20px;

  border: 0;
  border-radius: 19px;
  background: #222830;
  color: #fff;

  &::placeholder {
    font-size: 14px;
    color: #fff8;
  }

  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'auto')};
`;
