import React, { useState, useCallback } from 'react';
import { Form, Input } from '@rocketseat/unform';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { MdHelpOutline } from 'react-icons/md';
import { toast } from 'react-toastify';

import { mascaraCep } from '../../../../utils/masks';

import api from '../../../../services/api';
import history from '../../../../services/history';

import {
  Container,
  Header,
  Info,
  Item,
  Columns2,
  Columns3,
  Row,
} from './styles';

const schema = Yup.object().shape({
  ent_razao_social: Yup.string().required('A Razão Social é obrigatório!'),
  ent_nome_fantasia: Yup.string().required('O Nome Fantasia é obrigatório!'),
  ent_cnpj: Yup.string().required('O CNPJ é obrigatório!'),
  ent_insc_municipal: Yup.string().required('A Insc. Municipal é obrigatório!'),
  ent_insc_estadual: Yup.string().required('A Insc. Estadual é obrigatório!'),
  ent_endereco: Yup.string().required('O Endereço é obrigatório!'),
  ent_numero: Yup.string().required('O Número é obrigatório!'),
  ent_complemento: Yup.string(),
  ent_bairro: Yup.string().required('O Bairro é obrigatório!'),
  ent_cep: Yup.string().required('O CEP é obrigatório!'),
  ent_cidade: Yup.string().required('A Cidade é obrigatório!'),
  ent_uf: Yup.string().required('O UF é obrigatório!'),
  ent_telefone: Yup.string().required('O Telefone é obrigatório!'),
  ent_site: Yup.string().required('O Site é obrigatório!'),
  ent_email: Yup.string()
    .email('O E-mail deve ser valido')
    .required('O E-mail é obrigatório!'),
});

export default function Entidade() {
  const [visible, setVisible] = useState(true);

  function handleToggleVisible() {
    setVisible(!visible);
  }

  async function handleSubmit(data) {
    try {
      await api.post('entidade', data);

      toast.success('Entidade cadastrado com sucesso!');

      history.push('/admin/entidades');
    } catch (error) {
      toast.error(`Erro ao cadastrar Entidade, ${error.response.data.message}`);
    }
  }

  const onKeyUpCep = useCallback((event) => {
    mascaraCep(event);
  }, []);

  return (
    <Container>
      <Header>
        <Link to="/admin/entidades">
          <u>Voltar</u>
        </Link>
        <h1>Gerenciamento de Entidades</h1>
      </Header>

      <Info visible={visible}>
        <MdHelpOutline size={24} onClick={handleToggleVisible} />
        <span>
          Todos os campos são obrigatórios para cadastrar uma entidade
        </span>
      </Info>

      <Form schema={schema} onSubmit={handleSubmit}>
        <Columns2>
          <Item>
            <Input
              type="text"
              name="ent_razao_social"
              placeholder="Razão Social"
            />
          </Item>

          <Item>
            <Input
              type="text"
              name="ent_nome_fantasia"
              placeholder="Nome Fantasia"
            />
          </Item>
        </Columns2>

        <Columns3>
          <Item>
            <Input type="text" name="ent_cnpj" placeholder="CNPJ" />
          </Item>

          <Item>
            <Input
              type="text"
              name="ent_insc_municipal"
              placeholder="Insc. Municipal"
            />
          </Item>

          <Item>
            <Input
              type="text"
              name="ent_insc_estadual"
              placeholder="Insc. Estadual"
            />
          </Item>
        </Columns3>

        <Row />

        <Columns2>
          <Item>
            <Input type="text" name="ent_cidade" placeholder="Cidade" />
          </Item>

          <Item>
            <Input type="text" name="ent_endereco" placeholder="Endereço" />
          </Item>
        </Columns2>

        <Columns3>
          <Item>
            <Input type="text" name="ent_numero" placeholder="Número" />
          </Item>
          <Item>
            <Input type="text" name="ent_bairro" placeholder="Bairro" />
          </Item>

          <Item>
            <Input
              type="text"
              name="ent_complemento"
              placeholder="Complemento"
            />
          </Item>
        </Columns3>

        <Columns3>
          <Item>
            <Input
              onKeyUp={onKeyUpCep}
              type="text"
              name="ent_cep"
              placeholder="CEP"
            />
          </Item>

          <Item>
            <Input type="text" name="ent_uf" placeholder="UF" />
          </Item>

          <Item>
            <Input type="text" name="ent_telefone" placeholder="Telefone" />
          </Item>
        </Columns3>

        <Row />

        <Columns2>
          <Item>
            <Input type="text" name="ent_site" placeholder="Site" />
          </Item>

          <Item>
            <Input type="text" name="ent_email" placeholder="E-mail" />
          </Item>
        </Columns2>

        <button type="submit">Salvar</button>
      </Form>
    </Container>
  );
}
