import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  background: #121921;
  width: 80%;
  margin: 15px auto;
  border-radius: 8px;
  color: #fff;

  overflow-x: auto;

  @media (max-width: 700px) {
    width: 95%;
  }

  h2 {
    font-weight: lighter;
    padding: 20px;
  }
`;

export const Table = styled.table`
  border-top: 2px solid #fff2;
  border-collapse: separate;
  border-spacing: 0 5px;

  padding: 20px;
  width: 100%;
  background: #222830;
  border-radius: 0 0 8px 8px;

  tbody tr {
    transition: background 0.2s;

    &:hover {
      background: #fff2;
      border-radius: 8px;
    }
  }

  th,
  td {
    padding: 5px 8px;
    text-align: left;
  }

  td {
    border-bottom: 1px solid #fff1;
    color: #fff8;

    button {
      border: 0;
      background: none;
      transition: 0.2s;

      &:hover {
        background: #fff2;
      }
    }
  }
`;
