import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MdKeyboardBackspace, MdCheckCircle } from 'react-icons/md';
import { Form } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import api from '../../services/api';
import history from '../../services/history';
import { InputSelect } from '../../components/InputSelect';
import Input from '../../components/InputText';
import Button from '../../components/Button';
import { mascaraCpf } from '../../utils/masks';
import { Container, ContainerInfo, Header, Content, Screen } from './styles';

export default function Order({ location }) {
  const [idEntidade] = useState(location.state.idEntidade);

  const [checked, setChecked] = useState(false);
  const [periodoLetivo, setPeriodoLetivo] = useState('');
  const [curso, setCurso] = useState('');
  const [semestre, setSemestre] = useState('');

  const [errorSemestre, setErrorSemestre] = useState(false);
  const [errorCurso, setErrorCurso] = useState(false);
  const [errorAnoLetivo, setErrorAnoLetivo] = useState(false);

  const usuario = useSelector((state) => state.user.profile);

  const loadPeriodosLetivos = useCallback(async () => {
    const response = await api
      .get(`/periodoletivo/${idEntidade}`)
      .then((periodoletivo) => {
        const options = periodoletivo.data.map((periodo) => ({
          value: periodo.id,
          label: periodo.pel_descricao,
        }));

        return options;
      })
      .catch(() => {
        toast.error('Erro ao buscar periodos letivos');
      });

    return response;
  }, [idEntidade]);

  const loadCursos = useCallback(async () => {
    const response = await api
      .get(`/curso/${idEntidade}`)
      .then((cursos) => {
        const options = cursos.data.map((curso) => ({
          value: curso.id,
          label: curso.cur_descricao,
        }));
        return options;
      })
      .catch(() => {
        toast.error('Erro ao buscar os cursos');
      });
    return response;
  }, [idEntidade]);

  const loadSemestres = useCallback(async () => {
    const response = await api
      .get(`/periodo/${idEntidade}`, {
        params: {
          entidade_id: idEntidade,
        },
      })
      .then((semestres) => {
        const options = semestres.data.map((semestre) => ({
          value: semestre.id,
          label: semestre.per_descricao,
        }));
        return options;
      })
      .catch(() => {
        toast.error('Erro ao buscar semestres');
      });
    return response;
  }, [idEntidade]);

  const periodoLetivoSelected = useCallback((selectedOption) => {
    setPeriodoLetivo(selectedOption);
  }, []);

  const cursoSelected = useCallback((selectedOption) => {
    setCurso(selectedOption);
  }, []);

  const semestreSelected = useCallback((selectedOption) => {
    setSemestre(selectedOption);
  }, []);

  const cpfOnKeyUp = useCallback((event) => {
    mascaraCpf(event);
  }, []);

  const schema = Yup.object().shape({
    usu_nome: Yup.string()
      .min(3, 'Digite um nome válido')
      .required('Campo obrigatório'),
    usu_email: Yup.string()
      .email('Digite um e-mail válido')
      .required('Campo obrigatório'),
    usu_cpf: Yup.string().required('Campo obrigatório'),
    usu_rg: Yup.string()
      .min(3, 'Digite um RG válido')
      .required('Campo obrigatório'),
    usu_ra: Yup.string().required('Campo obrigatório'),
    usu_dataNascimento: Yup.string().required('Campo obrigatório'),
  });

  function backPage() {
    if (usuario.usu_admin) history.push('/admin/bolsas');
    else history.push('/dashboard');
  }

  async function CriarFichaAnalise(data) {
    if (!semestre) {
      setErrorSemestre(true);
      return;
    }
    setErrorSemestre(false);

    if (!curso) {
      setErrorCurso(true);
      return;
    }
    setErrorCurso(false);

    if (!periodoLetivo) {
      setErrorAnoLetivo(true);
      return;
    }
    setErrorAnoLetivo(false);

    if (usuario.usu_admin) {
      try {
        await api.post('fichaanaliseadmin', {
          entidade_id: idEntidade,
          periodo_letivo_id: periodoLetivo.value,
          curso_id: curso.value,
          periodo_id: semestre.value,
          fas_alu_ra: data.usu_ra,
          fas_alu_nome: data.usu_nome,
          fas_alu_nascimento: data.usu_dataNascimento,
          fas_alu_cpf: data.usu_cpf,
          fas_alu_rg: data.usu_rg,
          fas_alu_email: data.usu_email,
        });
        toast.success('Ficha cadastrada com sucesso!');
        history.push('/admin/bolsas');
      } catch (error) {
        toast.error(error.response.data.message);
      }
    } else {
      try {
        const response = await api.post('fichaanalisesocial', {
          entidade_id: idEntidade,
          usuario_id: usuario.id,
          periodo_letivo_id: periodoLetivo.value,
          curso_id: curso.value,
          periodo_id: semestre.value,
          fas_alu_ra: data.usu_ra,
          fas_alu_nome: data.usu_nome,
          fas_alu_nascimento: data.usu_dataNascimento,
          fas_alu_cpf: data.usu_cpf,
          fas_alu_rg: data.usu_rg,
          fas_alu_email: data.usu_email,
        });

        setChecked(true);
        setTimeout(() => {
          setChecked(false);

          history.push({
            pathname: '/order/edit',
            state: {
              idFichaAnalise: response.data.id,
              idEntidade,
              idSituacao: response.data.situacao_id,
            },
          });
        }, 4000);
      } catch (error) {
        toast.error(`${error.response.data.message}`);
      }
    }
  }

  return (
    <Container>
      <Header>
        <h2>Solicitação de Bolsa</h2>
        <button type="button" onClick={backPage}>
          <MdKeyboardBackspace size={20} />
          <u>Voltar</u>
        </button>
      </Header>

      {checked && (
        <Screen>
          <MdCheckCircle size={80} />
          <h2>Ficha cadastrada com sucesso, atualize os próximos dados!</h2>
        </Screen>
      )}
      <Form
        schema={schema}
        initialData={usuario.usu_admin ? null : usuario}
        onSubmit={CriarFichaAnalise}
      >
        <Content>
          <ContainerInfo>
            <h4>Nome:</h4>
            <Input type="text" name="usu_nome" placeholder="Nome" required />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Email:</h4>
            <Input
              type="email"
              name="usu_email"
              placeholder="E-mail"
              autoComplete="off"
              required
            />
          </ContainerInfo>

          <ContainerInfo>
            <h4>CPF:</h4>
            <Input
              type="text"
              name="usu_cpf"
              placeholder="CPF"
              onKeyUp={cpfOnKeyUp}
              required
            />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Semestre:</h4>
            <InputSelect
              placeholder="Semestre"
              loadOptions={loadSemestres}
              onChange={semestreSelected}
              name="semestre"
              value={semestre}
            />
            {errorSemestre && <span>Selecione alguma opção!</span>}
          </ContainerInfo>

          <ContainerInfo>
            <h4>Curso:</h4>
            <InputSelect
              placeholder="Seu Curso"
              loadOptions={loadCursos}
              onChange={cursoSelected}
              name="curso"
              value={curso}
            />
            {errorCurso && <span>Selecione alguma opção!</span>}
          </ContainerInfo>

          <ContainerInfo>
            <h4>Ano letivo:</h4>
            <InputSelect
              placeholder="Ano letivo"
              loadOptions={loadPeriodosLetivos}
              onChange={periodoLetivoSelected}
              name="periodoletivo"
              value={periodoLetivo}
            />
            {errorAnoLetivo && <span>Selecione alguma opção!</span>}
          </ContainerInfo>

          <ContainerInfo>
            <h4>RG:</h4>
            <Input type="text" name="usu_rg" placeholder="RG" required />
          </ContainerInfo>

          <ContainerInfo>
            <h4>RA: (00000-0 para alunos do 1º semestre)</h4>
            <Input type="text" name="usu_ra" placeholder="RA" required />
          </ContainerInfo>

          <ContainerInfo>
            <h4>Data de nascimento:</h4>
            <Input
              type="date"
              name="usu_dataNascimento"
              placeholder="Data Nascimento"
              maxLength={10}
              required
            />
          </ContainerInfo>
        </Content>

        <Button type="submit">Continuar</Button>
      </Form>
    </Container>
  );
}

Order.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
