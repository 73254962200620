export function handleEntityRequest(entidade_id) {
  return {
    type: '@entity/HANDLE_ENTITY_REQUEST',
    entidade_id,
  };
}

export function handleEntitySuccess(entidade) {
  return {
    type: '@entity/HANDLE_ENTITY_SUCCESS',
    entidade,
  };
}

export function handleEntityOut() {
  return {
    type: '@entity/HANDLE_ENTITY_OUT',
  };
}
