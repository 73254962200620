import React, { useState } from 'react';
import { Form, Input, Check } from '@rocketseat/unform';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { MdHelpOutline } from 'react-icons/md';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import api from '../../../../services/api';
import history from '../../../../services/history';

import {
  Container,
  Header,
  Info,
  Item,
  Columns2,
  Columns3,
  Row,
  Label,
} from './styles';

const schema = Yup.object().shape({
  ent_razao_social: Yup.string().required('A Razão Social é obrigatório!'),
  ent_nome_fantasia: Yup.string().required('O Nome Fantasia é obrigatório!'),
  ent_cnpj: Yup.string().required('O CNPJ é obrigatório!'),
  ent_insc_municipal: Yup.string().required('A Insc. Municipal é obrigatório!'),
  ent_insc_estadual: Yup.string().required('A Insc. Estadual é obrigatório!'),
  ent_endereco: Yup.string().required('O Endereço é obrigatório!'),
  ent_numero: Yup.string().required('O Número é obrigatório!'),
  ent_complemento: Yup.string().required('O Complemento é obrigatório!'),
  ent_bairro: Yup.string().required('O Bairro é obrigatório!'),
  ent_cep: Yup.string().required('O CEP é obrigatório!'),
  ent_cidade: Yup.string().required('A Cidade é obrigatório!'),
  ent_uf: Yup.string().required('O UF é obrigatório!'),
  ent_telefone: Yup.string().required('O Telefone é obrigatório!'),
  ent_site: Yup.string().required('O Site é obrigatório!'),
  ent_email: Yup.string().required('O E-mail é obrigatório!'),
  ent_ativo: Yup.bool().required('Ativo é obrigatório!'),
});

export default function EditeEntidade({ location }) {
  const [visible, setVisible] = useState(true);
  const [editEntidade] = useState(location.state.entidade);

  function handleToggleVisible() {
    setVisible(!visible);
  }

  async function handleEdit(entidade) {
    try {
      await api.put(`/entidade/${editEntidade.id}`, entidade);

      toast.success('Entidade editada com sucesso!');

      history.push('/admin/entidades');
    } catch (error) {
      toast.error(`Erro ao editar entidade, ${error.response.data.message}`);
    }
  }

  return (
    <Container>
      <Header>
        <Link to="/admin/entidades">
          <u>Voltar</u>
        </Link>
        <h1>Edição de Entidades</h1>
      </Header>

      <Info visible={visible}>
        <MdHelpOutline size={24} onClick={handleToggleVisible} />
        <span>Todos os campos são obrigatórios para editar uma entidade</span>
      </Info>

      <Form schema={schema} onSubmit={handleEdit} initialData={editEntidade}>
        <Columns2>
          <Item>
            <Label>Razão Social</Label>
            <Input
              type="text"
              name="ent_razao_social"
              placeholder="Razão Social"
            />
          </Item>

          <Item>
            <Label>Nome Fantasia</Label>
            <Input
              type="text"
              name="ent_nome_fantasia"
              placeholder="Nome Fantasia"
            />
          </Item>
        </Columns2>

        <Columns3>
          <Item>
            <Label>CNPJ</Label>
            <Input type="text" name="ent_cnpj" placeholder="CNPJ" />
          </Item>

          <Item>
            <Label>Insc. Municipal</Label>
            <Input
              type="text"
              name="ent_insc_municipal"
              placeholder="Insc. Municipal"
            />
          </Item>

          <Item>
            <Label>Insc. Estadual</Label>
            <Input
              type="text"
              name="ent_insc_estadual"
              placeholder="Insc. Estadual"
            />
          </Item>
        </Columns3>

        <Row />

        <Columns2>
          <Item>
            <Label>Cidade</Label>
            <Input type="text" name="ent_cidade" placeholder="Cidade" />
          </Item>

          <Item>
            <Label>Endereço</Label>
            <Input type="text" name="ent_endereco" placeholder="Endereço" />
          </Item>
        </Columns2>

        <Columns3>
          <Item>
            <Label>Número</Label>
            <Input type="text" name="ent_numero" placeholder="Número" />
          </Item>

          <Item>
            <Label>Bairro</Label>
            <Input type="text" name="ent_bairro" placeholder="Bairro" />
          </Item>

          <Item>
            <Label>Complemento</Label>
            <Input
              type="text"
              name="ent_complemento"
              placeholder="Complemento"
            />
          </Item>
        </Columns3>

        <Columns3>
          <Item>
            <Label>CEP</Label>
            <Input type="text" name="ent_cep" placeholder="CEP" />
          </Item>

          <Item>
            <Label>UF</Label>
            <Input type="text" name="ent_uf" placeholder="UF" />
          </Item>

          <Item>
            <Label>Telefone</Label>
            <Input type="text" name="ent_telefone" placeholder="Telefone" />
          </Item>
        </Columns3>

        <Row />

        <Columns3>
          <Item>
            <Label>Site</Label>
            <Input type="text" name="ent_site" placeholder="Site" />
          </Item>

          <Item>
            <Label>E-mail</Label>
            <Input type="text" name="ent_email" placeholder="E-mail" />
          </Item>

          <Item className="ativo">
            <Label>Ativo</Label>
            <Check type="checkbox" name="ent_ativo" />
          </Item>
        </Columns3>

        <button type="submit">Salvar</button>
      </Form>
    </Container>
  );
}

EditeEntidade.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
