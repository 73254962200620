import React, { useState, useEffect } from 'react';
import { Form, Input, Check } from '@rocketseat/unform';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { MdBorderColor } from 'react-icons/md';
import PropTypes from 'prop-types';

import api from '../../../../services/api';
import history from '../../../../services/history';
import { store } from '../../../../store';
import formatPrice from '../../../../utils/formatPrice';

import {
  Container,
  Header,
  Item,
  Columns2,
  Label,
  Main,
  Table,
  Row,
} from './styles';

const schema = Yup.object().shape({
  pel_descricao: Yup.string().required('A Descrição é obrigatório!'),
  pel_ativo: Yup.bool().required('Ativo é obrigatório!'),
});

export default function EditePeriodoLetivo({ location }) {
  const { entity } = store.getState().entity;
  const [editAnoLetivo] = useState(location.state.anoLetivo);
  const [cursosValor, setCursosValor] = useState();

  useEffect(() => {
    async function loadCursos() {
      const response = await api.get('cursovalor', {
        params: { entidade_id: entity, periodo_letivo_id: editAnoLetivo.id },
      });

      const cursosvalores = response.data.map((cursovalor) => ({
        ...cursovalor,
        valorFormatado: formatPrice(cursovalor.cuv_valor),
      }));
      setCursosValor(cursosvalores);
    }
    loadCursos();
  }, [entity, editAnoLetivo.id]);

  async function handleEdit(anoLetivo) {
    try {
      await api.put(`/periodoletivo/${editAnoLetivo.id}`, anoLetivo);

      toast.success('Ano Letivo editado com sucesso!');

      history.push('/admin/periodosletivo');
    } catch (error) {
      toast.error(`Erro ao editar Ano Letivo, verifique os dados!`);
    }
  }

  async function handleEditValor(CursoValor) {
    history.push({
      pathname: '/admin/valorcurso',
      state: { curso: CursoValor, periodo_letivo_id: editAnoLetivo.id },
    });
  }

  return (
    <Container>
      <Header>
        <Link to="/admin/periodosletivo">
          <u>Voltar</u>
        </Link>
        <h1>Edição do Ano Letivo</h1>
      </Header>

      <Form schema={schema} onSubmit={handleEdit} initialData={editAnoLetivo}>
        <Columns2>
          <Item>
            <Label>Ano letivo</Label>
            <Input
              type="text"
              name="pel_descricao"
              placeholder="Descrição do Curso"
            />
          </Item>

          <Item className="ativo">
            <Label>Ativo</Label>
            <Check type="checkbox" name="pel_ativo" />
          </Item>
        </Columns2>

        <button type="submit">Salvar</button>
      </Form>

      <h3>{`Valores dos cursos referente ao ano de ${editAnoLetivo.pel_descricao}`}</h3>
      <Row />

      <Main>
        <Table>
          <thead>
            <tr>
              <th>Ações</th>
              <th>ID</th>
              <th>Descrição</th>
              <th>Valor do Curso</th>
            </tr>
          </thead>
          <tbody id="cuv_valor">
            {cursosValor &&
              cursosValor.map((cursoValor) => (
                <tr key={cursoValor.id}>
                  <td>
                    <button
                      type="button"
                      onClick={() => handleEditValor(cursoValor)}
                    >
                      <MdBorderColor size={20} />
                    </button>
                  </td>
                  <td>{cursoValor.id}</td>
                  <td>{cursoValor.curso.cur_descricao}</td>
                  <td>{cursoValor.valorFormatado}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Main>
    </Container>
  );
}

EditePeriodoLetivo.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
