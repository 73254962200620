import styled from 'styled-components';
import { darken } from 'polished';

export const Section = styled.div`
  @media (min-width: 1600px) {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
  }

  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 500px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  width: 100%;
  background: #222830;
  border-top: 2px solid #fff2;
  border-radius: 0 0 8px 8px;
  padding: 20px;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
`;

export const Button = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 120px;
  margin: 10px;
  border-radius: 4px;
  background: #121921;
  color: #fff9;

  &:hover {
    color: ${darken(0.2, '#fff9')};
  }

  span {
    text-align: center;
    font-size: 16px;
    margin: auto 5px;
  }

  svg {
    margin: 5px 0 15px 0;
  }
`;
