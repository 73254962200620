import styled from 'styled-components';

export const Details = styled.div`
  max-width: 500px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 20px;
  margin-bottom: 20px;

  border: 1px solid #fff8;
  border-radius: 4px;

  h3 {
    text-align: center;
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 30px;
  color: #fff8;

  th,
  td {
    border-spacing: auto;
    padding: 16px;
    border-bottom: 1px solid #fff2;
  }

  td {
    > button {
      background: none;
      transition: 0.2s;
      padding: 5px;

      &:hover {
        background: #fff2;
      }
    }
  }

  thead,
  tbody {
    text-align: center;
  }

  tbody {
    tr {
      transition: 0.2s;

      &:hover {
        background: #222830;
      }
    }
  }

  tfoot td + td {
    text-align: center;
  }

  tfoot tr {
    color: #fff;
    font-weight: bold;
    background: #222830;
  }
`;
