import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  width: 100%;
  background: #121921;
  border-radius: 8px;
  margin: 20px;

  form {
    width: 100%;
    padding: 0 20px;

    button {
      float: right;
    }
  }
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  margin: 24px 0;

  a {
    position: absolute;
    left: 20px;
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;

  span {
    width: 100%;
    float: left;
  }
`;
