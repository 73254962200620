import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../services/api';
import { Container, Card, Icon, List } from './styles';

export default function ModalListDocuments({ onClose, open, idEntidade }) {
  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const [documentosExigidos, setDocumentosExigidos] = useState([]);

  useEffect(() => {
    async function loadDocumentosExigidos() {
      const response = await api.get(`documentos/${idEntidade}`);

      setDocumentosExigidos(response.data);
    }
    loadDocumentosExigidos();
  }, [idEntidade]);

  return (
    open && (
      <Container>
        <Card>
          <h2>* Lista de todos documentos obrigatórios:</h2>

          <List>
            {documentosExigidos.map((documento) => (
              <li key={documento.id}>
                {'-  '}
                {documento.doc_descricao}
              </li>
            ))}
          </List>

          <Icon onClick={closeModal} />
        </Card>
      </Container>
    )
  );
}

ModalListDocuments.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  idEntidade: PropTypes.number,
};
ModalListDocuments.defaultProps = {
  onClose: true,
  open: true,
  idEntidade: null,
};
