import React, { useState } from 'react';
import { Form, Input, Check } from '@rocketseat/unform';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { Container, Header, Item, Columns2, Label } from './styles';

const schema = Yup.object().shape({
  usu_nome: Yup.string().required('O Nome é obrigatório!'),
  usu_email: Yup.string()
    .email('Insira um E-mail válido!')
    .required('O E-mail é obrigatório!'),
  usu_cpf: Yup.string().required('O CPF é obrigatório!'),
  usu_ativo: Yup.string().required('Ativo é obrigatório!'),
});

export default function EditAluno({ location }) {
  const [editAluno] = useState(location.state.aluno);

  async function handleEdit(aluno) {
    try {
      await api.put(`/usuarioadmin/${editAluno.id}`, aluno);

      toast.success('Aluno editado com sucesso!');

      history.push('/admin/alunos');
    } catch (error) {
      toast.error(`Erro ao editar Aluno, ${error.response.data.message}`);
    }
  }

  return (
    <Container>
      <Header>
        <Link to="/admin/alunos">
          <u>Voltar</u>
        </Link>
        <h1>Edição de Alunos</h1>
      </Header>

      <Form schema={schema} onSubmit={handleEdit} initialData={editAluno}>
        <Columns2>
          <Item>
            <Label>Nome do aluno</Label>
            <Input type="text" name="usu_nome" placeholder="Nome do aluno" />
          </Item>

          <Item>
            <Label>E-mail do aluno</Label>
            <Input type="text" name="usu_email" placeholder="E-mail do aluno" />
          </Item>
        </Columns2>

        <Columns2>
          <Item>
            <Label>CPF do aluno</Label>
            <Input
              className="blocked"
              disabled
              type="text"
              name="usu_cpf"
              placeholder="CPF do aluno"
            />
          </Item>

          <Item className="ativo">
            <Label>Ativo</Label>
            <Check type="checkbox" name="usu_ativo" />
          </Item>
        </Columns2>

        <button type="submit">Salvar</button>
      </Form>
    </Container>
  );
}

EditAluno.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
