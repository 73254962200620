import produce from 'immer';

const INITIAL_STATE = {
  entity: null,
};

export default function entity(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@entity/HANDLE_ENTITY_SUCCESS': {
        draft.entity = action.entidade;
        break;
      }

      case '@entity/HANDLE_ENTITY_OUT': {
        draft.entity = null;
        break;
      }

      default:
    }
  });
}
