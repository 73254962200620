import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';

import { updateProfileSuccess, updateProfileFailure } from './actions';

export function* updateProfile({ payload }) {
  try {
    const { usu_nome, usu_email, ...rest } = payload.data;

    const profile = {
      usu_nome,
      usu_email,
      ...(rest.usu_oldPassword ? rest : {}),
    };

    const response = yield call(api.put, `usuario/${payload.data.id}`, profile);

    toast.success('Dados Atualizados com sucesso!');
    history.goBack();
    yield put(updateProfileSuccess(response.data));
  } catch (error) {
    toast.error(
      `Erro para atualizar seu perfil, ${error.response.data.message}`
    );

    yield put(updateProfileFailure());
  }
}

export default all([takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile)]);
