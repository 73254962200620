import React from 'react';
import { Form, Input } from '@rocketseat/unform';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import api from '../../../../services/api';
import history from '../../../../services/history';
import { store } from '../../../../store';

import { Container, Header, Item } from './styles';

const schema = Yup.object().shape({
  avl_descricao: Yup.string().required('A descrição é obrigatório!'),
});

export default function NewDocumento() {
  const { entity } = store.getState().entity;

  async function handleSubmit({ avl_descricao }) {
    try {
      await api.post('avisoslegais', { avl_descricao, entidade_id: entity });

      toast.success('Aviso Legal cadastrado com sucesso');

      history.push('/admin/avisos');
    } catch (error) {
      toast.error('Erro ao cadastrar Aviso Legal, verifique os dados!');
    }
  }

  return (
    <Container>
      <Header>
        <Link to="/admin/avisos">
          <u>Voltar</u>
        </Link>
        <h1>Gerenciamento de Avisos Legais</h1>
      </Header>

      <Form schema={schema} onSubmit={handleSubmit}>
        <Item>
          <Input
            multiline
            rows="4"
            type="text"
            name="avl_descricao"
            placeholder="Descrição do Aviso Legal"
          />
        </Item>

        <button type="submit">Cadastrar</button>
      </Form>
    </Container>
  );
}
