import React, { useEffect, useState } from 'react';
import { MdBorderColor, MdAssignment } from 'react-icons/md';
import PropTypes from 'prop-types';
import Header from '../../../components/HeaderForm';
import { Container, Table } from './styles';
import history from '../../../services/history';
import formatPrice from '../../../utils/formatPrice';

import api from '../../../services/api';

export default function DespesasMensais({ location }) {
  const [idFichaAnalise] = useState(location.state.idFichaAnalise);
  const [idEntidade] = useState(location.state.idEntidade);
  const [idSituacao] = useState(location.state.idSituacao);

  const [despesasMensais, setDespesasMensais] = useState([]);
  const [valorTotalSomaDespesas, setValorTotalSomaDespesas] = useState(0);

  useEffect(() => {
    async function loadDespesasMensais() {
      const { data } = await api.get(`despesasmensais/${idFichaAnalise}`, {
        params: { entidade_id: idEntidade },
      });

      setDespesasMensais(
        data.despesaMensal.map((despesa) => ({
          ...despesa,
          valor: formatPrice(despesa.dem_valor),
        }))
      );

      setValorTotalSomaDespesas(formatPrice(data.somaDespesas));
    }
    loadDespesasMensais();
  }, [idFichaAnalise, idEntidade]);

  return (
    <Container>
      <Header title="Despesas Mensais" icon={MdAssignment} />

      {!!despesasMensais.length && (
        <Table idSituacao={idSituacao}>
          <thead>
            <tr>
              <th>Editar</th>
              <th>Despesa</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            {despesasMensais.map(({ id, ...despesaMensal }) => (
              <tr key={id}>
                <td>
                  <button type="button">
                    <MdBorderColor
                      size={20}
                      color="#fff"
                      onClick={() =>
                        history.push({
                          pathname: '/order/despesas/edit',
                          state: {
                            idFichaAnalise,
                            idDespesa: id,
                            nomeDespesa: despesaMensal.despesa.des_descricao,
                            valorDespesa: despesaMensal.dem_valor,
                          },
                        })
                      }
                    />
                  </button>
                </td>
                <td>{despesaMensal.despesa.des_descricao}</td>
                <td>{despesaMensal.valor}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={idSituacao >= 3 ? '1' : '2'}>
                Total Geral({despesasMensais.length})
              </td>
              <td>{valorTotalSomaDespesas}</td>
            </tr>
          </tfoot>
        </Table>
      )}
    </Container>
  );
}
DespesasMensais.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
