import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;

  p {
    position: absolute;
    bottom: 15px;
    right: 15px;
    color: #999;
    font-size: 15px;
    padding: 20px 0 4px 0;
  }
`;
