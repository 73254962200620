import React from 'react';
import { MdDoneAll } from 'react-icons/md';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function Check({ title }) {
  return (
    <Container>
      <MdDoneAll size={38} />
      {title}
    </Container>
  );
}

Check.propTypes = {
  title: PropTypes.string.isRequired,
};
