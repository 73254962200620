export function mascaraCpf(cpf) {
  cpf.currentTarget.maxLength = 14;
  let valorInput = cpf.currentTarget.value;

  valorInput = valorInput.replace(/\D/g, '');
  valorInput = valorInput.replace(/^(\d{3})(\d)/, '$1.$2');
  valorInput = valorInput.replace(/(\d{3})(\d)/, '$1.$2');
  valorInput = valorInput.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

  cpf.currentTarget.value = valorInput;

  return cpf;
}

export function mascaraCep(cep) {
  cep.currentTarget.maxLength = 9;

  let valorInput = cep.currentTarget.value;
  valorInput = valorInput.replace(/\D/g, '');

  valorInput = valorInput.replace(/(\d{5})(\d)/, '$1-$2');

  cep.currentTarget.value = valorInput;

  return cep;
}

export function mascaraCelular(celular) {
  celular.currentTarget.maxLength = 15;

  let valorInput = celular.currentTarget.value;
  valorInput = valorInput.replace(/\D/g, '');

  valorInput = valorInput.replace(/^(\d{2})(\d)/g, '($1) $2');
  valorInput = valorInput.replace(/(\d)(\d{4})$/, '$1-$2');

  celular.currentTarget.value = valorInput;

  return celular;
}
