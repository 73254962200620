import React from 'react';
import {
  MdPerson,
  MdLocationOn,
  MdInfo,
  MdEdit,
  MdPeople,
  MdAssignment,
  MdAttachMoney,
  MdAttachFile,
  MdContacts,
  MdLibraryBooks,
} from 'react-icons/md';
import { FaRegEdit } from 'react-icons/fa';
import { GoGraph } from 'react-icons/go';
import PropTypes from 'prop-types';
import history from '../../services/history';

import Check from '../Check';

import { Section, Button } from './styles';

export default function Order({
  idFichaAnalise,
  idEntidade,
  idRequerimento,
  idSituacao,
  checksInformations,
  usuarioAdmin,
}) {
  return (
    <Section>
      <Button
        type="button"
        onClick={() =>
          history.push({
            pathname: '/order/personaldata',
            state: { idFichaAnalise, idSituacao },
          })
        }
      >
        <span>Dados Pessoais</span>
        <MdPerson size={30} />

        {checksInformations && checksInformations.dados_pessoais && (
          <Check title="Dados Pessoais" />
        )}
      </Button>
      <Button
        type="button"
        onClick={() =>
          history.push({
            pathname: '/order/address',
            state: { idFichaAnalise, idSituacao },
          })
        }
      >
        <span>Outros Endereços</span>
        <MdLocationOn size={30} />
      </Button>

      <Button
        type="button"
        onClick={() =>
          history.push({
            pathname: '/order/informations',
            state: { idFichaAnalise, idSituacao },
          })
        }
      >
        <span>Outras Informações</span>
        <MdInfo size={30} />

        {checksInformations && checksInformations.outras_informacoes && (
          <Check title="Outras Informações" />
        )}
      </Button>

      <Button
        type="button"
        onClick={() =>
          history.push({
            pathname: '/order/justification',
            state: { idFichaAnalise, idSituacao },
          })
        }
      >
        <span>Justificativa</span>
        <MdEdit size={30} />

        {checksInformations && checksInformations.justificativa && (
          <Check title="Justificativa" />
        )}
      </Button>

      <Button
        type="button"
        onClick={() =>
          history.push({
            pathname: '/order/familycomposition',
            state: { idFichaAnalise, idEntidade, idSituacao, usuarioAdmin },
          })
        }
      >
        <span>Composição Familiar</span>
        <MdPeople size={30} />

        {checksInformations && checksInformations.composicao_familiar && (
          <Check title="Composição Familiar" />
        )}
      </Button>

      <Button
        type="button"
        onClick={() =>
          history.push({
            pathname: '/order/despesas',
            state: { idFichaAnalise, idEntidade, idSituacao },
          })
        }
      >
        <span>Despesas Mensais</span>
        <MdAssignment size={30} />

        {checksInformations && checksInformations.despesas_mensais && (
          <Check title="Despesas Mensais" />
        )}
      </Button>

      <Button
        type="button"
        onClick={() =>
          history.push({
            pathname: '/order/bens',
            state: { idFichaAnalise, idEntidade, idSituacao, usuarioAdmin },
          })
        }
      >
        <span>Bens</span>
        <MdAttachMoney size={30} />
      </Button>

      <Button
        type="button"
        onClick={() =>
          history.push({
            pathname: '/order/anexos',
            state: { idFichaAnalise, idEntidade, idSituacao },
          })
        }
      >
        <span>Anexos</span>
        <MdAttachFile size={30} />

        {checksInformations && checksInformations.anexos && (
          <Check title="Anexos" />
        )}
      </Button>

      {idRequerimento === 4 && (
        <Button
          type="button"
          onClick={() =>
            history.push({
              pathname: '/order/fiadores',
              state: { idFichaAnalise, idEntidade, idSituacao },
            })
          }
        >
          <span>Fiador</span>
          <MdContacts size={30} />

          {checksInformations && checksInformations.fiador && (
            <Check title="Fiador" />
          )}
        </Button>
      )}

      {usuarioAdmin === true && (
        <>
          <Button
            type="button"
            onClick={() =>
              history.push({
                pathname: '/order/pendencias',
                state: { idFichaAnalise, idEntidade, idSituacao },
              })
            }
          >
            <span>Pendências</span>
            <MdLibraryBooks size={30} />
          </Button>

          <Button
            type="button"
            onClick={() =>
              history.push({
                pathname: '/order/parecer',
                state: { idFichaAnalise, idEntidade, idSituacao },
              })
            }
          >
            <span>Parecer</span>
            <FaRegEdit size={30} />
          </Button>

          <Button
            type="button"
            onClick={() =>
              history.push({
                pathname: '/order/analise',
                state: { idFichaAnalise, idEntidade, idSituacao },
              })
            }
          >
            <span>Análise</span>
            <GoGraph size={30} />
          </Button>
        </>
      )}
    </Section>
  );
}
Order.propTypes = {
  idFichaAnalise: PropTypes.number.isRequired,
  idEntidade: PropTypes.number.isRequired,
  idSituacao: PropTypes.number.isRequired,
  idRequerimento: PropTypes.number,
  usuarioAdmin: PropTypes.bool.isRequired,
  checksInformations: PropTypes.oneOfType([PropTypes.object]),
};

Order.defaultProps = {
  idRequerimento: null,
  checksInformations: {},
};
