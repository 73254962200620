import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    height: 36px;
    width: 155px;
    padding: 0 20px;
    background: #89c54b;
    margin: 20px 0px;
    border: 0;
    border-radius: 18px;
    color: #fff;

    transition: background 0.2s;

    &:hover {
      background: ${darken(0.1, '#89c54b')};
    }
  }
`;
