import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import 'react-circular-progressbar/dist/styles.css';
import { darken } from 'polished';

import fundo from '../assets/images/fundo.png';

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
  }

  body, input, button, textarea {
    font: 14px 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  input[type='email'] {
    text-transform: lowercase;
  }

  input[type='email']::placeholder {
    text-transform: none;
  }

  h1, h2, h3, h4, h5, h6 {
    color:#fff;
    font-family: 'Ubuntu' sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  a {
    text-decoration: none;
    color: #999;
    background: transparent;
  }

  a, u {
    &:hover {
      color: ${darken(0.15, '#999')};
    }
  }

  button {
    border:0;
    cursor: pointer;
  }

  body {
    background-image: url(${fundo});
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;


  }
  .Toastify {
      > div{
        > div{
          border-radius: 8px;
          font-size: 16px;
        }
      }
    }
`;
