import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  overflow-x: auto;

  background: #121921;
  width: 85%;
  margin: 15px auto;
  border-radius: 8px;
  color: #fff;

  @media (max-width: 800px) {
    width: 95%;
  }

  span {
    color: #fff8;
  }

  a {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    padding: 10px;

    svg {
      margin-right: 5px;
    }
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  max-width: 400px;
  margin-bottom: 30px;
  color: #fff8;

  padding: 20px;

  th,
  td {
    border-spacing: auto;
    padding: 16px;
    border-bottom: 1px solid #fff2;
  }

  td {
    > button {
      background: none;
      transition: 0.2s;
      padding: 5px;

      &:hover {
        background: #fff2;
      }
    }
  }

  thead > tr > th:first-child {
    display: ${({ idSituacao, isAdmin }) =>
    idSituacao >= 3 && !isAdmin && 'none'};
  }

  thead,
  tbody {
    text-align: center;
  }

  tbody {
    tr {
      transition: 0.2s;

      &:hover {
        background: #222830;
      }
    }
  }

  tbody > tr > td:first-child {
    display: ${({ idSituacao, isAdmin }) =>
    idSituacao >= 3 && !isAdmin && 'none'};
  }

  tfoot td + td {
    text-align: center;
  }

  tfoot tr {
    color: #fff;
    font-weight: bold;
    background: #222830;
  }
`;
