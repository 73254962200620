import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  position: relative;

  background: #121921;
  width: 80%;
  margin: 15px auto;
  border-radius: 8px;
  color: #fff;

  @media (max-width: 700px) {
    width: 95%;
  }

  span {
    color: #fff8;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 10px;

    span {
      color: #f64c75;
      font-size: 12px;
      margin: 0 0 10px;
      text-align: left;
    }
  }
`;

export const Value = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  padding: 20px;
  border: 1px solid #fff8;
  border-radius: 4px;

  label {
    margin: 5px;
  }

  span {
    text-align: center;
    margin-bottom: 15px;
  }
`;

export const Content = styled.div`
  border: 1px solid #fff8;
`;
