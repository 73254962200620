import React, { useCallback } from 'react';
import { Form, Input } from '@rocketseat/unform';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { mascaraCpf } from '../../../../utils/masks';

import api from '../../../../services/api';
import history from '../../../../services/history';

import { Container, Header, Columns2, Item } from './styles';

const schema = Yup.object().shape({
  usu_nome: Yup.string().required('O Nome é obrigatório!'),
  usu_email: Yup.string()
    .email('Insira um E-mail válido!')
    .required('O E-mail é obrigatório!'),
  usu_cpf: Yup.string().required('O CPF é obrigatório!'),
});

export default function NewUsuario() {
  async function handleSubmit({ usu_cpf, usu_email, usu_nome }) {
    try {
      await api.post('usuarioadmin', {
        usu_cpf,
        usu_email,
        usu_nome,
        usu_admin: true,
      });

      toast.success(
        'Usuário cadastrado com sucesso, uma senha foi enviada para o email!'
      );

      history.push('/admin/usuarios');
    } catch (error) {
      toast.error(`Erro ao cadastrar Usuário, ${error.response.data.message}`);
    }
  }

  const cpfOnKeyUp = useCallback((event) => {
    mascaraCpf(event);
  }, []);

  return (
    <Container>
      <Header>
        <Link to="/admin/usuarios">
          <u>Voltar</u>
        </Link>
        <h1>Gerenciamento de Usuários</h1>
      </Header>

      <Form schema={schema} onSubmit={handleSubmit}>
        <Columns2>
          <Item>
            <Input type="text" name="usu_nome" placeholder="Nome" />
          </Item>
          <Item>
            <Input
              type="text"
              name="usu_cpf"
              placeholder="CPF"
              onKeyUp={cpfOnKeyUp}
            />
          </Item>
        </Columns2>
        <Item>
          <Input
            type="email"
            name="usu_email"
            placeholder="E-mail"
            autoComplete="off"
          />
        </Item>

        <button type="submit">Cadastrar</button>
      </Form>
    </Container>
  );
}
