import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  background: #121921;
  width: 80%;
  margin: 15px auto;
  border-radius: 8px;
  color: #fff;

  @media (max-width: 800px) {
    width: 95%;
  }

  form {
    width: 95%;
  }

  h2 {
    font-weight: lighter;
    padding: 20px;
  }
`;

export const ButtonEdit = styled.div`
  width: 95%;
  display: flex;
  justify-content: flex-end;
  svg {
    padding: 5px;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      background: #fff2;
    }
  }
`;

export const Content = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;

  border: 1px solid #fff8;

  padding: 10px;

  margin-bottom: 20px;

  @media (max-width: 855px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

export const ContainerInfo = styled.div`
  h4 {
    margin: 5px;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  > button {
    margin: 20px 5px;

    height: 36px;
    width: 180px;
    padding: 0 20px;
    background: #89c54b;
    border: 0;
    border-radius: 18px;
    color: #fff;

    transition: background 0.2s;

    &:hover {
      background: ${darken(0.1, '#89c54b')};
    }
  }

  .delete {
    background: #db132f;
    transition: 0.4s;

    &:hover {
      background: ${darken(0.07, '#db132f')};
    }
  }

  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;

    button {
      margin: 10px;
    }
  }
`;

export const Button = styled.button`
  opacity: ${(props) => (props.disabled ? 0.1 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    padding: 10px;

    color: #999;
    background: none;

    svg {
      margin-right: 5px;
    }
  }

  @media (max-width: 400px) {
    display: flex;
    flex-direction: column;

    button {
      position: relative;
      padding: 0;
    }
  }
`;
