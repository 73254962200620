import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { MdCheckCircle, MdError, MdLink } from 'react-icons/md';
import PropTypes from 'prop-types';

import pdfIcon from '../../assets/images/pdf.svg';

import { Container, ListInfo, Preview } from './styles';

export default function FileList({ files, onDelete, newFile, idSituacao }) {
  const allFiles = files.concat(newFile);
  return (
    <Container>
      {allFiles.map((uploadedFile) => (
        <li key={uploadedFile.id}>
          <ListInfo>
            <Preview
              src={
                uploadedFile.type === 'application/pdf'
                  ? pdfIcon
                  : uploadedFile.url || uploadedFile.preview
              }
            />
            <div>
              <h4>{uploadedFile.descricao}</h4>
              <strong>{uploadedFile.name}</strong>
              <span>
                {uploadedFile.readableSize}
                {!!uploadedFile.url && idSituacao <= 2 && (
                  <button
                    type="button"
                    onClick={() => {
                      onDelete(uploadedFile.id);
                    }}
                  >
                    Excluir
                  </button>
                )}
              </span>
            </div>
          </ListInfo>

          <div>
            {!uploadedFile.uploaded && !uploadedFile.error && (
              <CircularProgressbar
                styles={{
                  root: { width: 25 },
                  path: { stroke: '#89c54b' },
                }}
                strokeWidth={10}
                value={uploadedFile.progress}
              />
            )}

            {uploadedFile.url && (
              <a
                href={uploadedFile.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <MdLink style={{ margin: 8 }} size={24} color="#FFF" />
              </a>
            )}

            {uploadedFile.uploaded && (
              <MdCheckCircle size={24} color="#89c54b" />
            )}

            {uploadedFile.error && <MdError size={24} color="#f64c75" />}
          </div>
        </li>
      ))}
    </Container>
  );
}

FileList.propTypes = {
  files: PropTypes.oneOfType([PropTypes.any]),
  newFile: PropTypes.oneOfType([PropTypes.any]),
  onDelete: PropTypes.oneOfType([PropTypes.func]),
};

FileList.defaultProps = {
  files: '',
  newFile: '',
  onDelete: '',
};
