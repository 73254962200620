import React, { useState, useCallback } from 'react';
import { MdContacts } from 'react-icons/md';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Input from '../../../components/InputText';
import { mascaraCep, mascaraCpf, mascaraCelular } from '../../../utils/masks';
import Header from '../../../components/HeaderForm';
import { Container } from './styles';
import Button from '../../../components/Button';
import history from '../../../services/history';

import api from '../../../services/api';

export default function CadastarFiadores({ location }) {
  const [idFichaAnalise] = useState(location.state.idFichaAnalise);
  const [idEntidade] = useState(location.state.idEntidade);

  const onKeyUpCep = useCallback((event) => {
    mascaraCep(event);
  }, []);

  const onKeyUpCpf = useCallback((event) => {
    mascaraCpf(event);
  }, []);

  const onKeyUpCelular = useCallback((event) => {
    mascaraCelular(event);
  }, []);

  const schema = Yup.object().shape({
    nome: Yup.string().required('Campo obrigatório!'),
    datanascimento: Yup.string().required('Campo obrigatório!'),
    cpf: Yup.string().required('Campo obrigatório!'),
    rg: Yup.string(),
    profissao: Yup.string(),
    rendamensal: Yup.number(),
    estadocivil: Yup.string(),
    naturalidade: Yup.string(),
    telefone: Yup.string(),
    celular: Yup.string().required('Campo obrigatório!'),
    email: Yup.string().email(),

    cep: Yup.string().required('Campo obrigatório!'),
    cidade: Yup.string().required('Campo obrigatório!'),
    complemento: Yup.string(),
    endereco: Yup.string().required('Campo obrigatório!'),
    bairro: Yup.string().required('Campo obrigatório!'),
    numero: Yup.number().typeError('Campo numerico!').required(),
    uf: Yup.string(),

    nomeconjuge: Yup.string(),
    datanascimentoconjuge: Yup.string(),
    cpfconjuge: Yup.string(),
    rgconjuge: Yup.string(),
    profissaoconjuge: Yup.string(),
    rendamensalconjuge: Yup.number(),
  });

  async function handleCadastrarFiador(data) {
    try {
      await api.post('fiadores', {
        entidade_id: idEntidade,
        ficha_analise_id: idFichaAnalise,
        fia_nome: data.nome,
        fia_nascimento: data.datanascimento,
        fia_cpf: data.cpf,
        fia_rg: data.rg,
        fia_profissao: data.profissao,
        fia_renda_mensal: data.rendamensal,
        fia_estado_civil: data.estadocivil,
        fia_naturalidade: data.naturalidade,
        fia_conjuge: data.nomeconjuge,
        fia_conjuge_nascimento: data.datanascimentoconjuge || null,
        fia_conjuge_cpf: data.cpfconjuge,
        fia_conjuge_rg: data.rgconjuge,
        fia_conjuge_profissao: data.profissaoconjuge,
        fia_conjuge_renda_mensal: data.rendamensalconjuge,
        fia_endereco_cep: data.cep,
        fia_endereco: data.endereco,
        fia_endereco_numero: data.numero,
        fia_endereco_complemento: data.complemento,
        fia_endereco_bairro: data.bairro,
        fia_endereco_cidade: data.cidade,
        fia_endereco_uf: data.uf,
        fia_telefone: data.telefone,
        fia_celular: data.celular,
        fia_email: data.email,
      });

      await api.put(`checkficha/${idFichaAnalise}`, {
        fiador: true,
      });

      toast.success('Fiador cadastrado com sucesso!');

      history.goBack();
    } catch (error) {
      toast.error('Erro ao cadastrar, verifique os dados novamente!');
    }
  }

  return (
    <Container>
      <Header title="Fiadores" icon={MdContacts} />

      <span>* Preencha os campos abaixo para cadastar um novo fiador</span>
      <Form
        schema={schema}
        onSubmit={handleCadastrarFiador}
        initialData={{
          rendamensal: 0,
          rendamensalconjuge: 0,
        }}
      >
        <h2>* Dados Pessoais</h2>

        <h4>Nome do fiador:</h4>
        <Input type="text" name="nome" autoComplete="off" />

        <h4>Data de nascimento:</h4>
        <Input type="date" name="datanascimento" autoComplete="off" />

        <h4>CPF:</h4>
        <Input type="text" name="cpf" autoComplete="off" onKeyUp={onKeyUpCpf} />

        <h4>RG:</h4>
        <Input type="text" name="rg" autoComplete="off" />

        <h4>Profissão:</h4>
        <Input type="text" name="profissao" autoComplete="off" />

        <h4>Renda mensal:</h4>
        <Input
          type="number"
          step="0.01"
          name="rendamensal"
          autoComplete="off"
        />

        <h4>Estado civil:</h4>
        <Input type="text" name="estadocivil" autoComplete="off" />

        <h4>Naturalidade:</h4>
        <Input type="text" name="naturalidade" autoComplete="off" />

        <h4>Telefone:</h4>
        <Input
          type="text"
          name="telefone"
          autoComplete="off"
          onKeyUp={onKeyUpCelular}
        />

        <h4>Celular:</h4>
        <Input
          type="text"
          name="celular"
          autoComplete="off"
          onKeyUp={onKeyUpCelular}
        />

        <h4>Email:</h4>
        <Input type="text" name="email" autoComplete="off" />

        <h2>* Endereço</h2>

        <h4>Cep:</h4>
        <Input type="text" name="cep" autoComplete="off" onKeyUp={onKeyUpCep} />
        <h4>Cidade:</h4>
        <Input type="text" name="cidade" autoComplete="off" />
        <h4>Complemento:</h4>
        <Input type="text" name="complemento" autoComplete="off" />
        <h4>Rua:</h4>
        <Input type="text" name="endereco" autoComplete="off" />
        <h4>Bairro:</h4>
        <Input type="text" name="bairro" autoComplete="off" />
        <h4>Número residencial:</h4>
        <Input type="number" name="numero" autoComplete="off" min="0" />
        <h4>UF:</h4>
        <Input type="text" name="uf" autoComplete="off" maxLength={2} />

        <h2>* Conjuge</h2>

        <h4>Nome do Conjuge:</h4>
        <Input type="text" name="nomeconjuge" autoComplete="off" />

        <h4>Data de nascimento:</h4>
        <Input type="date" name="datanascimentoconjuge" autoComplete="off" />

        <h4>CPF:</h4>
        <Input
          type="text"
          name="cpfconjuge"
          autoComplete="off"
          onKeyUp={onKeyUpCpf}
        />

        <h4>RG:</h4>
        <Input type="text" name="rgconjuge" autoComplete="off" />

        <h4>Profissão:</h4>
        <Input type="text" name="profissaoconjuge" autoComplete="off" />

        <h4>Renda mensal:</h4>
        <Input
          type="number"
          step="0.01"
          name="rendamensalconjuge"
          autoComplete="off"
        />

        <Button type="submit">Cadastar fiador</Button>
      </Form>
    </Container>
  );
}
CadastarFiadores.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
