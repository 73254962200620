import React, { useState, useEffect } from 'react';
import {
  MdSearch,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdBorderColor,
  MdClear,
  MdDoneAll,
} from 'react-icons/md';

import api from '../../../services/api';
import history from '../../../services/history';
import { store } from '../../../store';

import {
  Container,
  Header,
  Search,
  Input,
  Button,
  Main,
  Table,
  Footer,
} from './styles';

export default function AvisosLegais() {
  const { entity } = store.getState().entity;

  const [avisos, setAvisos] = useState();
  const [filter, setFilter] = useState();
  const [avisosTotal, setAvisosTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limitFromPage] = useState(10);
  const [initialNumberFromPage, setInitialNumberFromPage] = useState();
  const [numberFromPage, setNumberFromPage] = useState();

  useEffect(() => {
    async function loadNivelEnsino() {
      const response = await api.get('avisoslegais', {
        params: { avisoFilter: filter, page, entidade_id: entity },
      });

      setAvisos(response.data);
      setAvisosTotal(response.headers.x_total_count);
    }

    loadNivelEnsino();
  }, [filter, page, entity]);

  useEffect(() => {
    function handleLimits() {
      if (page >= 1) {
        if (page * limitFromPage > avisosTotal) {
          setNumberFromPage(avisosTotal);
        } else {
          setNumberFromPage(page * limitFromPage);
        }
      } else {
        setNumberFromPage(1);
      }

      if (page >= 1) {
        if (avisosTotal <= 0) {
          setInitialNumberFromPage(0);
        } else {
          setInitialNumberFromPage(page * limitFromPage + 1 - limitFromPage);
        }
      }
    }

    handleLimits();
  }, [page, avisosTotal, numberFromPage, limitFromPage]);

  function handlePageSum() {
    const numberpages = Math.ceil(avisosTotal / limitFromPage);

    if (page < numberpages) {
      setPage(page + 1);
    }
  }

  function handlePageSubtract() {
    if (!(page <= 1)) {
      setPage(page - 1);
    }
  }

  function handleNewAviso() {
    history.push('/admin/newaviso');
  }

  function handleEditAviso(aviso) {
    history.push({
      pathname: '/admin/editaviso',
      state: { aviso },
    });
  }

  return (
    <Container>
      <Header>
        <h1>Gerenciamento de Avisos Legais</h1>

        <Search>
          <Input>
            <MdSearch size={20} />
            <input
              placeholder="Buscar Aviso"
              onChange={(event) => setFilter(event.target.value)}
              type="text"
            />
          </Input>
          <Button type="button" onClick={handleNewAviso}>
            Novo
          </Button>
        </Search>
      </Header>
      <Main>
        <Table>
          <thead>
            <tr>
              <th>Ações</th>
              <th>ID</th>
              <th>Descrição</th>
              <th>Ativo</th>
            </tr>
          </thead>
          <tbody>
            {avisos &&
              avisos.map((aviso) => (
                <tr key={aviso.id}>
                  <td>
                    <button
                      type="button"
                      onClick={() => handleEditAviso(aviso)}
                    >
                      <MdBorderColor size={20} />
                    </button>
                  </td>
                  <td>{aviso.id}</td>
                  <td>{aviso.avl_descricao}</td>
                  <td>
                    {aviso.avl_ativo ? (
                      <MdDoneAll size={20} color="#89c54b" />
                    ) : (
                      <MdClear size={20} color="#f64c75" />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </Main>

      <Footer>
        <div>
          <MdKeyboardArrowLeft size={24} onClick={handlePageSubtract} />
          {page}
          <MdKeyboardArrowRight size={24} onClick={handlePageSum} />
        </div>

        <div>
          <span>{initialNumberFromPage}</span>
          <span>a</span>
          <span>{numberFromPage && numberFromPage}</span>
          <span>de</span>
          <span>{avisosTotal}</span>
        </div>
      </Footer>
    </Container>
  );
}
