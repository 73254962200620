import React from 'react';
import PropTypes from 'prop-types';

import { Input } from './styles';

export default function InputText({ name, type, ...rest }) {
  return <Input type={type} name={name} {...rest} />;
}

InputText.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
