import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Check } from '@rocketseat/unform';
import { MdPeople } from 'react-icons/md';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Header from '../../../components/HeaderForm';
import Input from '../../../components/InputText';
import Button from '../../../components/Button';
import { Select, InputSelect } from '../../../components/InputSelect';
import {
  Container,
  ContainerInfoPessoais,
  Content,
  ContainerRadio,
} from './styles';
import history from '../../../services/history';
import api from '../../../services/api';

export default function FamilyComposition({ location }) {
  const [idFichaAnalise] = useState(location.state.idFichaAnalise);
  const [idEntidade] = useState(location.state.idEntidade);

  const [formalInformal, setFormalInformal] = useState('');
  const [nivelEscolar, setNivelEscolar] = useState('');

  const [opcoesFormalInformal] = useState([
    {
      label: 'Formal',
      value: 1,
    },
    {
      label: 'Informal',
      value: 2,
    },
  ]);

  const [possuiProLabore, setPossuiProLabore] = useState(2);
  const [possuiOutrasRendas, setPossuiOutrasRendas] = useState(2);
  const [possuiSeguroDesemprego, setPossuiSeguroDesemprego] = useState(2);
  const [possuiNecessidade, setPossuiNecessidade] = useState(2);
  const [escolaridade, setEscolaridade] = useState(2);

  const [visibleInfosProLabore, setVisibleInfosProLabore] = useState(true);
  const [visibleInfoOutrasRendas, setVisibleInfoOutrasRendas] = useState(true);
  const [visibleInfosNecessidade, setVisibleInfosNecessidade] = useState(true);
  const [visibleInfosEntidade, setVisibleInfosEntidade] = useState(true);
  const [visibleProprioAluno, setVisibleProprioAluno] = useState(false);

  const [erroNivelEscolar, setErroNivelEscolar] = useState(false);

  const [inputProLabore, setInputNomeProLabore] = useState('');
  const [inputParentesco, setInputParentesco] = useState('');
  const [inputCnpjProLabore, setInputCnpjProLabore] = useState('');
  const [inputRamoProLabore, setInputRamoProLabore] = useState('');
  const [inputDescricaoRenda, setInputDescricaoRenda] = useState('');
  const [inputValorRenda, setInputValorRenda] = useState('');
  const [inputNecessidadeEspecial, setInputNecessidadeEspecial] = useState('');
  const [inputPeriodo, setInputPeriodo] = useState('');
  const [inputNomeCurso, setInputNomeCurso] = useState('');

  const opcoesFormaInformalSelected = useCallback((selectedOption) => {
    setFormalInformal(selectedOption);
  }, []);

  const selectedProprioAluno = useCallback((selectedOption) => {
    const { checked } = selectedOption.target;

    if (checked === true) setVisibleProprioAluno(true);
    else setVisibleProprioAluno(false);
  }, []);

  const nivelEnsinoSelected = useCallback((selectedOption) => {
    setNivelEscolar(selectedOption);
  }, []);

  const selectedProLabore = useCallback((selectedOption) => {
    const { value } = selectedOption.target;
    if (value === '1') setVisibleInfosProLabore(false);
    else setVisibleInfosProLabore(true);

    setPossuiProLabore(value);
  }, []);

  const selectedOutrasRendas = useCallback((selectedOption) => {
    const { value } = selectedOption.target;
    if (value === '1') setVisibleInfoOutrasRendas(false);
    else setVisibleInfoOutrasRendas(true);

    setPossuiOutrasRendas(value);
  }, []);

  const selectedSeguroDesemprego = useCallback((selectedOption) => {
    const { value } = selectedOption.target;

    setPossuiSeguroDesemprego(value);
  }, []);

  const selectedNecessidadeEspecial = useCallback((selectedOption) => {
    const { value } = selectedOption.target;
    if (value === '1') setVisibleInfosNecessidade(false);
    else setVisibleInfosNecessidade(true);

    setPossuiNecessidade(value);
  }, []);

  const selectedEntidade = useCallback((selectedOption) => {
    const { value } = selectedOption.target;
    if (value === '1') setVisibleInfosEntidade(false);
    else setVisibleInfosEntidade(true);

    setEscolaridade(value);
  }, []);

  const loadNiveisEnsino = useCallback(async () => {
    const response = await api
      .get(`/nivelensino/${idEntidade}`)
      .then((nivelensino) => {
        const options = nivelensino.data.map((nivel) => ({
          value: nivel.id,
          label: nivel.nie_descricao,
        }));
        return options;
      })
      .catch(() => {
        toast.error('Erro ao buscar niveis de ensino');
      });
    return response;
  }, [idEntidade]);

  const schema = Yup.object().shape({
    nome: Yup.string().required('Nome obrigatório!'),
    aluno: Yup.string(),
    parentesco: Yup.string().when(['aluno'], {
      is: false,
      then: Yup.string().required().min(3, 'Campo obrigatório'),
    }),
    datanascimento: Yup.string().required('Campo obrigatório!'),
    estadocivil: Yup.string().min(3, 'Mínimo 3 digitos!'),
    profissao: Yup.string(),
    salario: Yup.string(),
    nomeProlabore: Yup.string(),
    cnpjProlabore: Yup.string(),
    ramoProlabore: Yup.string(),
    descricaoRenda: Yup.string(),
    valorRenda: Yup.string(),
    necessidadeEspecial: Yup.string(),
    curso: Yup.string(),
    periodo: Yup.string(),
    gastos: Yup.string().required('Digite um valor mínimo ou 0'),
  });

  async function handlePersonalData(data) {
    if (nivelEscolar === '') {
      setErroNivelEscolar(true);
      return;
    }
    setErroNivelEscolar(false);
    try {
      await api.post('composicaofamiliar', {
        entidade_id: idEntidade,
        ficha_analise_id: idFichaAnalise,
        cfm_nome: data.nome,
        cfm_parentesco: data.parentesco,
        cfm_nascimento: data.datanascimento,
        cfm_estado_civil: data.estadocivil,
        cfm_profissao: data.profissao,
        cfm_salario: data.salario || 0,
        cfm_tipo: formalInformal.value || '',
        cfm_prolabore: possuiProLabore,
        cfm_prolabore_empresa: data.nomeProlabore,
        cfm_prolabore_cnpj: data.cnpjProlabore,
        cfm_prolabore_ramo: data.ramoProlabore,
        cfm_seguro_desemprego: possuiSeguroDesemprego,
        cfm_outras_rendas: possuiOutrasRendas || 0,
        cfm_outras_rendas_descricao: data.descricaoRenda,
        cfm_outras_rendas_valor: data.valorRenda || 0,
        cfm_necessidade_especial: possuiNecessidade,
        cfm_necessidade_qual: data.necessidadeEspecial,
        cfm_estudante_entidade: escolaridade,
        cfm_curso: data.curso,
        cfm_periodo: data.periodo,
        nivel_ensino_id: nivelEscolar.value,
        cfm_cadastro_aluno: data.aluno,
        cfm_gasto_ensino: data.gastos || 0,
      });

      toast.success('Membro adicionado com sucesso!');

      history.goBack();
    } catch (error) {
      toast.error(
        `Erro ao cadastrar membro familiar! ${error.response.data.message}`
      );
    }
  }

  return (
    <Container>
      <Header title="Composição familiar" icon={MdPeople} />

      <Form
        schema={schema}
        onSubmit={handlePersonalData}
        initialData={{
          entidade: `${escolaridade}`,
          prolabore: `${possuiProLabore}`,
          outrasrendas: `${possuiOutrasRendas}`,
          segurodesemprego: `${possuiSeguroDesemprego}`,
          necessidadeespecial: `${possuiNecessidade}`,
        }}
      >
        <h2>* Informações pessoais</h2>
        <h4>Nome:</h4>
        <ContainerInfoPessoais>
          <Input type="text" name="nome" />

          <Check
            name="aluno"
            onChange={selectedProprioAluno}
            label="Estas informações são do próprio aluno"
          />
        </ContainerInfoPessoais>

        <Content>
          <h4>Parentesco:</h4>
          <Input
            type="text"
            name="parentesco"
            placeholder="PAI / MÃE / IRMÃO "
            disabled={visibleProprioAluno}
            onChange={(e) => setInputParentesco(e.target.value)}
            value={visibleProprioAluno ? '' : inputParentesco}
          />
          <h4>Data de nascimento:</h4>
          <Input type="date" name="datanascimento" />

          <h4>Estado civil:</h4>
          <Input type="text" name="estadocivil" />

          <h2>* Trabalho</h2>

          <h4>Profissão:</h4>
          <Input type="text" name="profissao" />

          <h4>Salário:</h4>
          <Input min="0" type="number" step="0.01" name="salario" />

          <h4>Tipo de trabalho:</h4>
          <Select
            options={opcoesFormalInformal}
            onChange={opcoesFormaInformalSelected}
            name="formalInformal"
            value={formalInformal}
            className="inputSelect"
          />

          <h2>* Pró-Labore</h2>

          <h4>Possui pró-labore?</h4>

          <ContainerRadio>
            <div>
              <input
                type="radio"
                name="prolabore"
                onChange={selectedProLabore}
                value="1"
              />
              <label>Sim</label>
            </div>

            <div>
              <input
                type="radio"
                name="prolabore"
                onChange={selectedProLabore}
                value="2"
              />
              <label>Não</label>
            </div>
          </ContainerRadio>

          <h4>Nome da empresa:</h4>
          <Input
            type="text"
            name="nomeProlabore"
            disabled={visibleInfosProLabore}
            onChange={(e) => setInputNomeProLabore(e.target.value)}
            value={visibleInfosProLabore ? '' : inputProLabore}
          />
          <h4>CNPJ da empresa:</h4>
          <Input
            type="text"
            name="cnpjProlabore"
            onChange={(e) => setInputCnpjProLabore(e.target.value)}
            value={visibleInfosProLabore ? '' : inputCnpjProLabore}
            disabled={visibleInfosProLabore}
          />
          <h4>Ramo da empresa:</h4>
          <Input
            type="text"
            name="ramoProlabore"
            onChange={(e) => setInputRamoProLabore(e.target.value)}
            value={visibleInfosProLabore ? '' : inputRamoProLabore}
            disabled={visibleInfosProLabore}
          />
          <h2>* Rendas:</h2>

          <h4>Possui outras rendas?</h4>

          <ContainerRadio>
            <div>
              <input
                type="radio"
                name="outrasrendas"
                onChange={selectedOutrasRendas}
                value="1"
              />
              <label>Sim</label>
            </div>

            <div>
              <input
                type="radio"
                name="outrasrendas"
                onChange={selectedOutrasRendas}
                value="2"
              />
              <label>Não</label>
            </div>
          </ContainerRadio>

          <h4>Descrição da renda:</h4>
          <Input
            type="text"
            name="descricaoRenda"
            disabled={visibleInfoOutrasRendas}
            onChange={(e) => setInputDescricaoRenda(e.target.value)}
            value={visibleInfoOutrasRendas ? '' : inputDescricaoRenda}
          />
          <h4>Valor da renda:</h4>
          <Input
            type="number"
            min="0"
            step="0.01"
            name="valorRenda"
            disabled={visibleInfoOutrasRendas}
            onChange={(e) => setInputValorRenda(e.target.value)}
            value={visibleInfoOutrasRendas ? '' : inputValorRenda}
          />

          <h2>* Outras informações:</h2>

          <h4>Está recebendo seguro desemprego?</h4>

          <ContainerRadio>
            <div>
              <input
                type="radio"
                name="segurodesemprego"
                onChange={selectedSeguroDesemprego}
                value="1"
              />
              <label>Sim</label>
            </div>

            <div>
              <input
                type="radio"
                name="segurodesemprego"
                onChange={selectedSeguroDesemprego}
                value="2"
              />
              <label>Não</label>
            </div>
          </ContainerRadio>

          <h4>Possui necessidade especial?</h4>

          <ContainerRadio>
            <div>
              <input
                type="radio"
                name="necessidadeespecial"
                onChange={selectedNecessidadeEspecial}
                value="1"
              />
              <label>Sim</label>
            </div>

            <div>
              <input
                type="radio"
                name="necessidadeespecial"
                onChange={selectedNecessidadeEspecial}
                value="2"
              />
              <label>Não</label>
            </div>
          </ContainerRadio>

          <h4>Informe a necessidade especial:</h4>
          <Input
            type="text"
            name="necessidadeEspecial"
            disabled={visibleInfosNecessidade}
            onChange={(e) => setInputNecessidadeEspecial(e.target.value)}
            value={visibleInfosNecessidade ? '' : inputNecessidadeEspecial}
          />

          <h2>* Escolaridade</h2>
          <h4>Estuda nessa entidade?</h4>

          <ContainerRadio>
            <div>
              <input
                type="radio"
                name="entidade"
                onChange={selectedEntidade}
                value="1"
              />
              <label>Sim</label>
            </div>

            <div>
              <input
                type="radio"
                name="entidade"
                onChange={selectedEntidade}
                value="2"
              />
              <label>Não</label>
            </div>
          </ContainerRadio>

          <h4>Nome do curso:</h4>
          <Input
            type="text"
            name="curso"
            disabled={visibleInfosEntidade}
            onChange={(e) => setInputNomeCurso(e.target.value)}
            value={visibleInfosEntidade ? '' : inputNomeCurso}
          />
          <h4>Em qual período:</h4>
          <Input
            type="text"
            name="periodo"
            disabled={visibleInfosEntidade}
            onChange={(e) => setInputPeriodo(e.target.value)}
            value={visibleInfosEntidade ? '' : inputPeriodo}
          />

          <h4>Nível escolar:</h4>

          <InputSelect
            loadOptions={loadNiveisEnsino}
            onChange={nivelEnsinoSelected}
            name="nivelensino"
            className="inputSelect"
            value={nivelEscolar}
          />
          {erroNivelEscolar && <span>Selecione alguma opção!</span>}

          <h4>Gastos com ensino particular:</h4>
          <Input type="number" step="0.01" min="0" name="gastos" />
        </Content>

        <Button type="submit">Incluir membro</Button>
      </Form>
    </Container>
  );
}
FamilyComposition.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
