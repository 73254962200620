import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import { Container, ContainerButtons, Card, Icon } from './styles';

export default function ModalConfirmDelete({
  onClose,
  open,
  onDelete,
  itemId,
}) {
  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    open && (
      <Container>
        <Card>
          <h3>Deseja realmente apagar o registro?</h3>

          <ContainerButtons>
            <Button type="button" onClick={() => onDelete(itemId)}>
              Apagar
            </Button>
            <Button type="button" className="delete" onClick={closeModal}>
              Cancelar
            </Button>
          </ContainerButtons>

          <Icon onClick={closeModal} />
        </Card>
      </Container>
    )
  );
}

ModalConfirmDelete.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onDelete: PropTypes.func,
  itemId: PropTypes.number,
};
ModalConfirmDelete.defaultProps = {
  onClose: true,
  open: true,
  onDelete: '',
  itemId: null,
};
