import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MdBorderColor } from 'react-icons/md';
import Button from '../../../../components/Button';
import history from '../../../../services/history';
import api from '../../../../services/api';
import { Details, Table } from './styles';

export default function TabelaDetalheBens({ idBem, idFicha, idEntidade }) {
  const [bensDetalhes, setBensDetalhes] = useState([]);
  useEffect(() => {
    async function loadBensDetalhes() {
      const response = await api.get(
        `bensanalisesdetalhes/${idFicha}/${idBem}`
      );

      setBensDetalhes(response.data);
    }

    loadBensDetalhes();
  }, [idBem, idFicha]);

  return (
    <Details>
      <h3>Detalhes - Informe Marca/Modelo/Ano dos Carros/Motos/Caminhão</h3>
      <Button
        type="button"
        onClick={() =>
          history.push({
            pathname: '/order/cadastrar/bens/detalhes',
            state: {
              idBem,
              idEntidade,
              idFicha,
            },
          })
        }
      >
        Adicionar novo
      </Button>

      <Table>
        <thead>
          <tr>
            <th>Editar</th>
            <th>Modelo</th>
            <th>Marca</th>
            <th>Ano</th>
          </tr>
        </thead>
        <tbody>
          {bensDetalhes.map(({ id, ...bem }) => (
            <tr key={id}>
              <td>
                <button type="button">
                  <MdBorderColor
                    size={20}
                    color="#fff"
                    onClick={() =>
                      history.push({
                        pathname: '/order/editar/bens/detalhes',
                        state: {
                          idBemDetalhe: id,
                          idBem,
                          informacoesBens: bem,
                        },
                      })
                    }
                  />
                </button>
              </td>
              <td>{bem.bad_modelo}</td>
              <td>{bem.bad_marca}</td>
              <td>{bem.bad_ano}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Details>
  );
}

TabelaDetalheBens.propTypes = {
  idBem: PropTypes.oneOfType([PropTypes.number]).isRequired,
  idFicha: PropTypes.oneOfType([PropTypes.number]).isRequired,
  idEntidade: PropTypes.oneOfType([PropTypes.number]).isRequired,
};
