import React, { useEffect, useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '../../services/api';
import history from '../../services/history';
import { Container, Table } from './styles';
import Button from '../../components/Button';
import Modal from '../../components/ModalOrder';

export default function Dashboard() {
  const [fichasUsuario, setFichasUsuario] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const user = useSelector((state) => state.user.profile);

  useEffect(() => {
    async function loadFichas() {
     
        const response = await api.get(`fichaanalisesocial/dashboard/${user?.id}`);

        setFichasUsuario(response.data);
     
    }
    loadFichas();
  }, [user]);
  return (
    <Container>
      <h2>Meus Pedidos</h2>

      <Button type="button" onClick={() => setOpenModal(true)}>
        Nova Solicitação
      </Button>

      <Table>
        <thead>
          <tr>
            <th>Curso</th>
            <th>Ano</th>
            <th>Semestre</th>
            <th>Situação</th>
            <th>Editar</th>
          </tr>
        </thead>
        <tbody>
          {fichasUsuario.map((ficha) => (
           <tr key={ficha.id}>
              <td>{ficha.curso.cur_descricao}</td>
              <td>{ficha.periodo_letivo.pel_descricao}</td>
              <td>{ficha.periodo.per_descricao}</td>
              <td>{ficha.situacao.sit_descricao}</td>
              <td>
                <button
                  type="button"
                  onClick={() =>
                    history.push({
                      pathname: '/order/edit',
                      state: {
                        idFichaAnalise: ficha.id,
                        idEntidade: ficha.entidade_id,
                        idSituacao: ficha.situacao.id,
                      },
                    })
                  }
                >
                  <MdEdit size={20} color="#fff8" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {openModal && (
        <Modal open={openModal} onClose={() => setOpenModal(false)} />
      )}

      <p>Desenvolvedores: Rodrigo Barreto & Pedro Gomes</p>
    </Container>
  );
}
