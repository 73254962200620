import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;

  overflow-x: auto;

  background: #121921;
  width: 85%;
  margin: 15px auto;
  border-radius: 8px;
  color: #fff;

  @media (max-width: 800px) {
    width: 95%;
  }

  span {
    color: #fff8;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 40%;

    h2 {
      margin: 10px 0px;
      border-bottom: 1px solid #fff;
    }

    h4 {
      margin: 10px 0px 5px 0px;
    }

    span {
      color: #f64c75;
      font-size: 12px;
    }
  }

  @media (max-width: 500px) {
    form {
      width: 70%;
    }

    span {
      padding: 5px;
      text-align: center;
    }
  }

  a {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    padding: 10px;

    svg {
      margin-right: 5px;
    }
  }
`;
